import React, { memo, useCallback, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

import { closeAnyModal, getModalData } from "../../../store/slices/modals";
import { ReactComponent as CloseIcon } from "../../Modals/assets/closeIcon.svg";
import {
  getAvailableIds,
  getMapStructs,
  setIntersection,
} from "../../../store/slices/mapStructs";
import {
  addUndoFeature,
  getFeatureIndexByIntersectionId,
  getFeatures,
  getLanesBySelectedFeatures,
  setFeatures,
} from "../../../store/slices/currentMap";
import { generateUUID } from "../../../utils";
import { RowStyled } from "../../PolygonFeature/styles";
import { ItemWithContainerValues } from "../../common/ItemWithContainerValues";

import {
  IntersectionsModalStyled,
  HeaderStyled,
  IntersectionsModalContentStyled,
  DropdownButtonStyled,
  RowItemStyled,
  ModalWrapStyled,
  ActionButtonStyled,
} from "./styles";

const getIntersectionProperties = (
  intersectionId: number,
  associatedLaneIds: number[],
  featureId = generateUUID()
) => ({
  feature_id: featureId,
  feature_info_list: [
    {
      polygon_type: "intersection",
      lane_association: String(associatedLaneIds),
      stop_sign_id: "NULL",
      intersection_id: intersectionId,
      control_line_association: "NULL",
    },
  ],
});

export const IntersectionsModal = memo(function IntersectionsModal() {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const mapStructs = useSelector(getMapStructs);
  const availableIds = useSelector(getAvailableIds);
  const mapFeatures = useSelector(getFeatures);
  const selectedLanes = useSelector(getLanesBySelectedFeatures);

  const intersectionId = useMemo(
    () => modalData || generateUUID(),
    [modalData]
  );
  const isEditMode = Boolean(modalData);
  const currentIntersection = mapStructs.intersections[intersectionId];
  const featureIndex = useSelector(
    getFeatureIndexByIntersectionId(intersectionId)
  );

  const modalTitle = isEditMode
    ? `Edit ${intersectionId} intersection`
    : "Create new intersection";

  const initialLanes = useMemo(
    () =>
      isEditMode && currentIntersection
        ? ([...currentIntersection.associated_lane_ids, "NULL"] as (
            | number
            | "NULL"
          )[])
        : selectedLanes.length >= 1
        ? ([...selectedLanes, "NULL"] as (number | "NULL")[])
        : (["NULL"] as "NULL"[]),
    [isEditMode, currentIntersection, selectedLanes]
  );

  const [currentLanes, setCurrentLanes] =
    useState<(number | "NULL")[]>(initialLanes);

  const currentAvailableIds = useMemo(
    () =>
      Array.from(availableIds.laneIdsForIntersection.values()).filter(
        (id) => !currentLanes.includes(id as number)
      ) as (number | "NULL")[],
    [availableIds, currentLanes]
  );

  useEffect(() => {
    if (!isEditMode) {
      setCurrentLanes(
        selectedLanes.length >= 1
          ? ([...selectedLanes, "NULL"] as (number | "NULL")[])
          : (["NULL"] as "NULL"[])
      );
    }
  }, [selectedLanes, isEditMode]);

  const handleLaneSelect = useCallback(
    (selectedValue: any, laneIndex: number) => {
      setCurrentLanes((prevLanes) => {
        if (selectedValue === "NULL") {
          return prevLanes.filter((laneId) => laneId !== prevLanes[laneIndex]);
        } else {
          const updatedLanes = [...prevLanes];
          updatedLanes[laneIndex] = Number(selectedValue);
          if (!updatedLanes.includes("NULL")) {
            updatedLanes.push("NULL");
          }
          return updatedLanes;
        }
      });
    },
    []
  );

  const handleSubmit = useCallback(() => {
    const updatedMapFeatures = cloneDeep(mapFeatures);
    const associatedLaneIds = currentLanes.filter(
      (laneId) => laneId !== "NULL"
    );

    if (isEditMode) {
      const currentIntersectionFeature =
        updatedMapFeatures.features[featureIndex];

      if (currentIntersectionFeature) {
        currentIntersectionFeature.properties = getIntersectionProperties(
          intersectionId,
          associatedLaneIds,
          currentIntersectionFeature?.properties?.feature_id
        );
      }
    } else {
      updatedMapFeatures.features = [
        ...updatedMapFeatures.features,
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [[]],
          },
          properties: getIntersectionProperties(
            intersectionId,
            associatedLaneIds
          ),
        },
      ];
    }

    dispatch(setFeatures(updatedMapFeatures));
    dispatch(addUndoFeature(updatedMapFeatures));
    dispatch(
      setIntersection({
        intersection_id: intersectionId,
        associated_lane_ids: associatedLaneIds,
      })
    );
    toast.success(
      `Intersection ${intersectionId} was ${isEditMode ? "edited" : "created"} `
    );
    dispatch(closeAnyModal());
  }, [
    isEditMode,
    intersectionId,
    currentLanes,
    mapFeatures,
    featureIndex,
    dispatch,
  ]);

  return (
    <ModalWrapStyled>
      <IntersectionsModalStyled>
        <CloseIcon onClick={() => dispatch(closeAnyModal())} />
        <HeaderStyled>{modalTitle}</HeaderStyled>
        <IntersectionsModalContentStyled>
          {currentLanes.map((value, laneIndex) => (
            <RowStyled key={`${laneIndex}-${value}`}>
              <RowItemStyled>Lane Associated</RowItemStyled>
              <RowItemStyled>
                <DropdownButtonStyled
                  title={String(value)}
                  id={`dropdown-${laneIndex}`}
                  size="sm"
                  onSelect={(val: any) => handleLaneSelect(val, laneIndex)}
                >
                  <ItemWithContainerValues
                    key={`${laneIndex}-${value}`}
                    values={[...currentAvailableIds, "NULL"]}
                  />
                </DropdownButtonStyled>
              </RowItemStyled>
            </RowStyled>
          ))}
        </IntersectionsModalContentStyled>
        <ActionButtonStyled onClick={handleSubmit}>
          {isEditMode ? "Edit" : "Create"}
        </ActionButtonStyled>
      </IntersectionsModalStyled>
    </ModalWrapStyled>
  );
});
