import styled from "styled-components";

interface TooltipStyledProps {
  x: number;
  y: number;
}

export const TooltipStyled = styled.div<TooltipStyledProps>`
  position: absolute;
  left: ${(props) => `${props.x + 8}px`};
  top: ${(props) => `${props.y + 8}px`};
  background-color: #fff;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
`;
