import React, { FC, memo, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";

import {
  FeaturePropertiesFeatureInfoStyled,
  FeaturePropertiesTableContainer,
  FeaturePropertiesBodyStyled,
  DeleteFeaturePropertiesStyled,
} from "../../../common/toolbox";
import * as MapConst from "../../../../constants/map-constants";
import { LineStringFeature } from "../../../LineStringFeature/LineStringFeature";
import { PolygonFeature } from "../../../PolygonFeature";
import { Lane } from "../../../../models/map-interface";
import { getAvailableIds, setLane } from "../../../../store/slices/mapStructs";
import {
  getFeatures,
  getLockedFeatures,
} from "../../../../store/slices/currentMap";

import { ContainerStyled } from "./styles";

type FeaturePropertiesPanelProps = {
  featureIndex: number;
  handleAddFeatureProperty: (
    e: React.MouseEvent<HTMLButtonElement>,
    featureIndex: number
  ) => void;
  deleteFeatureProperty: (
    featureIndex: number,
    featureInfoIndex: number
  ) => void;
};

export type FeatureInfo = { [key: string]: string | number };

export const FeaturePropertiesPanel: FC<FeaturePropertiesPanelProps> = memo(
  ({ featureIndex, handleAddFeatureProperty, deleteFeatureProperty }) => {
    const dispatch = useDispatch();
    const mapFeatures = useSelector(getFeatures);
    const availableIds = useSelector(getAvailableIds);
    const lockedFeatures = useSelector(getLockedFeatures);
    const isLocked = useMemo(
      () => lockedFeatures.includes(featureIndex),
      [lockedFeatures, featureIndex]
    );
    const geoJsonProperties = mapFeatures.features[featureIndex].properties;
    const isHasProperties = Object.keys(geoJsonProperties || {}).length > 0;
    const featureInfoList =
      isHasProperties && (geoJsonProperties?.feature_info_list || []);
    const featureId = mapFeatures.features[featureIndex].properties?.feature_id;

    const addNewLaneToMapStructs = (laneId: number) => {
      const newLane: Lane = {
        lane_id: laneId,
        left_boundary_line_id: MapConst.INVALID_ID_NUMBER,
        right_boundary_line_id: MapConst.INVALID_ID_NUMBER,
        start_line_id: MapConst.INVALID_ID_NUMBER,
        termination_line_id: MapConst.INVALID_ID_NUMBER,
      };
      dispatch(setLane(newLane));
    };

    return (
      <FeaturePropertiesTableContainer>
        {isHasProperties && (
          <>
            {[
              <ContainerStyled
                key={featureIndex + "_" + MapConst.FEATURE_ID_STRING_NAME}
              >
                <Row>
                  <Col xs={6}>
                    <b>FEATURE ID</b>
                  </Col>
                  <Col xs={6}>
                    <b>{featureId}</b>
                  </Col>
                </Row>
              </ContainerStyled>,
              featureInfoList.map(
                (featureInfoItem: FeatureInfo, featureInfoIndex: number) => (
                  <FeaturePropertiesBodyStyled
                    key={`${featureIndex}_${featureInfoIndex}_feature_info`}
                  >
                    <FeaturePropertiesFeatureInfoStyled className="feature-property-info">
                      {MapConst.LINE_TYPE_STRING_NAME in featureInfoItem ? (
                        <LineStringFeature
                          availableIds={availableIds}
                          feature_info={featureInfoItem}
                          feature_index={featureIndex}
                          feature_info_index={featureInfoIndex}
                        />
                      ) : (
                        <PolygonFeature
                          availableIds={availableIds}
                          feature_info={featureInfoItem}
                          feature_index={featureIndex}
                          feature_info_index={featureInfoIndex}
                          addNewLaneToMapStructs={addNewLaneToMapStructs}
                        />
                      )}
                    </FeaturePropertiesFeatureInfoStyled>
                    {!isLocked && (
                      <DeleteFeaturePropertiesStyled
                        onClick={() =>
                          deleteFeatureProperty(featureIndex, featureInfoIndex)
                        }
                      >
                        <XCircleFill size={24} color={"red"} />
                      </DeleteFeaturePropertiesStyled>
                    )}
                  </FeaturePropertiesBodyStyled>
                )
              ),
            ]}
          </>
        )}
        {!isLocked && (
          <button onClick={(e) => handleAddFeatureProperty(e, featureIndex)}>
            Add New Feature Properties
          </button>
        )}
      </FeaturePropertiesTableContainer>
    );
  }
);
