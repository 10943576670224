import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../..";

export const getIsCurrentUserLoaded = (state: RootState): boolean =>
  Boolean(state.user.id);
export const getCurrentUserImage = (state: RootState) => state.user.image?.link;
export const getCurrentUserFirstName = (state: RootState) =>
  state.user.firstName;
export const getCurrentUserLastName = (state: RootState) => state.user.lastName;
export const getCurrentUserName = createSelector(
  getCurrentUserFirstName,
  getCurrentUserLastName,
  (firstName, lastName) => {
    if (!firstName && !lastName) return "";
    return `${firstName} ${lastName}`;
  }
);
