import styled from "styled-components";

export const InputContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  font-size: 14px;
`;

export const InputLabelStyled = styled.div`
  width: 100%;
  color: #fff;
  margin-bottom: 4px;
`;

export const InputStyled = styled.input`
  width: 100%;
  outline: none;
  height: 32px;
`;
