import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FeaturesInterface } from "../../../models/map-interface";
import { BASE } from "../../../utils/api";

const initialState: CurrentMap & {
  undoFeatures: Array<FeaturesInterface>;
  mapFeatures: FeaturesInterface;
} = {
  _id: "",
  latitude: 0,
  longitude: 0,
  pointCloudJson: "",
  semanticMapGeoJson: "",
  centerlineGeoJson: "",
  geojsonType: null,
  lockedFeatures: [],
  selectedFeatureIndexes: [],
  contextMenuData: null,
  mode: "GeoJsonEditMode",
  modeConfig: {
    turfOptions: { units: "meters" },
  },
  deleteSelectedItems: false,
  undoFeatures: [],
  undoStep: null,
  mapFeatures: {
    type: "FeatureCollection",
    features: [],
    properties: {
      useLocalCoord: true,
      latLngOrigin: {
        latitude: 0,
        longitude: 0,
      },
    },
  },
};

export const Slice = createSlice({
  name: "currentMap",
  initialState,
  reducers: {
    resetCurrentMap: (state) => {
      Object.assign(state, initialState);
    },
    setCurrentMapData: (state, action) => {
      Object.assign(state, {
        ...action.payload,
        pointCloudJson: `${BASE}map-projects/${action.payload._id}/point_cloud.json`,
        semanticMapGeoJson: `${BASE}map-projects/${action.payload._id}/semantic_map.geojson`,
        centerlineGeoJson: `${BASE}map-projects/${action.payload._id}/centerline.geojson`,
      });
    },
    setGeojsonType: (state, action) => {
      state.geojsonType = action.payload;
    },
    setLockedFeatures: (state, action: PayloadAction<Array<number>>) => {
      state.lockedFeatures = action.payload;
    },
    setSelectedFeatureIndexes: (
      state,
      action: PayloadAction<Array<number>>
    ) => {
      state.selectedFeatureIndexes = action.payload;
    },
    setContextMenuData: (state, action) => {
      state.contextMenuData = action.payload;
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setModeConfig: (state, action: any) => {
      state.modeConfig = action.payload;
    },
    setModeWithConfig: (state, action: PayloadAction<any>) => {
      const { mode, modeConfig } = action.payload;
      state.mode = mode;
      state.modeConfig = modeConfig;
    },
    setDeleteSelectedItems: (state, action: PayloadAction<boolean>) => {
      state.deleteSelectedItems = action.payload;
    },
    addUndoFeature: (state, action: PayloadAction<FeaturesInterface>) => {
      if (state.undoStep === null) {
        state.undoFeatures = [action.payload, ...state.undoFeatures];
      } else {
        state.undoFeatures = [
          action.payload,
          ...state.undoFeatures.slice(state.undoStep),
        ];
        state.undoStep = null;
      }
    },
    setUndoFeatures: (
      state,
      action: PayloadAction<Array<FeaturesInterface>>
    ) => {
      state.undoFeatures = action.payload;
    },
    increaseUndoStep: (state) => {
      if (state.undoStep !== null || state.undoStep === 0) {
        state.undoStep = state.undoStep + 1;
      } else {
        state.undoStep = 1;
      }
    },
    decreaseUndoStep: (state) => {
      if (state.undoStep !== null && state.undoStep !== 0) {
        state.undoStep = state.undoStep - 1;
      } else {
        state.undoStep = 0;
      }
    },
    setFeatures: (state, action: PayloadAction<FeaturesInterface>) => {
      state.mapFeatures = action.payload;
    },
  },
});

export default Slice;
