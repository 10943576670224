import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
  getIsSaveProgressModal,
  getIsReportModal,
  getIsVersionModal,
  getIsConfirmActionModal,
  getIsNewProjectModal,
  getIsEditProjectModal,
  getIsPointCloudModal,
  getIsVerifySemanticMapModal,
  getIsVerifyGeoJsonModal,
  getIsGeoJsonSelectTypeModal,
  getIsIntersectionsModal,
  getIsStopSignModal,
} from "../../store/slices/modals";

import { SaveProgressModal } from "./SaveProgressModal";
import { VerifySemanticMapModal } from "./VerifySemanticMapModal";
import { VerifyGeoJsonModal } from "./VerifyGeoJsonModal";
import { ReportModal } from "./ReportModal";
import { ConfirmActionModal } from "./ConfirmActionModal/ConfirmActionModal";
import { NewProjectModal } from "./NewProjectModal/NewProjectModal";
import { EditProjectModal } from "./EditProjectModal/EditProjectModal";
import { PointCloudModal } from "./PointCloudModal/PointCloudModal";
import { VersionsModal } from "./VersionsModal";
import { GeoJsonSelectTypeModal } from "../GeoJsonSelectTypeModal";
import { IntersectionsModal } from "./IntersectionsModal";
import { StopSignModal } from "./StopSignModal";

export const Modals = memo(() => {
  const isVerifySemanticMapModal = useSelector(getIsVerifySemanticMapModal);
  const isSaveProgressModal = useSelector(getIsSaveProgressModal);
  const isReportModal = useSelector(getIsReportModal);
  const isVersionModal = useSelector(getIsVersionModal);
  const isConfirmActionModal = useSelector(getIsConfirmActionModal);
  const isNewProjectModal = useSelector(getIsNewProjectModal);
  const isEditProjectModal = useSelector(getIsEditProjectModal);
  const isPointCloudModal = useSelector(getIsPointCloudModal);
  const isGeoJsonSelectTypeModal = useSelector(getIsGeoJsonSelectTypeModal);
  const isVerifyGeoJsonModal = useSelector(getIsVerifyGeoJsonModal);
  const isIntersectionsModal = useSelector(getIsIntersectionsModal);
  const isStopSignModal = useSelector(getIsStopSignModal);

  return (
    <>
      {isStopSignModal && <StopSignModal />}
      {isIntersectionsModal && <IntersectionsModal />}
      {isVerifyGeoJsonModal && <VerifyGeoJsonModal />}
      {isVerifySemanticMapModal && <VerifySemanticMapModal />}
      {isSaveProgressModal && <SaveProgressModal />}
      {isReportModal && <ReportModal />}
      {isVersionModal && <VersionsModal />}
      {isConfirmActionModal && <ConfirmActionModal />}
      {isNewProjectModal && <NewProjectModal />}
      {isEditProjectModal && <EditProjectModal />}
      {isPointCloudModal && <PointCloudModal />}
      {isGeoJsonSelectTypeModal && <GeoJsonSelectTypeModal />}
    </>
  );
});
