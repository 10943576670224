import styled from "styled-components";

export const ToolbarDropdownStyled = styled.div<{
  isOpen: boolean;
  isActive?: boolean;
}>`
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 14px 0;
  min-width: 80px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s;
  color: rgba(255, 255, 255, ${(props) => (props.isOpen ? "1" : "0.6")});
  border-bottom: 4px solid
    ${(props) => (props.isOpen ? "#55B8B3" : "transparent")};
  background-color: ${(props) => {
    if (props.isActive) return "rgba(8, 25, 45, 0.3)";
    return props.isOpen ? "#08192D" : "transparent";
  }};

  path {
    fill: ${(props) => (props.isOpen ? "#55B8B3" : "rgba(255, 255, 255, 0.6)")};
  }
`;

export const ToolbarDropdownTitleStyled = styled.div<{ isActive?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 24px;
  border-right: 1px solid
    ${(props) =>
      props.isActive ? "transparent " : "rgba(255, 255, 255, 0.08)"};
  cursor: pointer;

  svg {
    margin-bottom: 4px;
  }
`;

export const ToolbarDropdownContainerStyled = styled.div`
  position: absolute;
  top: 72px;
  left: -1px;
  display: flex;
  flex-direction: column;
  background: #172a3f;
`;

export const ToolbarDropdownItemStyled = styled.div<{
  isDisabled?: boolean;
  isActive?: boolean;
}>`
  display: flex;
  width: 240px;
  box-sizing: border-box;
  align-items: center;
  transition: all 0.3s;
  color: rgba(255, 255, 255, 0.6);
  background-color: ${(props) =>
    props.isActive ? "rgba(8,	25,	45, 0.3)" : "transparent"};
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};

  div {
    opacity: ${(props) => (props.isDisabled ? "0.6" : "1")};
  }

  path {
    fill: rgba(255, 255, 255, 0.6);
  }

  ${(props) =>
    !props.isDisabled &&
    `
    &:hover {
      color: #08192d;
      background-color: #55b8b3;

      path {
        fill: #08192d;
      }
    }
  `}

  svg {
    margin-right: 8px;
  }
`;

export const DropdownItemWrapperStyled = styled.div<{
  fullBorder?: boolean;
  isActive?: boolean;
}>`
  display: flex;
  width: 100%;
  padding: 14px 16px 14px ${(props) => (props.fullBorder ? "24px" : "0")};
  margin-left: ${(props) => (props.fullBorder ? "0" : "24px")};
  box-sizing: border-box;
  border-bottom: 1px solid
    ${(props) =>
      props.isActive ? "transparent" : " rgba(255, 255, 255, 0.08)"};
`;

export const ToolbarDropdownSubOptionsStyled = styled(
  ToolbarDropdownItemStyled
)`
  position: relative;
  flex-direction: row;
  justify-content: space-between;
`;

export const DropdownSubOptionsContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%;
  background: #172a3f;

  div {
    width: 180px;
  }
`;

export const ToolbarDropdownItemContentStyled = styled.div`
  display: flex;
  padding: 14px 16px 14px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;
