//TODO change after backend part
import { createSlice } from "@reduxjs/toolkit";

const initialState: User = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
};

export const Slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
    setUser: (state, action) => {
      state = action.payload;
    },
  },
});

export default Slice;
