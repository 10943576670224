import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { RouteProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchGetCurrentUser,
  getIsCurrentUserLoaded,
} from "../../store/slices/user";

export interface ChildrenProps {
  children: RouteProps["children"];
}

export const AuthorizedAppGuard = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = window.localStorage.getItem("JWT");
  const isCurrentUserLoaded = useSelector(getIsCurrentUserLoaded);

  if (!isLoggedIn) return <Navigate to="/login" />;

  // GET /users/me
  useEffect(() => {
    if (isCurrentUserLoaded) return;
    dispatch(fetchGetCurrentUser());
  }, [isCurrentUserLoaded]);

  return <>{children}</>;
};
