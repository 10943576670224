import { PointCloudLayer, PolygonLayer } from "@deck.gl/layers";

import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { hex2rgb } from "./index";
import { rainbow } from "../constants/nebula";
import { Position } from "@nebula.gl/edit-modes";

export const point_cloud_layer = (
  pointCloudJson: any,
  coordinateOrigin: [any, any]
) =>
  new PointCloudLayer({
    id: "point_cloud_layer",
    data: pointCloudJson,
    coordinateSystem: COORDINATE_SYSTEM.LNGLAT_OFFSETS,
    coordinateOrigin,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getPosition: (d: unknown) => d.COORDINATES as Position,
    pointSize: 2,
    opacity: 1.0,
    getColor: (d: any) => {
      const rgb_color = hex2rgb(rainbow().colourAt(d.COORDINATES[2] * 20));
      for (let i = 0; i < rgb_color.length; i++) {
        rgb_color[i] *= 255;
      }
      return rgb_color as RGBAColor;
    },
  });

export const edit_plane_layer = (
  center: number[],
  elevation: number,
  radius: number,
  color = [99, 203, 224, 50],
  visible: boolean
) => {
  const h: number = elevation - 0.1;
  const vertex: number[][][] = [
    [
      [center[0] - radius, center[1] - radius, h],
      [center[0] - radius, center[1] + radius, h],
      [center[0] + radius, center[1] + radius, h],
      [center[0] + radius, center[1] - radius, h],
      [center[0] - radius, center[1] - radius, h],
    ],
  ];

  const layer = new PolygonLayer({
    id: "polygon-layer",
    data: vertex,
    visible,
    extruded: false,
    pickable: false,
    stroked: false,
    filled: true,
    wireframe: true,
    getPolygon: (f: unknown) => f as Position[] | Position[][],
    getFillColor: color as RGBAColor,
    parameters: {
      depthTest: true,
    },
  });

  return layer;
};
