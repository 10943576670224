import React, { FC, memo, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ListGroup } from "react-bootstrap";

import { Intersection } from "../../../map-interface.d";
import { INTERSECTIONS_MODAL, toggleModal } from "../../../store/slices/modals";
import { removeIntersection } from "../../../store/slices/mapStructs";
import {
  RemoveButtonStyled,
  ButtonsContainerStyled,
  CardContainerStyled,
  CardHeaderStyled,
  MenuBtnStyled,
  CardBodyStyled,
  EditButtonStyled,
} from "../styles";

import { PropNameStyled, PropLineStyled } from "./styles";
import { Checkbox } from "../../common/Checkbox";

type IntersectionsCardProps = {
  intersection: Intersection;
  setActiveElement: (intersectionId: number) => void;
  isOpened: boolean;
  selectedLaneIds: number[];
  onLaneFocus: (laneIds: number[]) => void;
};

export const IntersectionsCard: FC<IntersectionsCardProps> = memo(
  ({
    intersection,
    isOpened,
    setActiveElement,
    selectedLaneIds,
    onLaneFocus,
  }) => {
    const dispatch = useDispatch();
    const { intersection_id, associated_lane_ids } = intersection;

    const isChecked = useMemo(
      () =>
        associated_lane_ids.every((laneId) => selectedLaneIds.includes(laneId)),
      [associated_lane_ids, selectedLaneIds]
    );

    const propsList = useMemo(() => {
      return (Object.keys(intersection) as Array<keyof Intersection>).map(
        (prop) => (
          <PropLineStyled key={prop} style={{ textTransform: "capitalize" }}>
            <PropNameStyled>{prop.replace(/_/g, " ")}:</PropNameStyled>
            <span>{`${intersection[prop]}`}</span>
          </PropLineStyled>
        )
      );
    }, [intersection]);

    const handleEdit = useCallback(() => {
      dispatch(
        toggleModal({ type: INTERSECTIONS_MODAL, data: intersection_id })
      );
    }, [dispatch, intersection_id]);

    const handleRemove = useCallback(() => {
      dispatch(removeIntersection(intersection_id));
    }, [intersection_id]);

    const selectIntersection = useCallback(() => {
      const updatedLaneIds = isChecked
        ? selectedLaneIds.filter(
            (laneId) => !associated_lane_ids.includes(laneId)
          )
        : [
            ...selectedLaneIds,
            ...associated_lane_ids.filter(
              (laneId) => !selectedLaneIds.includes(laneId)
            ),
          ];
      onLaneFocus(updatedLaneIds);
    }, [isChecked, associated_lane_ids, selectedLaneIds, onLaneFocus]);

    return (
      <CardContainerStyled>
        <CardHeaderStyled>
          <MenuBtnStyled onClick={() => setActiveElement(intersection_id)}>
            {intersection_id}
            <Checkbox
              key={intersection_id}
              checked={isChecked}
              onChange={selectIntersection}
            />
          </MenuBtnStyled>
        </CardHeaderStyled>
        {isOpened && (
          <CardBodyStyled>
            <ListGroup as="ul">
              {propsList}
              <ButtonsContainerStyled>
                <EditButtonStyled onClick={handleEdit}>Edit</EditButtonStyled>
                <RemoveButtonStyled onClick={handleRemove}>
                  Remove
                </RemoveButtonStyled>
              </ButtonsContainerStyled>
            </ListGroup>
          </CardBodyStyled>
        )}
      </CardContainerStyled>
    );
  }
);
