import axios from "axios";

const API_URL = window._envK8S_?.REACT_APP_API_URL || "";
const API_VERSION = window._envK8S_?.REACT_APP_API_VERSION || "v1";
const BASE = `${API_URL}api/${API_VERSION}/`;

export const refreshToken = async (): Promise<string> => {
  const currentRefreshToken = window.localStorage
    .getItem("JWTrefresh")
    ?.replace("Bearer ", "");

  if (!currentRefreshToken) {
    throw new Error("Refresh token not found");
  }

  const response = await axios.post(`${BASE}login/refresh/`, {
    refresh: currentRefreshToken,
  });

  const newAccessToken = `Bearer ${response.data.access}`;
  window.localStorage.setItem("JWT", newAccessToken);

  return newAccessToken;
};
