import styled from "styled-components";

export const CurrentMapInfoStyled = styled.div`
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  font-size: 14px;
  line-height: 140%;
  gap: 4px;
`;

export const CurrentMapSaveStatusStyled = styled.div`
  color: #a4a4a4;
`;

export const CurrentMapNameStyled = styled.div`
  color: #fff;
`;
