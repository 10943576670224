// the functionality is hidden until the implementation of the super admin role
import React, { memo } from "react";

import { UserAvatarComponent } from "../../../common/UserAvatarComponent";

import { UserInfoStyled, UserNameStyled, UserImageStyled } from "./styles";
export const UserInfo = memo(() => {
  // const toggleChangeCompanyModalAction = useCallback(() => {
  //   dispatch(toggleModal({ type: CHANGE_COMPANY_MODAL }));
  //   setShowDropdown(false);
  // }, []);

  return (
    <UserInfoStyled>
      <UserImageStyled>
        <UserAvatarComponent />
      </UserImageStyled>
      <UserNameStyled data-test-id="profile">Andrei</UserNameStyled>
    </UserInfoStyled>
  );
});
