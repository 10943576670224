import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API from "../../../api";
import { setIsLoading } from "../appState";
import { addNewProject, deleteProject, updateProject } from ".";
import { fetchPostPointCloud } from "../pointClouds";

export const fetchGetProjects = createAsyncThunk(
  "projects/fetchGetProjects",
  async (_, thunkAPI) => {
    try {
      const response = await API.get("map-projects");

      return response.data.mapProjects;
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return Promise.reject();
      }
      toast.error("Something went wrong with fetchProjects");
    }
    thunkAPI.dispatch(setIsLoading(false));
  }
);

export const fetchDownloadProjectById = createAsyncThunk<void, string>(
  "projects/fetchDownloadProjectById",
  async (projectId: string, thunkAPI) => {
    try {
      const response = await API.get(
        `map-projects/${
          //projectId is undefined sometimes
          projectId || window.location.pathname.split("/")[2]
        }/semantic_map.geojson`
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = url;
      tempLink.setAttribute("download", "semantic_map.geojson");
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      toast.success("File downloaded");
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return Promise.reject();
      }
      toast.error("Something went wrong with fetchDownloadProjectById");
    }
    thunkAPI.dispatch(setIsLoading(false));
  }
);

export const fetchPatchProject = createAsyncThunk<void, ProjectPatch>(
  "projects/fetchPatchProject",
  async (data, thunkAPI) => {
    try {
      const response = await API.put(`map-projects/${data._id}`, {
        ...data,
      });
      thunkAPI.dispatch(updateProject(response.data));
      toast.success(`Updated Project: ${data.name}`);
      thunkAPI.dispatch(setIsLoading(false));
    } catch (error) {
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
      return Promise.reject();
    }
  }
);

export const fetchDeleteProject = createAsyncThunk<
  void,
  { _id: string; name?: string }
>("projects/fetchDeleteProject", async ({ _id, name }, thunkAPI) => {
  try {
    await API.delete(`map-projects/${_id}`);
    thunkAPI.dispatch(deleteProject(_id));
    toast.success(`Project deleted: ${name}`);
    thunkAPI.dispatch(setIsLoading(false));
  } catch (error) {
    const baseError = error as BaseError;
    toast.error(baseError.response.data.detail);
    return Promise.reject();
  }
});

export const fetchPostProject = createAsyncThunk<void, { data: NewProjectData, onProjectCreated: (projectId: string) => void }>(
  "projects/fetchPostProject",
  async (
    {
      data: {
        name,
        description,
        latitude,
        longitude,
        pointCloudId,
        file,
        cloudName,
        cloudDescription,
      },
      onProjectCreated,
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      let pointCloudIdValue = pointCloudId;
      if (!pointCloudIdValue) {
        const pointCloudIdResponse = await thunkAPI.dispatch(
          fetchPostPointCloud({
            name: cloudName,
            description: cloudDescription,
            file,
          })
        );

        pointCloudIdValue = pointCloudIdResponse.payload as string;
      }

      const response = await API.post("map-project", {
        name,
        description,
        latitude,
        longitude,
        pointCloudId: pointCloudIdValue,
      });
      thunkAPI.dispatch(addNewProject(response.data));
      toast.success(`Added Project: ${response.data.name}`);
      thunkAPI.dispatch(setIsLoading(false));
      onProjectCreated(response.data._id);
    } catch (error) {
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
      return Promise.reject();
    }
  }
);

export const fetchPatchMapGeoJson = createAsyncThunk<
  void,
  { projectId?: string; file: Blob; hideToast?: boolean }
>(
  "projects/fetchPatchMapGeoJson",
  async ({ projectId, file, hideToast }, thunkAPI) => {
    try {
      const jwt = window.localStorage.getItem("JWT");
      const fileDataForm = new FormData();
      fileDataForm.append("semantic_map_json_file", file);

      const response = await API.post(
        `map-projects/${
          //projectId is undefined sometimes
          projectId || window.location.pathname.split("/")[2]
        }/semantic_map.geojson`,
        fileDataForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            //TODO fix after changed on backend
            Authorization: jwt || null,
          },
        }
      );
      if (!hideToast) {
        toast.success("Updated MapGeoJson");
      }
      thunkAPI.dispatch(setIsLoading(false));
      return response.data;
    } catch (error) {
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
      return Promise.reject();
    }
  }
);

export const fetchPatchMapPbBinFile = createAsyncThunk<void, string>(
  "projects/fetchPatchMapPbBinFile",
  async (projectId: string, thunkAPI) => {
    try {
      const endpoint = `/map-projects/${
        projectId || window.location.pathname.split("/")[2]
      }/semantic_map.pb.bin`;
      const response = await API.get(endpoint);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = url;
      tempLink.setAttribute("download", "semantic_map.pb.bin");
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      toast.success("File downloaded");
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return Promise.reject();
      }
      toast.error("Something went wrong with fetchPatchMapPbBinFile");
    }
    thunkAPI.dispatch(setIsLoading(false));
  }
);
