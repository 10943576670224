import React, { useState, useRef, memo, useMemo, useCallback } from "react";

import { useOnClickOutside } from "../../../../utils/hooks";
import { ReactComponent as SelectIcon } from "./selectIcon.svg";
import {
  DropDownContainerStyled,
  DropDownHeaderStyled,
  DropDownListContainerStyled,
  ListItemStyled,
  DropDownListStyled,
  DropdownIconStyled,
} from "./styles";

type LineStringFeatureDropdownProps = {
  listData: Array<string>;
  currentItem: string;
  changeItemAction: (value: string) => void;
  placeholderValue?: string;
  specialAction?: string;
  specialActionHandler?: (specialAction?: string) => void;
  isDisabled?: boolean;
};

export const LineStringFeatureDropdown = memo(
  ({
    listData,
    currentItem,
    changeItemAction,
    placeholderValue = "",
    specialAction,
    specialActionHandler,
    isDisabled,
  }: LineStringFeatureDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const listItemClick = (value: string) => {
      changeItemAction?.(value);
      setIsOpen(!isOpen);
    };

    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    const handleClickOutside = useCallback(() => setIsOpen(false), []);
    useOnClickOutside(ref, handleClickOutside);

    const sortedListData: Array<string> = useMemo(
      () =>
        [...listData].sort((prev, next) =>
          prev.toString().localeCompare(next.toString())
        ),
      [listData]
    );

    return (
      <DropDownContainerStyled ref={ref}>
        <DropDownHeaderStyled
          isOpen={isOpen}
          currentItem={currentItem}
          onClick={toggleDropdown}
        >
          {currentItem || placeholderValue}
          {!isDisabled && (
            <DropdownIconStyled>
              <SelectIcon />
            </DropdownIconStyled>
          )}
        </DropDownHeaderStyled>
        {!isDisabled && isOpen && (
          <DropDownListContainerStyled>
            <DropDownListStyled>
              {specialAction && (
                <ListItemStyled
                  onClick={() => {
                    specialActionHandler
                      ? specialActionHandler(specialAction)
                      : null;
                    setIsOpen(!isOpen);
                  }}
                >
                  {specialAction}
                </ListItemStyled>
              )}
              {sortedListData.map((element, index) => (
                <ListItemStyled
                  key={`${element}-${index}`}
                  onClick={() => listItemClick(element)}
                >
                  {element}
                </ListItemStyled>
              ))}
            </DropDownListStyled>
          </DropDownListContainerStyled>
        )}
      </DropDownContainerStyled>
    );
  }
);
