import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { EMPTY_MAP_STRUCTS_NEW, EMPTY_LANE } from "../constants/map-constants";
import { MapStructs } from "../models/map-interface";

export interface DataError {
  text: string;
  featureIds: Array<number>;
  laneId: number;
  lineType: string;
}

export const getFilteredFeaturesProps = (
  features: Array<Feature<Geometry, GeoJsonProperties>>,
  errors: Array<DataError>
) => {
  const getIsAnyFeatureErrors = (
    errors: Array<DataError>,
    info: GeoJsonProperties,
    feature: Feature<Geometry, GeoJsonProperties>
  ) =>
    errors.filter(
      (error: DataError) =>
        error.lineType === info?.line_type &&
        error.featureIds.includes(feature?.properties?.feature_id)
    ).length !== 0;

  const getFilteredFeatureInfoPlist = (
    feature: Feature<Geometry, GeoJsonProperties>,
    errors: Array<DataError>
  ) =>
    feature?.properties?.feature_info_list?.filter(
      (info: GeoJsonProperties) =>
        !(info?.line_type && getIsAnyFeatureErrors(errors, info, feature))
    );

  return features.map((feature) => ({
    ...feature,
    properties: {
      ...feature.properties,
      feature_info_list: getFilteredFeatureInfoPlist(feature, errors),
    },
  }));
};

export const getMapStructsFromFeatures = (
  features: Array<Feature<Geometry, GeoJsonProperties>>,
  speedLimits: any
): {
  mapStructs: MapStructs;
  errors: Array<DataError>;
} => {
  const newMapStructs = JSON.parse(JSON.stringify(EMPTY_MAP_STRUCTS_NEW));
  const errors: Array<DataError> = [];

  features.forEach((feature) => {
    const { properties } = feature;
    if (properties) {
      const featureId = Number(properties.feature_id);
      if (
        properties.feature_info_list &&
        Array.isArray(properties.feature_info_list)
      ) {
        properties.feature_info_list.forEach((featureInfo: any) => {
          const laneIds: Array<number> = String(featureInfo.lane_association)
            .split(",")
            .map((currentLaneId: string) => Number(currentLaneId));
          if (
            featureInfo.polygon_type &&
            featureInfo.polygon_type === "intersection"
          ) {
            const intersectionId = featureInfo.intersection_id;
            newMapStructs.intersections[intersectionId] = {
              intersection_id: intersectionId,
              associated_lane_ids: laneIds,
            };
          } else {
            const laneId = Number(laneIds[0]);
            if (laneId) {
              if (!newMapStructs.lanes[laneId]) {
                newMapStructs.lanes[laneId] = { ...EMPTY_LANE };
                newMapStructs.lanes[laneId].lane_id = laneId;
              }
              if (speedLimits[laneId]) {
                newMapStructs.lanes[laneId].speed_limit = speedLimits[laneId];
              }
              if (
                featureInfo.polygon_type === "stop_sign" &&
                featureInfo.stop_sign_id
              ) {
                const newStopSign = {
                  stop_sign_id: Number(featureInfo.stop_sign_id),
                  associated_lane_id: Number(featureInfo.lane_association),
                  associated_control_line_id: Number(
                    featureInfo.control_line_association
                  ),
                };
                newMapStructs.stopSigns[featureInfo.stop_sign_id] = {
                  ...newStopSign,
                };
              }
              if (
                featureInfo.line_type === "stop_sign_control_line" &&
                featureInfo.control_line_id
              ) {
                const newControlLine = {
                  associated_lane_id: Number(featureInfo.lane_association),
                  associated_stop_sign_id: Number(
                    featureInfo.stop_sign_association
                  ),
                  control_line_id: Number(featureInfo.control_line_id),
                };
                newMapStructs.controlLines[featureInfo.control_line_id] = {
                  ...newControlLine,
                };
              }
              if (featureInfo.line_type === "lane_start_line") {
                if (newMapStructs.lanes[laneId].start_line_id) {
                  errors.push({
                    featureIds: [
                      featureId,
                      newMapStructs.lanes[laneId].start_line_id,
                    ],
                    laneId,
                    lineType: featureInfo.line_type,
                    text: `Lane ${laneId} can not have 2 or more ${featureInfo.line_type}. Fill in the properties for features ${featureId} and ${newMapStructs.lanes[laneId].start_line_id} manually.`,
                  });
                  newMapStructs.lanes[laneId].start_line_id = -1;
                } else {
                  newMapStructs.lanes[laneId].start_line_id = featureId;
                }
              }
              if (featureInfo.line_type === "lane_termination_line") {
                newMapStructs.lanes[laneId].termination_line_id = featureId;
              }
              if (featureInfo.line_type === "lane_left_boundary_line") {
                newMapStructs.lanes[laneId].left_boundary_line_id = featureId;
              }
              if (featureInfo.line_type === "lane_right_boundary_line") {
                newMapStructs.lanes[laneId].right_boundary_line_id = featureId;
              }
            }
          }
        });
      }
    }
  });

  return { mapStructs: newMapStructs, errors };
};
