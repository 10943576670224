import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slide as Menu } from "react-burger-menu";
import * as React from "react";
import { Button, Accordion, ListGroup } from "react-bootstrap";

import { Intersection, StopSign } from "../../map-interface.d";
import { Checkbox } from "../common/Checkbox";
import {
  getMapStructs,
  removeLane,
  resetSpeedLimits,
} from "../../store/slices/mapStructs";

import {
  BmItemTitleStyled,
  CardBodyStyled,
  CardContainerStyled,
  CardHeaderStyled,
  CardStyled,
  EditPlaneButtonStyled,
  EditPlaneToolboxStyled,
  MenuBtnStyled,
  SwitchStyled,
  CancelSelectedLanesButtonStyled,
} from "./styles";
import "./side-menu.css";
import { ResetSpeedLimits } from "./components/ResetSpeedLimits";
import { ExpandedLane } from "./components/ExpandedLane";
import { IntersectionsCard } from "./components/IntersectionsCard";
import { CONFIRM_ACTION_MODAL, toggleModal } from "../../store/slices/modals";
import { getFeatures, setFeatures } from "../../store/slices/currentMap";
import { getFilteredFeatures } from "../../utils";

type SideMenuProps = {
  renderEditPlane: boolean;
  onLaneFocus: (laneIds: Array<number>) => void;
  onStopSignFocus: (stopSignId: number) => void;
  setEditPlaneOpen: (open: boolean) => void;
  adjustEditPlaneElevation: (diff: number) => void;
  adjustEditPlaneRadius: (diff: number) => void;
  selectedLaneIds: Array<number>;
};

export const SideMenu: FC<SideMenuProps> = ({
  renderEditPlane,
  onLaneFocus,
  onStopSignFocus,
  setEditPlaneOpen,
  adjustEditPlaneElevation,
  adjustEditPlaneRadius,
  selectedLaneIds,
}) => {
  const dispatch = useDispatch();
  const [openedTabs, setOpenedTabs] = useState<Array<number>>([]);
  const mapStructs = useSelector(getMapStructs);
  const mapFeatures = useSelector(getFeatures);
  const { features } = mapFeatures;

  const { lanes } = mapStructs;

  if (mapStructs === undefined) {
    return null;
  }

  const removeLaneAction = (laneId: number) => {
    dispatch(
      toggleModal({
        type: CONFIRM_ACTION_MODAL,
        data: {
          text: "Are you sure you want to delete the entire lane?",
          confirmAction: () => {
            dispatch(removeLane(laneId));
            setFeatures({
              ...mapFeatures,
              features: getFilteredFeatures(features, laneId),
            });
          },
        },
      })
    );
  };

  const selectLane = (event: any, laneId: number) => {
    event.preventDefault();
    let newSelectedLanes = [];

    if (selectedLaneIds.includes(laneId)) {
      newSelectedLanes = [...selectedLaneIds.filter((lane) => laneId !== lane)];
    } else {
      newSelectedLanes = [...selectedLaneIds, laneId];
    }
    onLaneFocus(newSelectedLanes);
  };

  const renderEditPlaneToolBox = () => {
    const elev_step = 0.5;
    const radius_step = 0.00005;
    return (
      <EditPlaneToolboxStyled>
        <BmItemTitleStyled>
          Edit Plane
          <SwitchStyled
            name="displayEditPlane"
            value={renderEditPlane ? 1 : 0}
            onChange={() => {
              setEditPlaneOpen(!renderEditPlane);
            }}
          ></SwitchStyled>
        </BmItemTitleStyled>
        <EditPlaneButtonStyled>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              adjustEditPlaneElevation(elev_step);
            }}
          >
            Up
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              adjustEditPlaneElevation(-elev_step);
            }}
          >
            Down
          </Button>
        </EditPlaneButtonStyled>
        <EditPlaneButtonStyled>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              adjustEditPlaneRadius(radius_step);
            }}
          >
            +
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              adjustEditPlaneRadius(-radius_step);
            }}
          >
            -
          </Button>
        </EditPlaneButtonStyled>
      </EditPlaneToolboxStyled>
    );
  };

  const setActiveElement = (elementId: number) => {
    if (openedTabs.includes(elementId)) {
      setOpenedTabs([...openedTabs].filter((tabId) => tabId !== elementId));
    } else {
      setOpenedTabs([...openedTabs, elementId]);
    }
  };

  const renderIntersectionList = () => {
    const { intersections } = mapStructs;

    if (mapStructs === undefined || intersections === undefined) {
      return null;
    }

    const listItems: JSX.Element[] = [];

    Object.values(intersections).forEach((intersection: Intersection) => {
      const { intersection_id } = intersection;
      listItems.push(
        <IntersectionsCard
          key={intersection_id}
          isOpened={openedTabs.includes(intersection_id)}
          intersection={intersection}
          setActiveElement={setActiveElement}
          selectedLaneIds={selectedLaneIds}
          onLaneFocus={onLaneFocus}
        />
      );
    });

    return (
      <div>
        <BmItemTitleStyled>Intersections</BmItemTitleStyled>
        <Accordion defaultActiveKey="0">{listItems}</Accordion>
      </div>
    );
  };

  const renderStopSignProps = (stop_sign_props: StopSign) => {
    const propsList: JSX.Element[] = [];
    (Object.keys(stop_sign_props) as Array<keyof StopSign>).forEach((prop) => {
      const currentItem = `${prop}: ${stop_sign_props[prop]}`;
      propsList.push(<ListGroup.Item key={prop}>{currentItem}</ListGroup.Item>);
    });

    return <ListGroup as="ul">{propsList}</ListGroup>;
  };

  const renderStopSignList = () => {
    const { stopSigns } = mapStructs;

    if (mapStructs === undefined || stopSigns === undefined) {
      return null;
    }

    const listItems: JSX.Element[] = [];

    Object.values(stopSigns).forEach((stop_sign_props: any) => {
      const { stop_sign_id } = stop_sign_props;
      listItems.push(
        <CardContainerStyled key={stop_sign_id}>
          <CardHeaderStyled>
            <Button
              variant="link"
              size="sm"
              onClick={() => {
                onStopSignFocus(stop_sign_id);
              }}
            >
              {stop_sign_id}
            </Button>
          </CardHeaderStyled>
          <Accordion.Collapse eventKey={stop_sign_id}>
            <CardBodyStyled>
              {renderStopSignProps(stop_sign_props)}
            </CardBodyStyled>
          </Accordion.Collapse>
        </CardContainerStyled>
      );
    });

    return (
      <div>
        <BmItemTitleStyled>Stop Signs</BmItemTitleStyled>
        <Accordion defaultActiveKey="0">{listItems}</Accordion>
      </div>
    );
  };

  const canselSelectedLanes = () => {
    if (selectedLaneIds.length !== 0) {
      onLaneFocus([]);
    }
  };

  return (
    <Menu right={true} isOpen={true}>
      <div>{renderEditPlaneToolBox()}</div>
      <div>
        {lanes && (
          <div>
            <BmItemTitleStyled>Lane Elements</BmItemTitleStyled>
            {Object.values(lanes).length > 0 && (
              <ResetSpeedLimits
                resetAllSpeedLimits={() => dispatch(resetSpeedLimits())}
              />
            )}
            {selectedLaneIds.length !== 0 && (
              <CancelSelectedLanesButtonStyled onClick={canselSelectedLanes}>
                Cancel Select
              </CancelSelectedLanesButtonStyled>
            )}
            <Accordion defaultActiveKey="0">
              {Object.values(lanes).map((lane: any) => {
                const { lane_id } = lane;

                return (
                  <CardContainerStyled key={lane_id}>
                    <CardStyled>
                      <MenuBtnStyled
                        onClick={() => {
                          setActiveElement(lane_id);
                        }}
                      >
                        <span>{lane_id}</span>
                        <Checkbox
                          key={lane_id}
                          checked={selectedLaneIds.includes(lane_id)}
                          onChange={(e: Event) => selectLane(e, lane_id)}
                        />
                      </MenuBtnStyled>
                    </CardStyled>
                    {openedTabs.includes(lane_id) && (
                      <ExpandedLane lane={lane} removeLane={removeLaneAction} />
                    )}
                  </CardContainerStyled>
                );
              })}
            </Accordion>
          </div>
        )}
      </div>
      <div>{renderIntersectionList()}</div>
      <div>{renderStopSignList()}</div>
    </Menu>
  );
};
