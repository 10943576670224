import * as React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import update from "immutability-helper";
import { Col } from "react-bootstrap";

import * as MapConst from "../../constants/map-constants"; // Register ply file loader - Used by pointcloud layer for loading ply
import {
  Intersection,
  MapStructs,
  StopSign,
} from "../../models/map-interface.d";
import { ItemWithContainerValues } from "../common/ItemWithContainerValues";
import {
  getMapStructs,
  setControlLine,
  setLane,
  setStopSign,
  removeControlLine,
  setIntersection,
  removeStopSign,
  removeIntersection,
  setNewLane,
} from "../../store/slices/mapStructs";
import { ControlLine } from "../../map-interface.d";
import { getFeatures, getLockedFeatures } from "../../store/slices/currentMap";
import { generateUUID } from "../../utils";

import { DropdownButtonStyled, RowStyled } from "./styles";

//TODO need to edit upper order types

type PolygonFeatureProps = {
  mapStructs: MapStructs;
  testFeatures: any;
  availableIds: any;
  updateFeatures: (temp_features: any) => void;
  feature_info: any;
  feature_index: number;
  feature_info_index: number;
  setNewLaneAction: (laneId: number) => void;
  setStopSignAction: (stopSign: StopSign) => void;
  setControlLineAction: (controlLine: ControlLine) => void;
  setIntersectionAction: (intersection: Intersection) => void;
  removeStopSignAction: (stopSignId: number) => void;
  removeIntersectionAction: (intersectionId: number) => void;
  lockedFeatures: Array<number>;
};

class PolygonFeatureComponent extends React.Component<PolygonFeatureProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentLane: null,
    };
  }

  _addNewStopSignToMapStructs(stop_sign_id: number) {
    const { setStopSignAction } = this.props;
    const newStopSign: StopSign = {
      stop_sign_id: stop_sign_id,
      associated_lane_id: MapConst.INVALID_ID_NUMBER,
      associated_control_line_id: MapConst.INVALID_ID_NUMBER,
    };
    setStopSignAction(newStopSign);
  }

  _addNewPolygonMapStructElement(e: any, feature_info: any) {
    if (e === MapConst.STOP_SIGN_STRING_NAME) {
      this._addNewStopSignToMapStructs(
        feature_info[MapConst.STOP_SIGN_ID_STRING_NAME]
      );
    } else if (e === MapConst.INTERSECTION_STRING_NAME) {
      this._addNewIntersectionToMapStructs(
        feature_info[MapConst.INTERSECTION_ID_STRING_NAME]
      );
    }
  }

  _addNewIntersectionToMapStructs(intersectionId: number) {
    const { setIntersectionAction } = this.props;
    const newIntersection: Intersection = {
      intersection_id: intersectionId,
      associated_lane_ids: [],
    };
    setIntersectionAction(newIntersection);
  }

  getAvailableControlLines(controlLineAssociationId: number | string) {
    const { mapStructs, feature_info } = this.props;
    const { controlLines, stopSigns } = mapStructs;
    const { lane_association } = feature_info;
    const filteredControlLines: Array<number | string> = [];

    if (lane_association === "NULL") return [];

    Object.values(controlLines).forEach((controlLine: ControlLine) => {
      if (controlLine.associated_lane_id === Number(lane_association)) {
        filteredControlLines.push(controlLine.control_line_id);
      }
    });
    const available_control_line_ids_set = new Set(filteredControlLines);

    Object.values(stopSigns).forEach((stopSign) => {
      const { associated_control_line_id } = stopSign;
      available_control_line_ids_set.delete(associated_control_line_id);
    });
    const available_control_line_ids = Array.from(
      available_control_line_ids_set
    );
    if (controlLineAssociationId !== MapConst.NULL_STRING_NAME) {
      available_control_line_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_control_line_ids;
  }

  getAvailablePolygonTypes(feature_index: number, curr_value: string) {
    const available_polygon_types_set = new Set([
      MapConst.STOP_SIGN_STRING_NAME,
      MapConst.INTERSECTION_STRING_NAME,
    ]);
    available_polygon_types_set.delete(curr_value);
    // handle in-feature conflict
    const temp_feature_info_list =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list;
    if (!temp_feature_info_list) {
      return Array.from(available_polygon_types_set);
    }
    for (let i = 0; i < temp_feature_info_list.length; i++) {
      const curr_feature_info = temp_feature_info_list[i];
      const curr_polygon_type =
        curr_feature_info[MapConst.POLYGON_TYPE_STRING_NAME];
      if (curr_polygon_type === MapConst.STOP_SIGN_STRING_NAME) {
        available_polygon_types_set.delete(MapConst.INTERSECTION_STRING_NAME);
        available_polygon_types_set.delete(MapConst.STOP_SIGN_STRING_NAME);
      } else if (curr_polygon_type === MapConst.INTERSECTION_STRING_NAME) {
        available_polygon_types_set.delete(MapConst.STOP_SIGN_STRING_NAME);
        available_polygon_types_set.delete(MapConst.INTERSECTION_STRING_NAME);
      }
    }
    const available_polygon_types = Array.from(available_polygon_types_set);
    if (curr_value !== MapConst.NULL_STRING_NAME) {
      available_polygon_types.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_polygon_types;
  }

  getAvailableLaneIdsForStopSign(
    feature_index: number,
    feature_info_index: number
  ) {
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list[feature_info_index];
    const available_lane_ids_set = this.props.availableIds.laneIdsForStopSign;
    const available_lane_ids = Array.from(available_lane_ids_set);

    available_lane_ids.unshift(MapConst.NEW_STRING_NAME);
    if (
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
      MapConst.NULL_STRING_NAME
    ) {
      available_lane_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_lane_ids;
  }

  getAvailableLaneIdsForIntersection(
    feature_index: number,
    feature_info_index: number
  ) {
    const { testFeatures, availableIds } = this.props;
    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    const { lane_association } = temp_feature_info;
    if (!lane_association) return [];
    const { laneIdsForIntersection } = availableIds;
    const available_lane_ids =
      Array.from(laneIdsForIntersection.values()) || [];
    available_lane_ids.unshift(MapConst.NEW_STRING_NAME);
    if (
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
      MapConst.NULL_STRING_NAME
    ) {
      available_lane_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_lane_ids.filter(
      (laneId) => !lane_association.includes(laneId)
    );
  }

  getAvailableLaneIdsForPolygon(
    feature_index: number,
    feature_info_index: number
  ) {
    const { testFeatures } = this.props;
    const available_lane_ids: never[] = [];
    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.NULL_STRING_NAME
    ) {
      return available_lane_ids;
    }
    const polygon_type = temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME];
    if (polygon_type === MapConst.STOP_SIGN_STRING_NAME) {
      return this.getAvailableLaneIdsForStopSign(
        feature_index,
        feature_info_index
      );
    } else {
      return this.getAvailableLaneIdsForIntersection(
        feature_index,
        feature_info_index
      );
    }
  }

  _updateLaneAssocOfStopSign(
    affectedStopSignId: number | string,
    newLaneAssociation: number
  ) {
    if (affectedStopSignId === MapConst.NULL_STRING_NAME) {
      return;
    }
    const { mapStructs, setStopSignAction } = this.props;
    const { stopSigns } = mapStructs;

    newLaneAssociation = isNaN(newLaneAssociation)
      ? MapConst.INVALID_ID_NUMBER
      : Number(newLaneAssociation);

    const affectedStopSign = { ...stopSigns[Number(affectedStopSignId)] };
    affectedStopSign["associated_lane_id"] = newLaneAssociation;

    setStopSignAction(affectedStopSign);
  }

  _updateLaneAssocOfIntersection(
    affectedIntersectionId: number | string,
    affectedLanes: Array<number>
  ) {
    if (affectedIntersectionId === MapConst.NULL_STRING_NAME) {
      return;
    }
    const { mapStructs, setIntersectionAction } = this.props;
    const { intersections } = mapStructs;

    const affectedIntersection = {
      ...intersections[Number(affectedIntersectionId)],
    };
    if (affectedLanes.length === 0) {
      // new lane association is NULL
      affectedIntersection.associated_lane_ids = [];
    } else {
      affectedIntersection.associated_lane_ids = affectedLanes;
    }

    setIntersectionAction(affectedIntersection);
  }

  _updateControlLineAssocOfStopSign(
    affectedStopSignId: number | string,
    newControlLineAssociation: number
  ) {
    if (affectedStopSignId === MapConst.NULL_STRING_NAME) {
      return;
    }
    const { mapStructs, setStopSignAction } = this.props;
    const { stopSigns } = mapStructs;

    newControlLineAssociation = isNaN(newControlLineAssociation)
      ? MapConst.INVALID_ID_NUMBER
      : newControlLineAssociation;
    const affectedStopSign = { ...stopSigns[Number(affectedStopSignId)] };
    affectedStopSign["associated_control_line_id"] = newControlLineAssociation;

    setStopSignAction(affectedStopSign);
  }

  _clearStaleStopSignMapStructElement(feature_info: any) {
    const { mapStructs, removeStopSignAction, setControlLineAction } =
      this.props;
    // remove stop sign struct
    removeStopSignAction(
      Number(feature_info[MapConst.STOP_SIGN_ID_STRING_NAME])
    );
    // update control line association for this stop sign to sentinel
    const { controlLines } = mapStructs;
    const affectedControlLineId = Number(
      feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME]
    );
    if (!affectedControlLineId || !controlLines[affectedControlLineId]) {
      toast.error(
        `undefined / missing control line id for map struct's stop sign ${
          feature_info[MapConst.STOP_SIGN_ID_STRING_NAME]
        }`
      );
    } else {
      setControlLineAction({
        ...controlLines[affectedControlLineId],
        associated_stop_sign_id: MapConst.INVALID_ID_NUMBER,
      });
    }
  }

  _clearStalePolygonMapStructElement(feature_info: { [x: string]: string }) {
    const { removeIntersectionAction } = this.props;
    if (
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.NULL_STRING_NAME
    ) {
      return;
    } else if (
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_STRING_NAME
    ) {
      this._clearStaleStopSignMapStructElement(feature_info);
    } else if (
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.INTERSECTION_STRING_NAME
    ) {
      removeIntersectionAction(
        Number(feature_info[MapConst.INTERSECTION_ID_STRING_NAME])
      );
    }
  }

  handleChangeStopLane = (
    feature_index: number,
    feature_info_index: number,
    e: any
  ) => {
    const { testFeatures, updateFeatures, setNewLaneAction } = this.props;

    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    if (temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] === e) {
      return;
    }

    let lane_id;
    if (e === MapConst.NEW_STRING_NAME) {
      const newLaneId = generateUUID();
      lane_id = newLaneId;
      setNewLaneAction(lane_id);
    } else {
      lane_id = Number(e);
    }

    temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] = lane_id;

    // update corresponding map struct of new lane id
    this._updateLaneAssocOfStopSign(
      temp_feature_info[MapConst.STOP_SIGN_ID_STRING_NAME],
      lane_id
    );

    const result_state = update(testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    updateFeatures(result_state);
  };

  handleChangeIntersectionLane = (
    laneIndex: number,
    currentLanes: Array<number>,
    e: any
  ) => {
    const {
      feature_index,
      testFeatures,
      updateFeatures,
      setNewLaneAction,
      feature_info_index,
    } = this.props;
    let affectedLanes = [...currentLanes];

    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];

    if (e === "NULL") {
      affectedLanes = affectedLanes.filter(
        (lane) => lane !== currentLanes[laneIndex]
      );
    }

    let lane_id;
    if (e === MapConst.NEW_STRING_NAME) {
      const newLaneId = generateUUID();
      lane_id = newLaneId;
      setNewLaneAction(lane_id);
    }
    if (e !== "NULL" && e !== MapConst.NEW_STRING_NAME) {
      lane_id = Number(e);
    }

    temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] = String(
      lane_id ? [...affectedLanes, lane_id] : affectedLanes
    );

    // update corresponding map struct of new lane id
    this._updateLaneAssocOfIntersection(
      temp_feature_info[MapConst.INTERSECTION_ID_STRING_NAME],
      lane_id ? [...affectedLanes, lane_id] : affectedLanes
    );

    const result_state = update(testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    updateFeatures(result_state);
  };

  getSetLanesElements = (availableIds: any) => {
    const { feature_info, mapStructs, feature_index, lockedFeatures } =
      this.props;
    const isLocked = lockedFeatures.includes(feature_index);
    const { intersections } = mapStructs;
    const { intersection_id } = feature_info;
    const currentIntersection: Intersection = intersections[intersection_id];
    if (!currentIntersection) return;

    let laneElements: Array<number | string> = ["NULL", "NULL"];
    const currentLanes = [...currentIntersection.associated_lane_ids];

    if (currentLanes.length >= 1) {
      laneElements = [...currentLanes];
      laneElements.push("NULL");
    }

    return (
      <>
        {laneElements.map((value, laneIndex) => (
          <RowStyled key={`${laneIndex}-${value}`}>
            <Col>
              <b>LANE ASSOC</b>
            </Col>
            <Col>
              <DropdownButtonStyled
                title={value}
                id="dropdown-menu-align-right"
                size="sm"
                onSelect={(e: any) =>
                  this.handleChangeIntersectionLane(laneIndex, currentLanes, e)
                }
              >
                {!isLocked && <ItemWithContainerValues values={availableIds} />}
              </DropdownButtonStyled>
            </Col>
          </RowStyled>
        ))}
      </>
    );
  };

  handleChangeInControlLineAssociationValue(
    feature_index: string | number,
    feature_info_index: string | number,
    e: any
  ) {
    const { testFeatures, updateFeatures } = this.props;
    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    if (
      temp_feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] ===
      Number(e)
    ) {
      return;
    }

    temp_feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] =
      Number(e);

    // update control line association for this stop sign in mapStruct
    this._updateControlLineAssocOfStopSign(
      temp_feature_info[MapConst.STOP_SIGN_ID_STRING_NAME],
      e === MapConst.NEW_STRING_NAME ? generateUUID() : Number(e)
    );

    const result_state = update(testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    updateFeatures(result_state);
  }

  handleChangeInPolygonTypeValue(
    feature_index: string | number,
    feature_info_index: string | number,
    e: string
  ) {
    const { testFeatures, updateFeatures } = this.props;
    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    if (temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] === e) {
      return;
    }

    // remove struct related to previous type of feature info
    this._clearStalePolygonMapStructElement(temp_feature_info);

    temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] = e;
    temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] =
      MapConst.NULL_STRING_NAME;
    temp_feature_info[MapConst.STOP_SIGN_ID_STRING_NAME] =
      e === MapConst.STOP_SIGN_STRING_NAME
        ? generateUUID()
        : MapConst.NULL_STRING_NAME;
    temp_feature_info[MapConst.INTERSECTION_ID_STRING_NAME] =
      e === MapConst.INTERSECTION_STRING_NAME
        ? generateUUID()
        : MapConst.NULL_STRING_NAME;
    temp_feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] =
      MapConst.NULL_STRING_NAME;

    // add new element to map struct
    this._addNewPolygonMapStructElement(e, temp_feature_info);

    const result_state = update(testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    updateFeatures(result_state);
  }

  _renderPolygonTypeDropDown(
    available_polygon_types: string[],
    feature_info: {
      [x: string]:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
    },
    feature_index: number,
    feature_info_index: string | number
  ) {
    const { lockedFeatures } = this.props;

    const isLocked = lockedFeatures.includes(feature_index);
    return (
      <RowStyled
        key={
          feature_index +
          "_" +
          feature_info_index +
          "_" +
          MapConst.POLYGON_TYPE_STRING_NAME
        }
      >
        <Col>
          <b>POLYGON TYPE</b>
        </Col>
        <Col>
          <DropdownButtonStyled
            title={feature_info[MapConst.POLYGON_TYPE_STRING_NAME] || ""}
            id="dropdown-menu-align-right"
            size="sm"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSelect={this.handleChangeInPolygonTypeValue.bind(
              this,
              feature_index,
              feature_info_index
            )}
          >
            {!isLocked && (
              <ItemWithContainerValues values={available_polygon_types} />
            )}
          </DropdownButtonStyled>
        </Col>
      </RowStyled>
    );
  }

  render() {
    const { feature_info, feature_index, feature_info_index, lockedFeatures } =
      this.props;
    const isLocked = lockedFeatures.includes(feature_index);
    const available_control_line_ids = this.getAvailableControlLines(
      feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME]
    );
    const available_polygon_types = this.getAvailablePolygonTypes(
      feature_index,
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME]
    );
    const available_lane_ids = this.getAvailableLaneIdsForPolygon(
      feature_index,
      feature_info_index
    );

    return [
      this._renderPolygonTypeDropDown(
        available_polygon_types,
        feature_info,
        feature_index,
        feature_info_index
      ),
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_STRING_NAME &&
        MapConst.STOP_SIGN_ID_STRING_NAME in feature_info && (
          <>
            <RowStyled
              key={
                feature_index +
                "_" +
                feature_info_index +
                "_" +
                MapConst.STOP_SIGN_ID_STRING_NAME
              }
            >
              <Col>
                <b>STOP SIGN ID</b>
              </Col>
              <Col>
                <b>{feature_info[MapConst.STOP_SIGN_ID_STRING_NAME]}</b>
              </Col>
            </RowStyled>
            <RowStyled
              key={
                feature_index +
                "_" +
                feature_info_index +
                "_" +
                MapConst.STOP_SIGN_ID_STRING_NAME
              }
            >
              <Col>
                <b>STOP NAME</b>
              </Col>
              <Col>
                <b>{feature_info[MapConst.STOP_SIGN_NAME] || "No Data"} </b>
              </Col>
            </RowStyled>
            <RowStyled
              key={
                feature_index +
                "_" +
                feature_info_index +
                "_" +
                MapConst.LANE_ASSOCIATION_STRING_NAME
              }
            >
              <Col xs={6}>
                <b>LANE ASSOC</b>
              </Col>
              <Col xs={6}>
                <DropdownButtonStyled
                  title={
                    feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] ||
                    "NULL"
                  }
                  id="dropdown-menu-align-right"
                  size="sm"
                  onSelect={(e: any) =>
                    this.handleChangeStopLane(
                      feature_index,
                      feature_info_index,
                      e
                    )
                  }
                >
                  {!isLocked && (
                    <ItemWithContainerValues values={available_lane_ids} />
                  )}
                </DropdownButtonStyled>
              </Col>
            </RowStyled>
          </>
        ),
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
        MapConst.INTERSECTION_STRING_NAME &&
        MapConst.INTERSECTION_ID_STRING_NAME in feature_info && (
          <>
            <RowStyled
              key={
                feature_index +
                "_" +
                feature_info_index +
                "_" +
                MapConst.INTERSECTION_ID_STRING_NAME
              }
            >
              <Col>
                <b>INTERSECTION ID</b>
              </Col>
              <Col>
                <b>{feature_info[MapConst.INTERSECTION_ID_STRING_NAME]}</b>
              </Col>
            </RowStyled>
            {this.getSetLanesElements(available_lane_ids)}
          </>
        ),
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_STRING_NAME &&
        MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME in feature_info && (
          <RowStyled
            key={
              feature_index +
              "_" +
              feature_info_index +
              "_" +
              MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME
            }
          >
            <Col>
              <b>CONTROL LINE ASSOC</b>
            </Col>
            <Col>
              <DropdownButtonStyled
                title={
                  feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] ||
                  ""
                }
                id="dropdown-menu-align-right"
                size="sm"
                onSelect={this.handleChangeInControlLineAssociationValue.bind(
                  this,
                  feature_index,
                  feature_info_index
                )}
              >
                {!isLocked && (
                  <ItemWithContainerValues
                    values={available_control_line_ids}
                  />
                )}
              </DropdownButtonStyled>
            </Col>
          </RowStyled>
        ),
    ];
  }
}

const mapStateToProps = (state: any) => ({
  mapStructs: getMapStructs(state),
  testFeatures: getFeatures(state),
  lockedFeatures: getLockedFeatures(state),
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setControlLineAction: (params: any) => dispatch(setControlLine(params)),
    setLaneAction: (params: any) => dispatch(setLane(params)),
    setStopSignAction: (params: any) => dispatch(setStopSign(params)),
    setIntersectionAction: (params: any) => dispatch(setIntersection(params)),
    removeStopSignAction: (params: any) => dispatch(removeStopSign(params)),
    removeIntersectionAction: (params: any) =>
      dispatch(removeIntersection(params)),
    removeControlLineAction: (params: any) =>
      dispatch(removeControlLine(params)),
    setNewLaneAction: (params: number) => dispatch(setNewLane(params)),
  };
};

const PolygonFeature = connect(
  mapStateToProps,
  mapDispatchToProps
)(PolygonFeatureComponent);

export { PolygonFeature };
