import * as React from "react";
import { FC, memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ElevationMode, Position } from "@nebula.gl/edit-modes";

import {
  Toolbox,
  ToolboxButton,
  ToolboxCheckbox,
  ToolboxControl,
  ToolboxRow,
  ToolboxSelect,
} from "../../components/common/toolbox";
import FeatureItem from "../FeatureItem";
import MeasureElements from "../MeasureElements";
import { sortArrayByIndices } from "../../utils";
import {
  getFeatures,
  getMode,
  getModeConfig,
  getSelectedFeatureIndexes,
  setSelectedFeatureIndexes,
} from "../../store/slices/currentMap";
import { modeConfigAdditionalSnapTargets } from "../../constants/map-constants";

import { MapToolboxProps } from "./index.d";
import { ToolboxTitleStyled } from "./styles";
import { Input } from "../common/Input";

export const MapToolbox: FC<MapToolboxProps> = memo(
  ({
    measureFeatures,
    removeMeasureElements,
    updateModeConfig,
    pointsRemovable,
    updatePointsRemovable,
    deleteFeatureProperty,
    featureMenuClick,
    updateSelectedFeatureIndexes,
    viewport,
  }) => {
    const dispatch = useDispatch();
    const [searchFeatureValue, setSearchFeatureValue] = useState<string>("");

    const mapFeatures = useSelector(getFeatures);
    const currentMode = useSelector(getMode);
    const modeConfig = useSelector(getModeConfig);
    const selectedFeatureIndexes = useSelector(getSelectedFeatureIndexes);

    let currentModeConfig = modeConfig;

    if (currentMode === "ElevationMode") {
      currentModeConfig = {
        ...currentModeConfig,
        viewport,
        calculateElevationChange: (opts: {
          pointerDownScreenCoords: Position;
          screenCoords: Position;
        }) =>
          ElevationMode.calculateElevationChangeWithViewport(viewport, opts),
      };
    } else if (currentMode === "ModifyMode") {
      currentModeConfig = {
        ...currentModeConfig,
        viewport,
      };
    } else if (
      currentMode === "SnappableMode" &&
      currentModeConfig &&
      currentModeConfig.enableSnapping
    ) {
      // Snapping can be accomplished to features that aren't rendered in the same layer
      currentModeConfig = {
        ...currentModeConfig,
        additionalSnapTargets: modeConfigAdditionalSnapTargets,
      };
    }

    const buildSelectFeatureCheckboxes = useCallback(() => {
      const { features } = mapFeatures;
      const checkboxes: JSX.Element[] = [];

      features.forEach((v, i) =>
        checkboxes.push(
          <FeatureItem
            deleteFeatureProperty={deleteFeatureProperty}
            featureMenuClick={featureMenuClick}
            updateSelectedFeatureIndexes={updateSelectedFeatureIndexes}
            key={`feature-item-${i}`}
            index={i}
            featureType={v?.geometry?.type}
          />
        )
      );

      return sortArrayByIndices(
        checkboxes,
        selectedFeatureIndexes,
        searchFeatureValue,
        mapFeatures.features
      );
    }, [
      mapFeatures,
      deleteFeatureProperty,
      featureMenuClick,
      updateSelectedFeatureIndexes,
      selectedFeatureIndexes,
      searchFeatureValue,
    ]);

    const onSearchAction = useCallback(
      (val: any) => setSearchFeatureValue(val?.target?.value),
      []
    );

    return (
      <Toolbox>
        {currentMode === "ModifyMode" && (
          <ToolboxRow key="modify">
            <ToolboxTitleStyled>Allow removing points</ToolboxTitleStyled>
            <ToolboxControl>
              <ToolboxCheckbox
                checked={pointsRemovable}
                onChange={() => updatePointsRemovable()}
              />
            </ToolboxControl>
          </ToolboxRow>
        )}
        {currentMode === "MeasureDistanceMode" && (
          <ToolboxRow key="measure-distance">
            <ToolboxTitleStyled>Units</ToolboxTitleStyled>
            <ToolboxControl>
              <ToolboxSelect
                value={modeConfig?.turfOptions?.units}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  updateModeConfig({
                    turfOptions: { units: event.target.value },
                  })
                }
                options={["meters", "miles", "degrees", "radians"]}
              ></ToolboxSelect>
            </ToolboxControl>
          </ToolboxRow>
        )}
        {measureFeatures.features.length !== 0 && (
          <MeasureElements
            units={modeConfig?.turfOptions?.units}
            features={[...measureFeatures.features]}
            removeMeasureElements={removeMeasureElements}
          />
        )}
        <ToolboxRow>
          <ToolboxTitleStyled active={true}>Features</ToolboxTitleStyled>
          <>
            <ToolboxButton
              onClick={() => dispatch(setSelectedFeatureIndexes([]))}
            >
              Clear Selection
            </ToolboxButton>
            <Input onChange={onSearchAction} labelText="Search Feature" />
            <ToolboxRow>{buildSelectFeatureCheckboxes()}</ToolboxRow>
          </>
        </ToolboxRow>
      </Toolbox>
    );
  }
);
