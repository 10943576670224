import styled from "styled-components";

export const UserInfoStyled = styled.div`
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-right: 24px;
  position: relative;
`;

export const UserNameStyled = styled.div`
  display: block;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
  margin-right: 24px;
`;

export const UserImageStyled = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  margin-right: 24px;
`;
