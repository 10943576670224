import React from "react";

import { InputContainerStyled, InputLabelStyled, InputStyled } from "./styles";

interface InputProps {
  labelText?: string;
  onChange: (val: any) => void;
}

export const Input = ({ labelText = "", onChange }: InputProps) => {
  return (
    <InputContainerStyled>
      {labelText && <InputLabelStyled>{labelText}</InputLabelStyled>}
      <InputStyled onChange={onChange} />
    </InputContainerStyled>
  );
};
