import React, { memo } from "react";

import { TooltipStyled } from "./styles";

interface TooltipProps {
  tooltipText: string;
  x: number;
  y: number;
}

export const Tooltip: React.FC<TooltipProps> = memo(({ tooltipText, x, y }) => (
  <TooltipStyled x={x} y={y}>
    {tooltipText}
  </TooltipStyled>
));
