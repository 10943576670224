import React, { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Header } from "./components/Header";
import { Search } from "./components/Search";
import { Table } from "./components/Table";
import { sortByColumn } from "./utils";
import { MainPageInfo, SelectedTabEnum } from "./types";
import { OrderOptions, RowData } from "./components/Table/types";
import { useNavigate } from "react-router-dom";
import {
  toggleModal,
  POINT_CLOUD_MODAL,
  EDIT_PROJECT_MODAL,
  NEW_PROJECT_MODAL,
} from "../../store/slices/modals";
import {
  fetchDeletePointCloud,
  getPointClouds,
} from "../../store/slices/pointClouds";
import { fetchDeleteProject, getProjects } from "../../store/slices/projects";
import { fetchDownloadProjectById } from "../../store/slices/projects/actions";

import { MainPageWrapperStyled } from "./styles";

const mainPageInfo: MainPageInfo = {
  Projects: {
    buttonTitle: "Start a New Project",
    selectedTitle: "Recent Projects",
    searchPlaceholder: "Search Project Name",
    createModalName: NEW_PROJECT_MODAL,
  },
  PointClouds: {
    buttonTitle: "Upload Point Cloud",
    selectedTitle: "Recent Point Cloud",
    searchPlaceholder: "Search Point Cloud Name",
    createModalName: POINT_CLOUD_MODAL,
  },
};

const tableHeaderData = {
  name: "Name",
  description: "Description",
  created: "Created",
  updated: "Last Updated",
};

export const MainPage: React.FC = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projects: Array<Project> = useSelector(getProjects);
  const pointClouds: Array<PointCloud> = useSelector(getPointClouds);
  const [activeTab, setActiveTab] = useState<SelectedTabEnum>(
    SelectedTabEnum.Projects
  );
  const [orderOptions, setOrderOptions] = useState<OrderOptions>({
    columnName: "name",
    order: 1,
  });
  const [searchValue, setSearchValue] = useState<string>("");

  const options = useMemo(
    () =>
      activeTab === SelectedTabEnum.Projects
        ? [
            {
              name: "Open Editor",
              callback: (item: RowData) => {
                navigate(`/mapEditor/${item._id}`);
              },
            },
            {
              name: "Edit Details",
              callback: (item: RowData) => {
                dispatch(
                  toggleModal({
                    type: EDIT_PROJECT_MODAL,
                    data: {
                      _id: item._id,
                      name: item.name,
                      description: item.description,
                      latitude: item.latitude,
                      longitude: item.longitude,
                      pointCloudId: item.pointCloudId,
                    },
                  })
                );
              },
            },
            {
              name: "Download",
              callback: (item: RowData) => {
                dispatch(fetchDownloadProjectById(item._id));
              },
            },
            {
              name: "Delete Project",
              callback: (item: RowData) => {
                dispatch(
                  fetchDeleteProject({
                    _id: item._id,
                    name: item.name as string,
                  })
                );
              },
            },
          ]
        : [
            {
              name: "Edit Details",
              callback: (item: RowData) => {
                dispatch(
                  toggleModal({
                    type: POINT_CLOUD_MODAL,
                    data: {
                      _id: item._id,
                      name: item.name,
                      description: item.description,
                    },
                  })
                );
              },
            },
            {
              name: "Delete",
              callback: (item: RowData) => {
                dispatch(
                  fetchDeletePointCloud({
                    _id: item._id,
                    name: item.name as string,
                  })
                );
              },
            },
          ],
    [activeTab]
  );

  const selectedListData = useMemo(
    () => (activeTab === SelectedTabEnum.Projects ? projects : pointClouds),
    [projects, pointClouds, activeTab]
  );

  const preparedData = useMemo(() => {
    const sortedData = sortByColumn(
      [...selectedListData],
      orderOptions.columnName,
      orderOptions.order
    );
    if (searchValue)
      return sortedData.filter((row) =>
        row.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    return sortedData;
  }, [selectedListData, searchValue, orderOptions]) as unknown as RowData[];

  return (
    <MainPageWrapperStyled>
      <Header
        projectsCount={projects.length || 0}
        pointCloudsCount={pointClouds.length || 0}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        buttonTitle={mainPageInfo[activeTab].buttonTitle}
        createModalName={mainPageInfo[activeTab].createModalName}
      />
      <Search
        selectedTitle={mainPageInfo[activeTab].selectedTitle}
        searchPlaceholder={mainPageInfo[activeTab].searchPlaceholder}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <Table
        options={options}
        headerData={tableHeaderData}
        orderOptions={orderOptions}
        setOrderOptions={setOrderOptions}
        tableData={preparedData}
      />
    </MainPageWrapperStyled>
  );
});
