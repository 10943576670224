import React from "react";
import { ToolboxButton } from "../common/toolbox";
import { FeatureMenuStyled } from "./styles";

interface FeatureMenuProps {
  featureMenuClick: (action: string) => void;
  featureMenu?: { index: number; x: number; y: number };
}

const FeatureMenu = ({ featureMenu, featureMenuClick }: FeatureMenuProps) => {
  const { x = 0, y = 0 } = featureMenu || {};

  const buttons = [
    { action: "delete", label: "Delete" },
    { action: "info", label: "Info" },
    { action: "close", label: "Close" },
  ];

  return (
    <FeatureMenuStyled top={y - 200} left={x + 60}>
      {buttons.map(({ action, label }) => (
        <ToolboxButton key={action} onClick={() => featureMenuClick(action)}>
          {label}
        </ToolboxButton>
      ))}
    </FeatureMenuStyled>
  );
};

export default FeatureMenu;
