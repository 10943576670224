import styled from "styled-components";

export const PropLineStyled = styled.span`
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  align-items: center;
  flex-direction: row;
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;

  span {
    white-space: pre-wrap;
    word-break: break-word;

    span::after {
      content: ",";
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
`;

export const PropNameStyled = styled.span`
  display: flex;
  margin: 0 4px 0 0;
  font-weight: 600;
  text-wrap: nowrap;
`;
