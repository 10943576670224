import {
  fetchGetProjects,
  fetchPatchProject,
  fetchDeleteProject,
  fetchPostProject,
  fetchPatchMapGeoJson,
  fetchPatchMapPbBinFile,
} from "./actions";
import {
  getProjects,
  getIsProjectsLoaded,
  getIsProjectsLoadingError,
} from "./selectors";
import projectsSlice from "./reducers";

// actions
export const { resetProjects, addNewProject, deleteProject, updateProject } =
  projectsSlice.actions;

// async actions
export {
  fetchGetProjects,
  fetchPatchProject,
  fetchDeleteProject,
  fetchPostProject,
  fetchPatchMapGeoJson,
  fetchPatchMapPbBinFile,
};

// selectors
export { getProjects, getIsProjectsLoaded, getIsProjectsLoadingError };

// reducer
export default projectsSlice.reducer;
