import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API from "../../../api";
import { setIsLoading } from "../appState";
import {
  addUndoFeature,
  getCurrentMapId,
  getFeatures,
  getOutput,
  setContextMenuData,
  setCurrentMapData,
  setFeatures,
  setLatLong,
  setLockedFeatures,
  setUndoFeatures,
} from ".";
import { fetchPatchMapGeoJson } from "../projects";
import { RootState } from "../..";
import { parseGeoJson } from "../../../pages/MapEditor/utils";
import { setMapStructsData } from "../mapStructs";
import { FeaturesInterface } from "../../../models/map-interface";
import { CONFIRM_ACTION_MODAL, toggleModal } from "../modals";
import { GeoJsonUploadType } from "../../../pages/MapEditor/mapEditor";

export const fetchGetCurrentMapData = createAsyncThunk(
  "currentMap/fetchGetCurrentMapData",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const currentMapFeatures = getFeatures(state);

    try {
      const currentMapDataResponse = await API.get(
        `map-projects/${window.location.pathname.split("/")[2]}`
      );
      const currentMapData = currentMapDataResponse.data;
      const { _id, latitude, longitude, radius } = currentMapData;
      dispatch(
        setCurrentMapData({
          _id,
          latitude,
          longitude,
          radius,
        })
      );
      if (_id) {
        const geoJsonResponse = await API.get(
          `map-projects/${_id}/semantic_map.geojson`
        );
        const { mapFeatures, mapStructs, errors } = parseGeoJson(
          JSON.stringify(geoJsonResponse.data),
          currentMapFeatures
        );
        if (mapStructs) {
          dispatch(setMapStructsData({ ...mapStructs, errors }));
        }
        if (mapFeatures) {
          dispatch(addUndoFeature({ ...mapFeatures }));
          dispatch(setFeatures(mapFeatures));
        }
      }
      dispatch(setIsLoading(false));
      return currentMapData;
    } catch (error) {
      dispatch(setIsLoading(false));
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
      return Promise.reject();
    }
  }
);

export const importFromFileAction = createAsyncThunk(
  "currentMap/importFromFileAction",
  async (_, { getState, dispatch }) => {
    const state = getState() as {
      currentMap: CurrentMap & {
        mapFeatures: FeaturesInterface;
      };
    };
    const currentMapFeatures = { ...state.currentMap.mapFeatures };
    const { features } = currentMapFeatures;
    const el = document.createElement("input");
    el.type = "file";
    el.onchange = (e) => {
      const eventTarget = e.target as HTMLInputElement;
      if (eventTarget.files && eventTarget.files[0]) {
        const reader = new FileReader();
        reader.onload = ({ target }) => {
          const currentJson = target?.result as string;

          const confirmMergeAction = (type: GeoJsonUploadType) => {
            const { mapFeatures, mapStructs, errors } = parseGeoJson(
              currentJson,
              currentMapFeatures,
              type
            );
            if (mapStructs) {
              dispatch(setMapStructsData({ ...mapStructs, errors }));
            }
            if (mapFeatures) {
              dispatch(addUndoFeature({ ...mapFeatures }));
              dispatch(setFeatures(mapFeatures));
            }
          };
          if (features.length === 0) {
            const { mapFeatures, mapStructs, errors } = parseGeoJson(
              currentJson,
              currentMapFeatures
            );
            if (mapStructs) {
              dispatch(setMapStructsData({ ...mapStructs, errors }));
            }
            if (mapFeatures) {
              dispatch(addUndoFeature({ ...mapFeatures }));
              dispatch(setFeatures(mapFeatures));
              dispatch(setLatLong(mapFeatures.properties.latLngOrigin));
            }
          } else {
            dispatch(
              toggleModal({
                type: CONFIRM_ACTION_MODAL,
                data: {
                  text: "Would you like to merge with feature properties?",
                  confirmAction: () =>
                    confirmMergeAction(GeoJsonUploadType.MergeWithFeatures),
                  cancelAction: () =>
                    confirmMergeAction(GeoJsonUploadType.MergeWithNoFeatures),
                },
              })
            );
          }
        };
        reader.readAsText(eventTarget.files[0]);
      }
    };
    el.click();
  }
);

export const setLockedFeaturesAction = createAsyncThunk(
  "currentMap/setLockedFeaturesAction",
  async (featureId: number, { getState, dispatch }) => {
    const state = getState() as { currentMap: CurrentMap };
    const { lockedFeatures } = state.currentMap;
    let updatedLockedFeatures: number[];

    if (lockedFeatures.includes(featureId)) {
      updatedLockedFeatures = lockedFeatures.filter((id) => id !== featureId);
    } else {
      updatedLockedFeatures = [...lockedFeatures, featureId];
    }

    dispatch(setLockedFeatures(updatedLockedFeatures));
    dispatch(setContextMenuData(null));

    return updatedLockedFeatures;
  }
);

export const saveGeoJsonAction = createAsyncThunk(
  "currentMap/saveGeoJsonAction",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    const output = getOutput(state);
    const currentMapProjectId = getCurrentMapId(state);
    const mapFeatures = getFeatures(state);

    const file = new Blob([JSON.stringify(output)], { type: "octet/stream" });

    await dispatch(
      fetchPatchMapGeoJson({
        file,
        projectId: currentMapProjectId,
      })
    );
    dispatch(setUndoFeatures([mapFeatures]));

    return { success: true };
  }
);
