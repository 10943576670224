import { DropdownButton } from "react-bootstrap";
import styled from "styled-components";

export const ModalWrapStyled = styled.div`
  position: fixed;
  top: 0px;
  left: 368px;
  z-index: 9000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IntersectionsModalStyled = styled.div`
  width: 348px;
  min-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #172a3f;
  box-shadow: 0px 8px 32px rgba(27, 27, 27, 0.08);
  border-radius: 4px;
  color: #ffffff;
  line-height: 140%;
  box-sizing: border-box;
  padding: 16px;
  z-index: 1;
  margin-top: 80px;
  position: relative;

  svg {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
`;

export const HeaderStyled = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const IntersectionsModalContentStyled = styled.div`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
    cursor: pointer;
  }
`;

export const DropdownButtonStyled = styled(DropdownButton)`
  padding: 0;
`;

export const RowItemStyled = styled.div`
  width: 148px;
  height: 32px;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0;
  box-sizing: border-box;

  & > :first-child {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ActionButtonStyled = styled.div`
  width: 96px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  background-color: #3f7f7d;
  cursor: pointer;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;
