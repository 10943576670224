// the functionality is hidden until the implementation of the super admin role
import React, { memo } from "react";
import { useSelector } from "react-redux";

import { UserAvatarComponent } from "../../../common/UserAvatarComponent";
import { getCurrentUserName } from "../../../../store/slices/user";

import { UserInfoStyled, UserNameStyled, UserImageStyled } from "./styles";
export const UserInfo = memo(() => {
  const userName = useSelector(getCurrentUserName);

  return (
    <UserInfoStyled>
      <UserImageStyled>
        <UserAvatarComponent />
      </UserImageStyled>
      <UserNameStyled data-test-id="profile">{userName}</UserNameStyled>
    </UserInfoStyled>
  );
});
