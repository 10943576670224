//TODO change after backend part
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";

import API from "../../../api";
import { setIsLoading } from "../appState";
import { setUser } from ".";

export const fetchGetLogin = createAsyncThunk(
  "auth/fetchGetLogin",
  async (
    { email, password }: { email: string; password: string },
    { dispatch }
  ) => {
    try {
      const response = await API.post("/login/", {
        email,
        password,
      });
      if (response.data) {
        window.localStorage.setItem("JWT", `Bearer ${response.data.access}`);
        window.localStorage.setItem(
          "JWTrefresh",
          `Bearer ${response.data.refresh}`
        );
      }
      dispatch(setIsLoading(false));
      return response.data;
    } catch (error) {
      dispatch(setIsLoading(false));
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
    }
  }
);

export const fetchGetCurrentUser = createAsyncThunk(
  "currentUser/fetchGetCurrentUser",
  async (_, { dispatch }) => {
    try {
      const API_URL = window._envK8S_?.REACT_APP_API_URL;
      if (API_URL) {
        const response = await axios.get(
          `${API_URL.replace("com", "net").replace(
            "map-editor",
            "cloud"
          )}users/me/`,
          {
            headers: {
              Authorization: window.localStorage.getItem("JWT"),
            },
          }
        );
        dispatch(setUser(response.data));
      }
    } catch (error) {
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
    }
  }
);

// export const verifyMFA = createAsyncThunk(
//   "auth/verifyMFA",
//   async ({ id, token, code }: any, thunkAPI) => {
//     try {
//       const response = await API.post("/login", {
//         mfa: {
//           id,
//           token,
//           code,
//         },
//       });
//       thunkAPI.dispatch(setIsLoading(false));
//       return response.data;
//     } catch (error: any) {
//       let errorMessage = "An error occured with multi-factor authentication.";
//       try {
//         const message = error.response.data.detail.message;
//         if (message) {
//           errorMessage = message;
//         }
//       } catch (e) {
//         console.error("There was an error in the response message.");
//       }
//       thunkAPI.dispatch(setIsLoading(false));
//       return new Error(errorMessage);
//     }
//   }
// );
