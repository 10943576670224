import styled from "styled-components";

import { ReactComponent as ContextMenuIcon } from "../../assets/contextMenuIcon.svg";

export const ContextMenuStyled = styled.div`
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-right: 24px;
  position: relative;
`;

interface ContextMenuIconProps {
  active: boolean;
}

export const ContextMenuIconStyled = styled(
  ContextMenuIcon
)<ContextMenuIconProps>`
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  path {
    fill: ${(props) => (props.active ? "#55B8B3" : "rgba(255, 255, 255, 0.5)")};
    transition: all 0.3s;
  }
`;

export const DropdownContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  width: 200px;
  right: -24px;
  top: 71px;
  box-sizing: border-box;
  padding: 8px 0;
  z-index: 2;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
`;

export const DropdownItemStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #606060;
  text-decoration: none;
  box-sizing: border-box;
  padding-left: 18px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;

  path {
    transition: all 0.3s;
  }

  &:hover {
    color: #55b8b3;
    border-bottom: 1px solid #55b8b3;

    path {
      fill: #55b8b3;
    }
  }

  svg {
    margin-right: 8px;
  }
`;
