import {
  fetchGetCurrentMapData,
  setLockedFeaturesAction,
  saveGeoJsonAction,
} from "./actions";
import {
  getCurrentMap,
  getContextMenuData,
  getLockedFeatures,
  getMode,
  getModeConfig,
  getCurrentMapId,
  getSelectedFeatureIndexes,
  getDeleteSelectedItems,
  getCurrentProjectName,
  getUndoFeatures,
  getUndoStep,
  getFeatures,
  getLanesBySelectedFeatures,
  getFeatureIndexByIntersectionId,
  getCurrentMapLotLang,
  getOutput,
} from "./selectors";
import currentMapSlice from "./reducers";

// actions
export const {
  resetCurrentMap,
  setCurrentMapData,
  setGeojsonType,
  setLockedFeatures,
  setContextMenuData,
  setMode,
  setModeConfig,
  setModeWithConfig,
  setSelectedFeatureIndexes,
  setDeleteSelectedItems,
  setUndoFeatures,
  addUndoFeature,
  increaseUndoStep,
  decreaseUndoStep,
  setFeatures,
} = currentMapSlice.actions;

// async actions
export { fetchGetCurrentMapData, setLockedFeaturesAction, saveGeoJsonAction };

// selectors
export {
  getCurrentMap,
  getContextMenuData,
  getLockedFeatures,
  getMode,
  getModeConfig,
  getCurrentMapId,
  getSelectedFeatureIndexes,
  getDeleteSelectedItems,
  getCurrentProjectName,
  getUndoFeatures,
  getUndoStep,
  getFeatures,
  getLanesBySelectedFeatures,
  getFeatureIndexByIntersectionId,
  getCurrentMapLotLang,
  getOutput,
};

// reducer
export default currentMapSlice.reducer;

export interface FeatureInfo {
  line_type?: string;
  lane_association?: string | number;
  control_line_id?: string;
  stop_sign_association?: string;
  polygon_type?: string;
  stop_sign_id?: string | null;
  intersection_id?: number | string;
  control_line_association?: string | null;
}
