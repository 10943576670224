import styled from "styled-components";

import { ReactComponent as LayerIcon } from "../../assets/layerIcon.svg";

export const ContextMenuBodyStyled = styled.div<{ x: number; y: number }>`
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  background: #172a3f;
  z-index: 1000;
  width: 252px;
  box-shadow: 0px 4px 20px 0px rgba(27, 27, 27, 0.08);
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const ContextMenuItemStyled = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding-left: 24px;
  position: relative;
  transition: all 0.3s;
  color: rgba(255, 255, 255, 0.6);
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};

  div {
    opacity: ${(props) => (props.isDisabled ? "0.6" : "1")};
  }

  ${(props) =>
    !props.isDisabled &&
    `
    &:hover {
      color: #08192d;
      background-color: #55b8b3;

      path {
        fill: #08192d;
      }
    }
  `}
`;

export const ContextMenuItemContentStyled = styled.div`
  box-sizing: border-box;
  padding: 14px 16px 14px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ContextMenuOptionsStyled = styled.div`
  width: 220px;
  position: absolute;
  top: 0;
  left: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContextMenuOptionsItemStyled = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 14px 16px;
  cursor: pointer;
  background-color: #172a3f;
  transition: all 0.3s;
  gap: 8px;
  color: rgba(255, 255, 255, 0.6);

  svg {
    opacity: 0.4;
  }

  &:hover {
    color: #fff;
    background-color: #08192d;

    svg {
      opacity: 1;
    }
  }
`;

export const LayerIconStyled = styled(LayerIcon)<{ isVisible: boolean }>`
  path {
    fill: ${(props) => (props.isVisible ? "#767676" : "none")} !important;
  }
`;
