import yaml from "js-yaml";
import fs from "fs";

const LOCATION_ID = window._envK8S_?.location_id || "";
const location_id_specific_config_file_path =
  "/home/cyngn/dev/cyngn_map/" + LOCATION_ID + "/metadata.yaml";

let lat_long_origin_lat = 12.3;
let lat_long_origin_long = -45.6;

try {
  const fileContents = fs.readFileSync(location_id_specific_config_file_path);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = yaml.safeLoad(fileContents);
  lat_long_origin_lat = data.origin.position.lat_deg;
  lat_long_origin_long = data.origin.position.lon_deg;
} catch (e) {
  console.log(
    "unable to fetch map origin lat long with location_id: " + LOCATION_ID
  );
}
export const default_point_cloud_origin_lat = lat_long_origin_lat;
export const default_point_cloud_origin_long = lat_long_origin_long;

export const EMPTY_MAP_STRUCTS = {
  lanes: new Map(),
  stop_signs: new Map(),
  intersections: new Map(),
  control_lines: new Map(),
};

export const getEmptyFeatureCollection = (lat: any, long: any) => {
  return {
    type: "FeatureCollection",
    features: [],
    properties: {
      useLocalCoord: true,
      latLngOrigin: {
        latitude: lat,
        longitude: long,
      },
    },
  };
};

export const EMPTY_FEATURE_COLLECTION = {
  type: "FeatureCollection",
  features: [],
  properties: {
    useLocalCoord: true,
    latLngOrigin: {
      latitude: default_point_cloud_origin_lat,
      longitude: default_point_cloud_origin_long,
    },
  },
};

export const LINE_TYPE_STRING_NAME = "line_type";
export const LANE_START_LINE_STRING_NAME = "lane_start_line";
export const LANE_TERMINATION_LINE_STRING_NAME = "lane_termination_line";
export const LANE_LEFT_BOUNDARY_LINE_STRING_NAME = "lane_left_boundary_line";
export const LANE_RIGHT_BOUNDARY_LINE_STRING_NAME = "lane_right_boundary_line";
export const STOP_SIGN_CONTROL_LINE_STRING_NAME = "stop_sign_control_line";

export const POLYGON_TYPE_STRING_NAME = "polygon_type";
export const STOP_SIGN_STRING_NAME = "stop_sign";
export const INTERSECTION_STRING_NAME = "intersection";

export const LANE_ASSOCIATION_STRING_NAME = "lane_association";
export const CONTROL_LINE_ID_STRING_NAME = "control_line_id";
export const STOP_SIGN_ASSOCIATION_STRING_NAME = "stop_sign_association";

export const STOP_SIGN_ID_STRING_NAME = "stop_sign_id";
export const CONTROL_LINE_ASSOCIATION_STRING_NAME = "control_line_association";

export const FEATURE_ID_STRING_NAME = "feature_id";

export const INTERSECTION_ID_STRING_NAME = "intersection_id";

export const NULL_STRING_NAME = "NULL";
export const NEW_STRING_NAME = "NEW";

export const INVALID_ID_NUMBER = -1;
export const SMALLEST_UNIQUE_ID = 1000000;

export const VERIFY_FIELDS = new Map([
  [LANE_START_LINE_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [LANE_TERMINATION_LINE_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [LANE_LEFT_BOUNDARY_LINE_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [LANE_RIGHT_BOUNDARY_LINE_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [INTERSECTION_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [
    STOP_SIGN_STRING_NAME,
    [LANE_ASSOCIATION_STRING_NAME, CONTROL_LINE_ASSOCIATION_STRING_NAME],
  ],
  [
    STOP_SIGN_CONTROL_LINE_STRING_NAME,
    [LANE_ASSOCIATION_STRING_NAME, STOP_SIGN_ASSOCIATION_STRING_NAME],
  ],
]);

//DEFAULT FONT SIZE FOR TOOLTIPS FROM @nebula.gl/layers
export const PROJECTED_PIXEL_SIZE_MULTIPLIER = 2 / 3;
export const TOOLTIP_FONT_SIZE = PROJECTED_PIXEL_SIZE_MULTIPLIER * 32;
export const TOOLTIP_FONT_COLOR = [255, 255, 255];

export const EDIT_PLANE_CONFIG = {
  renderEditPlane: false,
  elevation: 0,
  radius: 0.0005,
  color: [99, 203, 224, 150],
};
