import React, { FC, memo, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ListGroup } from "react-bootstrap";

import { Intersection } from "../../../map-interface.d";
import { INTERSECTIONS_MODAL, toggleModal } from "../../../store/slices/modals";
import { removeIntersection } from "../../../store/slices/mapStructs";
import {
  RemoveButtonStyled,
  ButtonsContainerStyled,
  CardContainerStyled,
  CardHeaderStyled,
  MenuBtnStyled,
  CardBodyStyled,
  EditButtonStyled,
} from "../styles";

import { PropNameStyled, PropLineStyled } from "./styles";

type IntersectionsCardProps = {
  intersection: Intersection;
  setActiveElement: (intersectionId: number) => void;
  isOpened: boolean;
  selectedLaneIds: number[];
  onLaneFocus: (laneIds: number[]) => void;
};

export const IntersectionsCard: FC<IntersectionsCardProps> = memo(
  ({ intersection, isOpened, setActiveElement }) => {
    const dispatch = useDispatch();
    const { intersection_id } = intersection;

    const propsList = useMemo(() => {
      return (Object.keys(intersection) as Array<keyof Intersection>).map(
        (prop) => (
          <PropLineStyled key={prop} style={{ textTransform: "capitalize" }}>
            <PropNameStyled>{prop.replace(/_/g, " ")}:</PropNameStyled>
            <span>{`${intersection[prop]}`}</span>
          </PropLineStyled>
        )
      );
    }, [intersection]);

    const handleEdit = useCallback(() => {
      dispatch(
        toggleModal({ type: INTERSECTIONS_MODAL, data: intersection_id })
      );
    }, [dispatch, intersection_id]);

    const handleRemove = useCallback(() => {
      dispatch(removeIntersection(intersection_id));
    }, [intersection_id]);

    return (
      <CardContainerStyled>
        <CardHeaderStyled>
          <MenuBtnStyled onClick={() => setActiveElement(intersection_id)}>
            {intersection_id}
          </MenuBtnStyled>
        </CardHeaderStyled>
        {isOpened && (
          <CardBodyStyled>
            <ListGroup as="ul">
              {propsList}
              <ButtonsContainerStyled>
                <EditButtonStyled onClick={handleEdit}>Edit</EditButtonStyled>
                <RemoveButtonStyled onClick={handleRemove}>
                  Remove
                </RemoveButtonStyled>
              </ButtonsContainerStyled>
            </ListGroup>
          </CardBodyStyled>
        )}
      </CardContainerStyled>
    );
  }
);
