//TODO change after backend part
import { fetchGetLogin, fetchGetCurrentUser } from "./actions";
import {
  getIsCurrentUserLoaded,
  getCurrentUserImage,
  getCurrentUserName,
} from "./selectors";
import userSlice from "./reducers";

// actions
export const { resetUser, setUser } = userSlice.actions;

// async actions
export { fetchGetLogin, fetchGetCurrentUser };

// selectors
export { getIsCurrentUserLoaded, getCurrentUserImage, getCurrentUserName };

// reducer
export default userSlice.reducer;
