import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { getProjects } from "../projects";
import { FeaturesInterface } from "../../../models/map-interface";
import { getOutputMapStructs } from "../mapStructs";
import { FeatureInfo } from ".";

export const getCurrentMap = (state: RootState): CurrentMap => state.currentMap;
export const getCurrentMapLotLang = (
  state: RootState
): { latitude: number; longitude: number } => ({
  latitude: state.currentMap.latitude,
  longitude: state.currentMap.longitude,
});
export const getCurrentMapId = (state: RootState): string =>
  state.currentMap._id;
export const getLockedFeatures = (state: RootState): Array<number> =>
  state.currentMap.lockedFeatures;
export const getContextMenuData = (
  state: RootState
): ContextMenuDataType | null => state.currentMap.contextMenuData;
export const getMode = (state: RootState): string => state.currentMap.mode;
export const getSelectedFeatureIndexes = (state: RootState): Array<number> =>
  state.currentMap.selectedFeatureIndexes;
export const getModeConfig = (state: RootState): any =>
  state.currentMap.modeConfig;
export const getDeleteSelectedItems = (state: RootState): boolean =>
  state.currentMap.deleteSelectedItems;
export const getCurrentProjectName = createSelector(
  [getCurrentMapId, getProjects],
  (currentMapId, projects) => {
    const currentProject = projects.find(
      (project) => project._id === currentMapId
    );
    return currentProject ? currentProject.name : null;
  }
);
export const getUndoFeatures = (state: RootState): FeaturesInterface[] =>
  state.currentMap.undoFeatures;
export const getUndoStep = (state: RootState): number | null =>
  state.currentMap.undoStep;
export const getFeatures = (state: RootState): FeaturesInterface =>
  state.currentMap.mapFeatures;
export const getLanesBySelectedFeatures = createSelector(
  (state: RootState) => state.currentMap.mapFeatures.features,
  (state: RootState) => state.currentMap.selectedFeatureIndexes,
  (features, selectedFeatureIndexes) => {
    const resultLaneIds: number[] = [];
    const requiredLineTypes = [
      "lane_start_line",
      "lane_termination_line",
      "lane_right_boundary_line",
      "lane_left_boundary_line",
    ];

    const laneAssociationMap = new Map<string | number, Set<string>>();

    selectedFeatureIndexes.forEach((index) => {
      const feature = features[index];
      if (
        feature &&
        feature.properties &&
        feature.properties.feature_info_list
      ) {
        feature.properties.feature_info_list.forEach((info: FeatureInfo) => {
          if (info.line_type && info.lane_association) {
            if (!laneAssociationMap.has(info.lane_association)) {
              laneAssociationMap.set(info.lane_association, new Set());
            }
            laneAssociationMap.get(info.lane_association)!.add(info.line_type);
          }
        });
      }
    });

    laneAssociationMap.forEach((lineTypesSet, laneAssociation) => {
      const hasAllRequiredLineTypes = requiredLineTypes.every((lineType) =>
        lineTypesSet.has(lineType)
      );

      if (hasAllRequiredLineTypes) {
        resultLaneIds.push(Number(laneAssociation));
      }
    });

    return resultLaneIds;
  }
);
export const getFeatureIndexByIntersectionId = (intersectionId: number) =>
  createSelector(
    (state: RootState) => state.currentMap.mapFeatures.features,
    (features) => {
      return features.findIndex((feature) =>
        feature.properties?.feature_info_list?.some(
          (info: FeatureInfo) =>
            info.intersection_id !== undefined &&
            info.intersection_id === intersectionId
        )
      );
    }
  );

export const getOutput = createSelector(
  [getFeatures, getOutputMapStructs, getCurrentMapLotLang],
  (mapFeatures, outputMapStructs, { latitude, longitude }) => ({
    type: "FeatureCollection",
    properties: {
      latLngOrigin: { latitude, longitude },
      useLocalCoord: false,
    },
    features: mapFeatures.features,
    map_structs: outputMapStructs,
  })
);
