import {
  ModifyMode,
  TranslateMode,
  TransformMode,
  DuplicateMode,
  ExtendLineStringMode,
  DrawLineStringMode,
  DrawPointMode,
  DrawPolygonMode,
  DrawRectangleMode,
  MeasureDistanceMode,
  MeasureAreaMode,
  MeasureAngleMode,
  SnappableMode,
  ElevationMode,
  GeoJsonEditMode,
} from "@nebula.gl/edit-modes";

import { DrawLinestringByDraggingMode } from "../../utils/draw-linestring-by-dragging-mode";
import { DrawRefinedLineStringMode } from "../../utils/draw-refined-line-string-mode";
import { DrawCurvedLineStringMode } from "../../utils/draw-curved-line-string-mode";
import { AlterCurvedEdgeMode } from "../../utils/alter-curved-edge-mode";
import { TangentLineConstraintMode } from "../../utils/tangent-line-constraint-mode";
import { RotateMode } from "./customMode/RotateMode";

export const modeMap: { [key: string]: any } = {
  ModifyMode: ModifyMode,
  TranslateMode: TranslateMode,
  TransformMode: TransformMode,
  DuplicateMode: DuplicateMode,
  ExtendLineStringMode: ExtendLineStringMode,
  DrawLineStringMode: DrawLineStringMode,
  DrawPointMode: DrawPointMode,
  DrawPolygonMode: DrawPolygonMode,
  DrawRectangleMode: DrawRectangleMode,
  MeasureDistanceMode: MeasureDistanceMode,
  MeasureAreaMode: MeasureAreaMode,
  MeasureAngleMode: MeasureAngleMode,
  SnappableMode: SnappableMode,
  ElevationMode: ElevationMode,
  GeoJsonEditMode: GeoJsonEditMode,
  DrawLinestringByDraggingMode: DrawLinestringByDraggingMode,
  DrawRefinedLineStringMode: DrawRefinedLineStringMode,
  DrawCurvedLineStringMode: DrawCurvedLineStringMode,
  AlterCurvedEdgeMode: AlterCurvedEdgeMode,
  TangentLineConstraintMode: TangentLineConstraintMode,
  RotateMode: RotateMode,
};
export const HANDLE_WHEEL_DELTA = 0.000002;
export const ZOOM_DELTA = 0.01;
export const STOP_SIGN_ICON_WIDTH = 24;
export const STOP_SIGN_ICON_HEIGHT = 24;
export const STOP_SIGN_OFFSET_X = 0.000000705;
export const STOP_SIGN_OFFSET_Y = -0.0000012;
export const STOP_SIGN_BASE_SIZE = 0.00000002;
export const STOP_SIGN_SCALE_FACTOR_MULTIPLIER = 90000000;
export const STOP_SIGN = "stop_sign";
export const TRANSPARENT_COLOR: RGBAColor = [0, 0, 0, 0];
