import styled from "styled-components";

export const HeaderStyled = styled.div`
  height: 72px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: #172a3f;
  color: #bbbbbb;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  user-select: none;
  z-index: 1300;
  position: fixed;
  top: 0;
`;

export const LogoContainerStyled = styled.div`
  height: 100%;
  display: flex;
  width: 212px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;

export const ContentContainerStyled = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
`;

export const BackToProjectsButtonStyled = styled.div`
  display: flex;
  padding: 14px 24px;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  transition: opacity 0.3s;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-bottom: 4px;
  }
`;
