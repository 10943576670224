import { FeaturesInterface, MapStructs } from "../../models/map-interface";

export enum GeoJsonType {
  Semantic = "semantic",
  Centerline = "centerline",
}

export enum GeoJsonUploadType {
  MergeWithFeatures = "mergeWithFeatures",
  MergeWithNoFeatures = "MergeWithNoFeatures",
}

export interface MapEditorProps {
  currentMapData: any;
  isLoading: boolean;
  saveSemanticMapFlag: any;
  mapStructs: MapStructs;
  saveMapGeoJsonAction: any;
  getCurrentMapDataAction: any;
  setGeojsonTypeAction: any;
  toggleModalAction: any;
  setMapStructsAction: any;
  setLaneAction: any;
  removeIntersectionAction: any;
  dispatch: any;
  lockedFeatures: Array<number>;
  selectedFeatureIndexes: Array<number>;
  setSelectedFeatureIndexesAction: (indexes: Array<number>) => void;
  mode: string;
  modeConfig: any;
  contextMenuData: ContextMenuDataType | null;
  setModeAction: (mode: string) => void;
  setModeConfigAction: (modeConfig: any) => void;
  resetCurrentMapAction: () => void;
  setContextMenuDataAction: (
    contextMenuData: ContextMenuDataType | null
  ) => void;
  setModeWithConfigAction: (data: { mode: string; modeConfig: any }) => void;
  deleteSelectedItems: boolean;
  setDeleteSelectedItemsAction: (value: boolean) => void;
  undoFeatures: FeaturesInterface[];
  undoStep: number | null;
  addUndoFeatureAction: (value: FeaturesInterface) => void;
  setFeaturesAction: (value: FeaturesInterface) => void;
  mapFeatures: FeaturesInterface;
  selectedLaneIds: number[];
}

export interface MapEditorState {
  viewport: Record<string, any>;
  measureFeatures: FeaturesInterface;
  pointsRemovable: boolean;
  selectedLaneId: number;
  editContext?: any;
  editHandleType: string;
  showGeoJson: boolean;
  showDialog: boolean;
  featureMenu?: {
    index: number;
    x: number;
    y: number;
  };
  editPlane: {
    renderEditPlane: boolean;
    elevation: number;
    radius: number;
    color: number[];
  };
  radiusDialog: boolean;
  pointCloudJson: any;
  layerVisibility: any;
  layers: any;
  editableGeoJsonLayer: any;
  editableGeoJsonLayerVisible: boolean;
  editPlaneLayerVisible: boolean;
  pointCloudData: any;
  isLoading: boolean;
  geojsonType: GeoJsonType | null;
  showGeoJsonTypeModal: boolean;
  geoJsonDataIsLoaded: boolean;
  bufferFeature: number | null;
  highlightedFeatureIndexes: Array<number> | null;
  tooltipData: { x: number; y: number; tooltipText: string } | null;
}
