import React, { useMemo } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { Trash } from "react-bootstrap-icons";

import {
  FeatureInfo,
  FeaturePropertiesPanel,
} from "./components/FeaturePropertiesPanel";
import * as MapConst from "../../constants/map-constants";
import { generateUUID } from "../../utils";
import { getHtmlColorForFeature } from "../../utils/toolbox";
import {
  getFeatures,
  getLockedFeatures,
  getSelectedFeatureIndexes,
  setFeatures,
} from "../../store/slices/currentMap";

import { FeatureItemProps } from "./index.d";
import {
  ModalStyled,
  ToolboxCheckboxSpanStyled,
  ToolboxCheckboxStyled,
  ToolboxRowStyled,
} from "./styles";

const FeatureItem: React.FC<FeatureItemProps> = ({
  featureType,
  index,
  isModal,
  featureMenuClick,
  updateSelectedFeatureIndexes,
  deleteFeatureProperty,
}) => {
  const dispatch = useDispatch();
  const mapFeatures = useSelector(getFeatures);
  const selectedFeatureIndexes = useSelector(getSelectedFeatureIndexes);
  const lockedFeatures = useSelector(getLockedFeatures);

  const isLocked = useMemo(
    () => lockedFeatures.includes(index),
    [lockedFeatures, index]
  );
  const feature = mapFeatures.features[index];
  const featureId = feature?.properties?.feature_id || "";

  if (
    feature?.geometry?.type === "Polygon" &&
    feature?.properties?.feature_info_list?.some(
      (info: FeatureInfo) => info.polygon_type === "intersection"
    )
  ) {
    return null;
  }

  const handleAddFeatureProperty = (
    e: React.MouseEvent<HTMLButtonElement>,
    featureIndex: number
  ) => {
    const feature = mapFeatures.features[featureIndex];
    const tempFeatureInfoList = feature.properties?.feature_info_list || [];
    const featureId = feature.properties?.feature_id || generateUUID();

    const newFeatureInfo: FeatureInfo = {};

    if (featureType === "LineString") {
      newFeatureInfo[MapConst.LINE_TYPE_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      newFeatureInfo[MapConst.LANE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      newFeatureInfo[MapConst.CONTROL_LINE_ID_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      newFeatureInfo[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
    } else if (featureType === "Polygon") {
      newFeatureInfo[MapConst.POLYGON_TYPE_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      newFeatureInfo[MapConst.LANE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      newFeatureInfo[MapConst.STOP_SIGN_ID_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      newFeatureInfo[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
    }

    tempFeatureInfoList.push(newFeatureInfo);

    const updatedFeatures = mapFeatures.features.map((f, i) =>
      i === featureIndex
        ? {
            ...f,
            properties: {
              feature_id: featureId,
              feature_info_list: tempFeatureInfoList,
            },
          }
        : f
    );

    dispatch(
      setFeatures({
        ...mapFeatures,
        features: updatedFeatures,
      })
    );
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    action: string,
    index: number
  ) => {
    e.preventDefault();
    featureMenuClick(action, index);
  };

  return (
    <div key={index}>
      <ToolboxRowStyled>
        <ToolboxCheckboxStyled
          isHide={isModal}
          type="checkbox"
          checked={selectedFeatureIndexes.includes(index)}
          disabled={!!isModal}
          onChange={() => {
            const updatedIndexes = selectedFeatureIndexes.includes(index)
              ? selectedFeatureIndexes.filter((e) => e !== index)
              : [...selectedFeatureIndexes, index];
            updateSelectedFeatureIndexes(updatedIndexes);
          }}
        >
          <ToolboxCheckboxSpanStyled
            index={index}
            isSelected={selectedFeatureIndexes.includes(index)}
          >
            {featureId}: {featureType}
            {isLocked && " (Locked)"}
          </ToolboxCheckboxSpanStyled>
        </ToolboxCheckboxStyled>
        {isModal ? (
          <ModalStyled>
            <Button
              variant="danger"
              onClick={(e) => handleClick(e, "delete", index)}
            >
              <Trash size={16} title="Delete" />
            </Button>
          </ModalStyled>
        ) : (
          <Button
            style={{
              position: "absolute",
              right: "12px",
              textDecoration: "none",
              color: getHtmlColorForFeature(
                index,
                selectedFeatureIndexes.includes(index)
              ),
            }}
            variant="link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateSelectedFeatureIndexes([index], {
                index,
                x: e.clientX,
                y: e.clientY,
              });
            }}
          >
            &gt;&gt;
          </Button>
        )}
      </ToolboxRowStyled>

      {selectedFeatureIndexes.includes(index) && (
        <FeaturePropertiesPanel
          deleteFeatureProperty={deleteFeatureProperty}
          handleAddFeatureProperty={handleAddFeatureProperty}
          featureIndex={index}
        />
      )}
    </div>
  );
};

export default FeatureItem;
