import { FC, memo } from "react";
import * as MapConst from "../../../../constants/map-constants";
import { Col } from "react-bootstrap";
import { DropdownButtonStyled, RowStyled } from "../../styles";
import { ItemWithContainerValues } from "../../../common/ItemWithContainerValues";
import * as React from "react";
import update from "immutability-helper";
import { getFeatures, setFeatures } from "../../../../store/slices/currentMap";
import { useDispatch, useSelector } from "react-redux";
import { getMapStructs } from "../../../../store/slices/mapStructs";

type StopSignAssocDropdownProps = {
  feature_index: any;
  feature_info_index: any;
  feature_info: any;
  setControlLineAction: any;
};

export const StopSignAssocDropdown: FC<StopSignAssocDropdownProps> = memo(
  ({
    feature_index,
    feature_info_index,
    feature_info,
    setControlLineAction,
  }) => {
    const dispatch = useDispatch();
    const mapFeatures = useSelector(getFeatures);
    const mapStructs = useSelector(getMapStructs);
    const { features } = mapFeatures;
    const getAvailableStopSigns = (curr_value: string) => {
      const { controlLines, stopSigns } = mapStructs;
      const { lane_association } = feature_info;

      if (lane_association === "NULL") return [];

      const filteredStopSigns = new Map();
      Object.values(stopSigns).forEach((stopSign: any) => {
        if (Number(stopSign.associated_lane_id) === Number(lane_association)) {
          filteredStopSigns.set(stopSign.stop_sign_id, stopSign);
        }
      });

      const availableStopSignIdsSet = new Set(filteredStopSigns.keys());

      Object.values(controlLines).forEach((controlLine: any) => {
        const temp_stop_sign_id = controlLine.associated_stop_sign_id;
        availableStopSignIdsSet.delete(temp_stop_sign_id);
      });

      const available_stop_sign_ids = Array.from(availableStopSignIdsSet);
      if (curr_value !== MapConst.NULL_STRING_NAME) {
        available_stop_sign_ids.unshift(MapConst.NULL_STRING_NAME);
      }
      return available_stop_sign_ids;
    };

    const available_stop_sign_ids = getAvailableStopSigns(
      feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME]
    );

    const handleChangeInStopSignAssociationValue = (
      feature_index: number,
      feature_info_index: number,
      e: any
    ) => {
      const _updateStopSignAssocOfControlLine = (
        affectedControlLineId: string | number,
        newStopSignAssociation: string | number
      ) => {
        if (affectedControlLineId === MapConst.NULL_STRING_NAME) {
          return;
        }
        const { controlLines } = mapStructs;
        newStopSignAssociation = isNaN(Number(newStopSignAssociation))
          ? MapConst.INVALID_ID_NUMBER
          : newStopSignAssociation;
        const affectedControlLine = {
          ...controlLines[Number(affectedControlLineId)],
        };

        affectedControlLine["associated_stop_sign_id"] = Number(
          newStopSignAssociation
        );
        setControlLineAction(affectedControlLine);
      };

      const temp_feature_info = features[feature_index].properties
        ? features[feature_index].properties.feature_info_list[
            feature_info_index
          ]
        : {};
      if (temp_feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] === e) {
        return;
      }

      temp_feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] = e;

      // update stop sign association for this control line in mapStruct
      _updateStopSignAssocOfControlLine(
        temp_feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME],
        Number(e)
      );

      const resultState = update(mapFeatures, {
        features: {
          [feature_index]: {
            properties: {
              feature_info_list: {
                [feature_info_index]: { $set: temp_feature_info },
              },
            },
          },
        },
      });
      dispatch(setFeatures(resultState));
    };
    return (
      <RowStyled
        key={
          feature_index +
          "_" +
          feature_info_index +
          "_" +
          MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME
        }
      >
        <Col>
          <b>STOP SIGN ASSOC</b>
        </Col>
        <Col>
          <DropdownButtonStyled
            title={
              feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] || ""
            }
            id="dropdown-menu-align-right"
            size="sm"
            onSelect={(event) =>
              handleChangeInStopSignAssociationValue(
                feature_index,
                feature_info_index,
                event
              )
            }
          >
            <ItemWithContainerValues values={available_stop_sign_ids} />
          </DropdownButtonStyled>
        </Col>
      </RowStyled>
    );
  }
);
