import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAnyModal, getModalData } from "../../../store/slices/modals";

import { ConfirmActionModalCommon } from "./ConfirmActionModalCommon";

interface ConfirmActionData {
  text: string;
  confirmAction: () => void;
  cancelAction?: () => void;
  cancelButtonText?: string;
  submitButtonText?: string;
}

export const ConfirmActionModal = memo(() => {
  const dispatch = useDispatch();
  const data = useSelector(getModalData);
  const {
    text,
    confirmAction,
    cancelAction,
    cancelButtonText,
    submitButtonText,
  } = data as unknown as ConfirmActionData;

  const handleCloseModal = useCallback(() => {
    if (cancelAction) {
      cancelAction();
    }
    dispatch(closeAnyModal());
  }, []);

  const currentConfirmAction = () => {
    confirmAction();
    dispatch(closeAnyModal());
  };

  return (
    <ConfirmActionModalCommon
      cancelButtonText={cancelButtonText}
      submitButtonText={submitButtonText}
      handleClose={handleCloseModal}
      handleConfirm={currentConfirmAction}
      modalMessage={text}
    />
  );
});
