import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getCurrentMapId,
  getCurrentProjectName,
  getUndoFeatures,
  saveGeoJsonAction,
} from "../../store/slices/currentMap";

import { ReactComponent as LogoSvg } from "./assets/logo.svg";
import { ReactComponent as BackIcon } from "./assets/backIcon.svg";
import { UserInfo } from "./components/UserInfo";
import { EditorToolbar } from "./components/EditorToolbar";
import ContextMenu from "./components/ContextMenu";
import { CurrentMapInfo } from "./components/CurrentMapInfo";
import {
  HeaderStyled,
  LogoContainerStyled,
  ContentContainerStyled,
  BackToProjectsButtonStyled,
} from "./styles";
import { CONFIRM_ACTION_MODAL, toggleModal } from "../../store/slices/modals";

const Header = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentProjectName = useSelector(getCurrentProjectName);
  const currentMapId = useSelector(getCurrentMapId);
  const undoFeatures = useSelector(getUndoFeatures);

  const backToProjects = useCallback(() => {
    if (undoFeatures.length !== 1) {
      dispatch(
        toggleModal({
          type: CONFIRM_ACTION_MODAL,
          data: {
            text: `You are about to leave ${currentProjectName} with unsaved changes. Would you like to keep your work before leaving the project`,
            confirmAction: () => {
              dispatch(saveGeoJsonAction());
              navigate("/");
            },
            cancelAction: () => navigate("/"),
            submitButtonText: "Save",
            cancelButtonText: "Discard",
          },
        })
      );
    } else {
      navigate("/");
    }
  }, [currentMapId, undoFeatures]);

  return (
    <HeaderStyled>
      <ContentContainerStyled>
        <LogoContainerStyled data-test-id="home">
          <LogoSvg />
        </LogoContainerStyled>
        {currentMapId && (
          <>
            <BackToProjectsButtonStyled onClick={backToProjects}>
              <BackIcon />
              Back to Projects
            </BackToProjectsButtonStyled>
            <EditorToolbar />
          </>
        )}
      </ContentContainerStyled>
      {currentMapId && (
        <ContentContainerStyled>
          <CurrentMapInfo />
        </ContentContainerStyled>
      )}
      <ContentContainerStyled>
        <UserInfo />
        <ContextMenu />
      </ContentContainerStyled>
    </HeaderStyled>
  );
});

export default Header;
