import { LineString } from "@nebula.gl/edit-modes";
import { cloneDeep } from "lodash";
import { PointCloudLayer } from "@deck.gl/layers";

import { COLOR_COUNT, SNAP_MIN_DISTANCE } from "../../constants/map-constants";
import { initialViewport, rainbow } from "../../constants/nebula";
import {
  EDIT_PLANE_CONFIG,
  getEmptyFeatureCollection,
  INVALID_ID_NUMBER,
  LINE_TYPE_STRING_NAME,
  NULL_STRING_NAME,
  POLYGON_TYPE_STRING_NAME,
  STOP_SIGN_STRING_NAME,
} from "../../map-constants.d";
import {
  FeaturesInterface,
  GeoJSONInterface,
  MapStructs,
} from "../../models/map-interface";
import {
  DataError,
  getFilteredFeaturesProps,
  getMapStructsFromFeatures,
} from "../../utils/data";
import { generateUUID, hex2rgb, reviver } from "../../utils";

import { GeoJsonUploadType, MapEditorState } from "./mapEditor";
import { COORDINATE_SYSTEM } from "@deck.gl/core/typed";

export const createPointCloudLayers = (
  pointCloudData: { COORDINATES: number[] }[],
  currentMapData: CurrentMap,
  layerVisibility: any
) => {
  const groupedPoints = groupPointsByColor(pointCloudData);

  const pointCloudLayers: any[] = [];

  Object.entries(groupedPoints).forEach(([color, points], index) => {
    const rgbColor = hex2rgb(color).map((c) => c * 255);
    const layerId = `point_cloud_layer_${index}`;

    if (points && (points as Array<number>).length !== 0) {
      pointCloudLayers.push(
        new PointCloudLayer({
          id: layerId,
          data: points,
          coordinateSystem: COORDINATE_SYSTEM.LNGLAT_OFFSETS,
          coordinateOrigin: [
            currentMapData?.longitude,
            currentMapData?.latitude,
          ],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getPosition: (d: unknown) => d.COORDINATES as Position,
          pointSize: 2,
          opacity: 1.0,
          getColor: () => rgbColor as RGBAColor,
          visible: layerVisibility[layerId] || false,
        })
      );
    }
  });

  return pointCloudLayers;
};

export const initializeLayerVisibility = (
  pointCloudData: any,
  currentMapData: CurrentMap,
  layerVisibility: any
) => {
  if (!pointCloudData) {
    return {}; // Return an empty object if pointCloudData is undefined
  }

  const pointCloudLayers = createPointCloudLayers(
    pointCloudData,
    currentMapData,
    layerVisibility
  );
  const initialLayerVisibility = pointCloudLayers?.reduce(
    (visibilityMap, layer) => {
      (visibilityMap as any)[layer?.id] = true; // Set the initial visibility for each layer to true
      return visibilityMap;
    },
    {}
  );

  return initialLayerVisibility;
};

export const parseGeoJson = (
  json: string,
  mapFeatures: FeaturesInterface,
  geoJsonUploadType?: GeoJsonUploadType
): {
  mapStructs?: MapStructs;
  mapFeatures: FeaturesInterface;
  errors?: DataError[];
} => {
  const input: GeoJSONInterface = JSON.parse(json, reviver);
  const currentMapFeatures = cloneDeep(mapFeatures);
  const { features, properties, type } = currentMapFeatures;
  let currentTestFeatures = { ...currentMapFeatures };

  if (geoJsonUploadType === GeoJsonUploadType.MergeWithNoFeatures) {
    const newFeatures = input.features.map(({ geometry, type }) => ({
      type,
      geometry,
      properties: {
        feature_id: generateUUID(),
        feature_info_list: [],
      },
    }));
    currentTestFeatures = {
      features: [...features, ...newFeatures],
      properties,
      type,
    };

    return {
      mapFeatures: currentTestFeatures,
    };
  }

  if (geoJsonUploadType === GeoJsonUploadType.MergeWithFeatures) {
    const newFeatures = input.features.map(
      ({ geometry, type, properties }) => ({
        type,
        geometry,
        properties: {
          feature_id: generateUUID(),
          feature_info_list: properties?.feature_info_list || [],
        },
      })
    );

    currentTestFeatures = {
      features: [...features, ...newFeatures],
      properties,
      type,
    };

    return {
      mapFeatures: currentTestFeatures,
    };
  }

  const newFeatures = input.features.map(({ geometry, type, properties }) => ({
    type,
    geometry,
    properties: {
      feature_id: properties?.feature_id
        ? Number(properties?.feature_id)
        : generateUUID(),
      feature_info_list: properties?.feature_info_list || [],
    },
  }));
  const newProperties = input.properties || {};

  const speedLimits: any = {};
  if (input && input.map_structs && input.map_structs.lanes) {
    input.map_structs.lanes.forEach(({ lane_id, speed_limit }: any) => {
      if (lane_id && speed_limit) {
        speedLimits[lane_id] = speed_limit;
      }
    });
  }
  const mapStructsData = getMapStructsFromFeatures(newFeatures, speedLimits);
  currentTestFeatures = {
    type: "FeatureCollection",
    features: getFilteredFeaturesProps(newFeatures, mapStructsData.errors),
    properties: newProperties,
  };

  return {
    mapFeatures: currentTestFeatures,
    mapStructs: mapStructsData.mapStructs,
    errors: mapStructsData.errors,
  };
};

export const getInitialState = (currentMapData: any): MapEditorState => ({
  viewport: {
    ...initialViewport(),
    latitude: currentMapData.latitude,
    longitude: currentMapData.longitude,
  },
  measureFeatures: getEmptyFeatureCollection(
    currentMapData.latitude,
    currentMapData.longitude
  ),
  pointsRemovable: true,
  selectedLaneId: INVALID_ID_NUMBER,
  editContext: undefined,
  editHandleType: "point",
  showDialog: false,
  featureMenu: undefined,
  editPlane: EDIT_PLANE_CONFIG,
  radiusDialog: false,
  pointCloudJson: currentMapData.pointCloudJson,
  layerVisibility: {},
  layers: [],
  editableGeoJsonLayer: null,
  editableGeoJsonLayerVisible: true,
  editPlaneLayerVisible: false,
  pointCloudData: [],
  // TODO: add Floor Plan state and initialize
  isLoading: false,
  geojsonType: null,
  showGeoJsonTypeModal: false,
  bufferFeature: null,
  highlightedFeatureIndexes: null,
  tooltipData: null,
});

export const calculateDistance = (
  coord1: [number, number] | undefined,
  coord2: [number, number] | undefined
): number => {
  if (!coord1 || !coord2) {
    return Infinity;
  }

  const toRadians = (degrees: number): number => (degrees * Math.PI) / 180;

  const [lon1, lat1] = coord1;
  const [lon2, lat2] = coord2;

  const EARTH_RADIUS = 6371e3;

  const latitude1Rad = toRadians(lat1);
  const latitude2Rad = toRadians(lat2);
  const deltaLatRad = toRadians(lat2 - lat1);
  const deltaLonRad = toRadians(lon2 - lon1);

  // Haversine formula to calculate distance
  const a =
    Math.sin(deltaLatRad / 2) ** 2 +
    Math.cos(latitude1Rad) *
      Math.cos(latitude2Rad) *
      Math.sin(deltaLonRad / 2) ** 2;
  const angularDistance = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance between the two points in meters
  return EARTH_RADIUS * angularDistance;
};

export const findClosestPoint = (
  coordinate: [number, number],
  features: GeoJSON.Feature[]
): [number, number] => {
  let closestPoint: [number, number] = [...coordinate];
  let minDistance = Infinity;

  features.forEach((feature) => {
    if (feature.geometry.type === "LineString") {
      const coords = feature.geometry.coordinates as [number, number][];
      const outerPoints = [coords[0], coords[coords.length - 1]];

      outerPoints.forEach((pointCoord) => {
        const distance = calculateDistance(coordinate, pointCoord);
        if (distance < minDistance && distance <= SNAP_MIN_DISTANCE) {
          minDistance = distance;
          closestPoint = pointCoord;
        }
      });
    } else if (feature.geometry.type === "Polygon") {
      const outerRing = feature.geometry.coordinates[0] as [number, number][];
      outerRing.forEach((pointCoord) => {
        const distance = calculateDistance(coordinate, pointCoord);

        if (distance < minDistance && distance <= SNAP_MIN_DISTANCE) {
          minDistance = distance;
          closestPoint = pointCoord;
        }
      });
    }
  });

  return closestPoint;
};

export const snapFeature = (
  featureIndex: number,
  featuresData: FeaturesInterface
) => {
  const newFeaturesData = { ...featuresData };
  const { features } = newFeaturesData;
  const currentFeature = featuresData.features[featureIndex];
  const previousFeatures = [
    ...features.slice(0, featureIndex),
    ...features.slice(featureIndex + 1),
  ];

  const coordinates = getCoordinates(currentFeature.geometry);

  if (
    coordinates &&
    (currentFeature.geometry.type === "LineString" ||
      currentFeature.geometry.type === "Polygon")
  ) {
    (coordinates as [number, number][]).forEach(
      (coordinate: [number, number], index) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        currentFeature.geometry.coordinates[index] = findClosestPoint(
          coordinate,
          previousFeatures
        );
      }
    );
  }

  return newFeaturesData;
};

const getCoordinates = (geometry: GeoJSON.Geometry) => {
  if (geometry.type === "LineString" || geometry.type === "Polygon") {
    return geometry.coordinates;
  } else {
    throw new Error("Unsupported geometry type");
  }
};

export const getRadius = (
  testFeatures: FeaturesInterface,
  editContext?: any
) => {
  const index: number = editContext?.featureIndex;
  const position = editContext?.positionIndexes?.[0];
  const feature = testFeatures.features?.[index];

  return feature?.properties?.radius?.get(position);
};

export const getIsError = (
  testFeatures: FeaturesInterface,
  editContext?: any
) => {
  const index: number = editContext?.featureIndex;
  const position = editContext?.positionIndexes?.[0];
  const line = testFeatures.features?.[index]?.geometry as LineString;
  const length = line?.coordinates?.length || 0;

  // Intermediate handle
  if (editContext?.position) {
    return true;
  }

  return !(position > 0 && length - 1 > position);
};

export const getLaneAssociations = (feature: any): number[] | null => {
  if (
    feature?.properties?.feature_info_list &&
    Array.isArray(feature.properties.feature_info_list)
  ) {
    const laneAssociations = feature.properties.feature_info_list
      .map((info: any) => Number(info.lane_association))
      .filter((lane: any) => lane !== undefined && lane !== "NULL");

    return laneAssociations.length > 0 ? laneAssociations : null;
  }

  return null;
};

export const getFeatureIndexesByLaneIds = (
  features: any[],
  laneIds: number[]
): number[] => {
  const featureIndexes: number[] = [];

  features.forEach((feature, index) => {
    if (
      feature?.properties?.feature_info_list &&
      Array.isArray(feature.properties.feature_info_list)
    ) {
      const hasMatchingLane = feature.properties.feature_info_list.some(
        (info: any) => laneIds.includes(Number(info.lane_association))
      );

      if (hasMatchingLane) {
        featureIndexes.push(index);
      }
    }
  });

  return featureIndexes;
};

export const groupPointsByColor = (
  pointCloudData: { COORDINATES: number[] }[]
) => {
  if (!pointCloudData) {
    return {}; // Return an empty object if pointCloudData is undefined
  }

  const groupedPoints: any = {};

  for (let i = 0; i < COLOR_COUNT; i++) {
    const color = rainbow().colourAt((i * 20) % 100);
    groupedPoints[color] = [];
  }

  pointCloudData.forEach((point: { COORDINATES: number[] }) => {
    const colorIndex = Math.floor(
      ((point.COORDINATES[2] * 20) % 100) / (100 / COLOR_COUNT)
    );
    const color = Object.keys(groupedPoints)[colorIndex];
    if (groupedPoints[color]) {
      groupedPoints[color].push(point);
    }
  });

  return groupedPoints;
};

export const isFeatureInfoListNull = (featureInfoList: any[]) => {
  // nothing has been populated yet
  if (!featureInfoList) {
    return true;
  }

  for (let i = 0; i < featureInfoList.length; i++) {
    const tempFeatureInfo = featureInfoList[i];
    if (LINE_TYPE_STRING_NAME in tempFeatureInfo) {
      if (tempFeatureInfo[LINE_TYPE_STRING_NAME] !== NULL_STRING_NAME) {
        return false;
      }
    } else if (POLYGON_TYPE_STRING_NAME in tempFeatureInfo) {
      if (tempFeatureInfo[POLYGON_TYPE_STRING_NAME] !== NULL_STRING_NAME) {
        return false;
      }
    }
  }
  return true;
};

export const isPolygonTypeStopSign = (
  featureInfoList: Record<string, string>[] | undefined
) => {
  if (Array.isArray(featureInfoList) && featureInfoList.length === 1) {
    return (
      featureInfoList[0][POLYGON_TYPE_STRING_NAME] === STOP_SIGN_STRING_NAME
    );
  }
  return false;
};

export const featurePropIdtoIndex = (
  geometry_type: string,
  feature_prop_name: string,
  feature_prop_id: number,
  features: GeoJSON.Feature[]
) => {
  for (let i = 0; i < features?.length; i++) {
    const feature = features[i];
    if (feature.geometry.type === geometry_type) {
      for (const index in feature.properties?.feature_info_list) {
        if (
          feature.properties?.feature_info_list[index][feature_prop_name] ===
          feature_prop_id
        ) {
          return i;
        }
      }
    }
  }
  return INVALID_ID_NUMBER;
};

export const updateMeasureFeatures = (
  measureFeatures: FeaturesInterface,
  mode: { _clickSequence: any; _currentDistance?: any; _currentTooltips?: any }
) => {
  const currentMeasureFeatures = { ...measureFeatures };
  if (mode && mode._clickSequence) {
    const { _clickSequence, _currentDistance, _currentTooltips } = mode;
    if (mode._clickSequence.length === 1) {
      currentMeasureFeatures.features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: _clickSequence,
        },
        properties: {
          id: generateUUID(),
          type: "measure",
          currentTooltips: _currentTooltips,
          distance: _currentDistance,
          feature_id: 0,
          feature_info_list: [],
        },
      });
    }
    if (mode._clickSequence.length > 1) {
      currentMeasureFeatures.features.slice(-1)[0] = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: _clickSequence,
        },
        properties: {
          id: generateUUID(),
          type: "measure",
          distance: _currentDistance,
          currentTooltips: _currentTooltips,
          feature_id: 0,
          feature_info_list: [],
        },
      };
    }
  }

  return currentMeasureFeatures;
};

export const getMeasureTooltips = (measureFeatures: FeaturesInterface) => {
  const { features } = measureFeatures;
  const currentTooltips: Array<any> = [];
  features.forEach((feature) => {
    if (feature && feature.properties) {
      currentTooltips.push(...feature.properties.currentTooltips);
    }
  });
  return currentTooltips;
};

export const copyPasteFeature = (
  feature: GeoJSON.Feature,
  targetCoordinates: Array<number>
) => {
  const { geometry } = feature;

  if (geometry.type !== "Polygon" && geometry.type !== "LineString") {
    throw new Error("Unsupported geometry type");
  }

  let minLon = Infinity;
  let maxLat = -Infinity;
  let coordinates: number[][] = [];

  if (geometry.type === "Polygon") {
    coordinates = geometry.coordinates[0];
  } else if (geometry.type === "LineString") {
    coordinates = geometry.coordinates;
  }

  coordinates.forEach(([lon, lat]) => {
    if (lon < minLon) minLon = lon;
    if (lat > maxLat) maxLat = lat;
  });

  const lonOffset = targetCoordinates[0] - minLon;
  const latOffset = targetCoordinates[1] - maxLat;

  const newCoordinates = coordinates.map(([lon, lat]) => [
    lon + lonOffset,
    lat + latOffset,
  ]);

  return {
    ...feature,
    geometry: {
      ...geometry,
      coordinates:
        geometry.type === "Polygon" ? [newCoordinates] : newCoordinates,
    },
    properties: {
      ...feature.properties,
      feature_info_list: [],
    },
  };
};

export const getTopLeftPoint = (
  feature: GeoJSON.Feature
): [number, number] | null => {
  let coordinates: Array<[number, number]> = [];

  if (feature.geometry.type === "LineString") {
    coordinates = feature.geometry.coordinates as [number, number][];
  } else if (feature.geometry.type === "Polygon") {
    coordinates = feature.geometry.coordinates[0] as [number, number][];
  }

  if (coordinates.length === 0) return null;

  const topLeft = coordinates.reduce((topLeft, coord) => {
    const [long, lat] = coord;
    if (lat > topLeft[1] || (lat === topLeft[1] && long < topLeft[0])) {
      return [long, lat];
    }
    return topLeft;
  }, coordinates[0]);

  return topLeft;
};

export type Direction = "horizontal" | "vertical";
export type Position = [number, number] | [number, number, number];

export const flipCoordinates = (
  coordinates: Position[],
  direction: Direction
): Position[] => {
  if (direction === "vertical") {
    const minY = Math.min(...coordinates.map((coord) => coord[1]));
    const maxY = Math.max(...coordinates.map((coord) => coord[1]));
    const midY = (minY + maxY) / 2;

    return coordinates.map((coord) => {
      const [x, y, z] = coord;
      const flippedY = midY - (y - midY);
      return z !== undefined ? [x, flippedY, z] : [x, flippedY];
    });
  } else if (direction === "horizontal") {
    const minX = Math.min(...coordinates.map((coord) => coord[0]));
    const maxX = Math.max(...coordinates.map((coord) => coord[0]));
    const midX = (minX + maxX) / 2;

    return coordinates.map((coord) => {
      const [x, y, z] = coord;
      const flippedX = midX - (x - midX);
      return z !== undefined ? [flippedX, y, z] : [flippedX, y];
    });
  }
  return coordinates;
};
