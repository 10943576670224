import styled, { css } from "styled-components";
import Switch from "react-input-switch";
import { Card } from "react-bootstrap";

export const BmItemTitleStyled = styled.div`
  color: white;
  font-size: 20px;
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

export const EditPlaneToolboxStyled = styled.div`
  background-color: rgba(39, 45, 59, 0.8);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
`;

export const EditPlaneButtonStyled = styled.div`
  margin: 4px;
  display: flex;
  flex-direction: row;
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 48px;
  width: 100%;
`;

export const MenuBtnStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  height: 40px;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  box-sizing: border-box;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
  }
`;

export const SpeedLimitInputStyled = styled.input.attrs({
  type: "number",
  step: "0.01",
})`
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box;
  height: 24px;
`;

export const SwitchStyled = styled(Switch)`
  margin-left: 8px;
`;

export const CardContainerStyled = styled(Card)`
  margin-bottom: 8px;
`;

export const CardStyled = styled.div`
  padding: 0rem;
`;

export const CardHeaderStyled = styled(Card.Header)`
  padding: 0rem;
`;

export const CardBodyStyled = styled(Card.Body)`
  padding: 0rem;
`;

export const ButtonStyled = styled.div`
  height: 32px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  margin: 0 auto;

  &:hover {
    opacity: 0.8;
  }
`;

export const EditButtonStyled = styled(ButtonStyled)`
  background: #172a3f;
`;

export const RemoveButtonStyled = styled(ButtonStyled)`
  background: #a90000;
`;

export const LaneButtonStyled = css`
  font-size: 16px;
  height: 40px;
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const CancelSelectedLanesButtonStyled = styled.div`
  ${LaneButtonStyled};
  color: #a90000;
`;

export const DefaultSpeedButtonStyled = styled.div`
  ${LaneButtonStyled};
  color: #006ea9;
`;
