import React, { FC, memo, SVGProps, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ToolbarDropdown, ToolbarDropdownProps } from "../ToolbarDropdown";
import {
  decreaseUndoStep,
  getCurrentMapId,
  getCurrentProjectName,
  getFeatures,
  getMode,
  getOutput,
  getSelectedFeatureIndexes,
  getUndoFeatures,
  getUndoStep,
  increaseUndoStep,
  saveGeoJsonAction,
  setDeleteSelectedItems,
  setMode,
} from "../../../../store/slices/currentMap";

import { ReactComponent as FileIcon } from "./assets/file.svg";
import { ReactComponent as SelectIcon } from "./assets/select.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as MeasureIcon } from "./assets/measure.svg";
import { ReactComponent as VerifyIcon } from "./assets/verify.svg";
import { ReactComponent as DrawIcon } from "./assets/draw.svg";
import { ReactComponent as NewProjectIcon } from "./assets/newProject.svg";
import { ReactComponent as OpenProjectIcon } from "./assets/openProject.svg";
import { ReactComponent as SaveProjectIcon } from "./assets/saveProject.svg";
// import { ReactComponent as ImportIcon } from "./assets/import.svg";
// import { ReactComponent as RenderBoundariesIcon } from "./assets/renderBoundaries.svg";
import { ReactComponent as ExportIcon } from "./assets/export.svg";
import { ReactComponent as UndoIcon } from "./assets/undo.svg";
import { ReactComponent as RedoIcon } from "./assets/redo.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as TranslateIcon } from "./assets/translate.svg";
import { ReactComponent as AreaIcon } from "./assets/area.svg";
import { ReactComponent as RotateIcon } from "./assets/rotate.svg";
import { ReactComponent as DistanceIcon } from "./assets/distance.svg";
import { ReactComponent as AngleIcon } from "./assets/angle.svg";
import { ReactComponent as StopIcon } from "./assets/stop.svg";
import { ReactComponent as LineIcon } from "./assets/line.svg";
// import { ReactComponent as GoalPointIcon } from "./assets/goalPoint.svg";
// import { ReactComponent as InitializationPointIcon } from "./assets/initializationPoint.svg";
import { ReactComponent as IntersectionIcon } from "./assets/intersection.svg";

import { EditorToolbarStyled } from "./styles";
import {
  CONFIRM_ACTION_MODAL,
  INTERSECTIONS_MODAL,
  NEW_PROJECT_MODAL,
  toggleModal,
  VERIFY_SEMANTIC_MAP_MODAL,
} from "../../../../store/slices/modals";
import { fetchPatchMapPbBinFile } from "../../../../store/slices/projects";
import { replacer } from "../../../../utils";

export const EditorToolbar = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mode = useSelector(getMode);
  const mapFeatures = useSelector(getFeatures);
  const selectedFeatureIndexes = useSelector(getSelectedFeatureIndexes);
  const undoFeatures = useSelector(getUndoFeatures);
  const undoStep = useSelector(getUndoStep);
  const currentProjectName = useSelector(getCurrentProjectName);
  const currentProjectId = useSelector(getCurrentMapId);
  const output = useSelector(getOutput);
  const isTransformDisabled = selectedFeatureIndexes.length === 0;
  const isUndoDisabled =
    undoFeatures.length === 1 ||
    (undoStep !== null && undoStep === undoFeatures.length - 1);
  const isRedoDisabled =
    undoStep === null || undoStep === 0 || undoFeatures.length === 1;

  const toolbarOptions: Array<ToolbarDropdownProps> = useMemo(
    () => [
      {
        name: "File",
        Icon: FileIcon as FC<SVGProps<SVGElement>>,
        options: [
          {
            optionName: "New Project",
            OptionIcon: NewProjectIcon as FC<SVGProps<SVGElement>>,
            optionClickAction: () => {
              dispatch(
                toggleModal({
                  type: NEW_PROJECT_MODAL,
                })
              );
            },
          },
          {
            optionName: "Open Project",
            OptionIcon: OpenProjectIcon as FC<SVGProps<SVGElement>>,
            optionClickAction: () => {
              if (undoFeatures.length !== 1) {
                dispatch(
                  toggleModal({
                    type: CONFIRM_ACTION_MODAL,
                    data: {
                      text: `You are about to leave ${currentProjectName} with unsaved changes. Would you like to keep your work before leaving the project`,
                      confirmAction: () => {
                        dispatch(saveGeoJsonAction());
                        navigate("/");
                      },
                      cancelAction: () => navigate("/"),
                      submitButtonText: "Save",
                      cancelButtonText: "Discard",
                    },
                  })
                );
              } else {
                navigate("/");
              }
            },
          },
          {
            optionName: "Save Project",
            OptionIcon: SaveProjectIcon as FC<SVGProps<SVGElement>>,
            optionClickAction: () => {
              dispatch(saveGeoJsonAction());
            },
            fullBorder: true,
          },
          // {
          //   optionName: "Import",
          //   OptionIcon: ImportIcon as FC<SVGProps<SVGElement>>,
          //   optionClickAction: () => {
          //     console.log("action");
          //   },
          // },
          {
            optionName: "Verify..",
            OptionIcon: VerifyIcon as FC<SVGProps<SVGElement>>,
            optionClickAction: () => {
              dispatch(
                toggleModal({
                  type: VERIFY_SEMANTIC_MAP_MODAL,
                  data: mapFeatures,
                })
              );
            },
          },
          // {
          //   optionName: "Render Boundaries",
          //   OptionIcon: RenderBoundariesIcon as FC<SVGProps<SVGElement>>,
          //   optionClickAction: () => {
          //     console.log("action");
          //   },
          // },
          {
            optionName: "Export",
            OptionIcon: ExportIcon as FC<SVGProps<SVGElement>>,
            subOptions: [
              {
                name: "download .pb.bin",
                subOptionClickAction: () => {
                  dispatch(
                    fetchPatchMapPbBinFile(
                      currentProjectId || window.location.pathname.split("/")[2]
                    )
                  );
                },
              },
              {
                name: "download .geojson",
                subOptionClickAction: () => {
                  const blob = new Blob([JSON.stringify(output, replacer)], {
                    type: "octet/stream",
                  });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = "nebula.geojson";
                  link.click();
                },
              },
            ],
          },
        ],
      },
      {
        name: "Select",
        Icon: SelectIcon as FC<SVGProps<SVGElement>>,
        isActive: mode === "GeoJsonEditMode",
        clickAction: () => {
          dispatch(setMode("GeoJsonEditMode"));
        },
      },
      {
        name: "Edit",
        Icon: EditIcon as FC<SVGProps<SVGElement>>,
        isActive: [
          "ModifyMode",
          "TranslateMode",
          "TransformMode",
          "RotateMode",
        ].includes(mode),
        options: [
          {
            optionName: "Undo",
            isDisabled: isUndoDisabled,
            OptionIcon: UndoIcon as FC<SVGProps<SVGElement>>,
            optionClickAction: () => {
              if (!isUndoDisabled) {
                dispatch(increaseUndoStep());
              }
            },
          },
          {
            optionName: "Redo",
            isDisabled: isRedoDisabled,
            OptionIcon: RedoIcon as FC<SVGProps<SVGElement>>,
            optionClickAction: () => {
              if (!isRedoDisabled) {
                dispatch(decreaseUndoStep());
              }
            },
            fullBorder: true,
          },
          {
            optionName: "ModifyMode",
            OptionIcon: EditIcon as FC<SVGProps<SVGElement>>,
            isDisabled: isTransformDisabled,
            optionClickAction: () => {
              if (!isTransformDisabled) {
                dispatch(setMode("ModifyMode"));
              }
            },
          },
          {
            optionName: "Delete",
            OptionIcon: DeleteIcon as FC<SVGProps<SVGElement>>,
            isDisabled: isTransformDisabled,
            optionClickAction: () => {
              dispatch(setDeleteSelectedItems(true));
              dispatch(setMode("GeoJsonEditMode"));
            },
          },
          {
            optionName: "Translate",
            OptionIcon: TranslateIcon as FC<SVGProps<SVGElement>>,
            isDisabled: isTransformDisabled,
            isActive: mode === "TranslateMode",
            optionClickAction: () => {
              if (!isTransformDisabled) {
                dispatch(setMode("TranslateMode"));
              }
            },
          },
          {
            optionName: "Transform",
            OptionIcon: AreaIcon as FC<SVGProps<SVGElement>>,
            isDisabled: isTransformDisabled,
            isActive: mode === "TransformMode",
            optionClickAction: () => {
              if (!isTransformDisabled) {
                dispatch(setMode("TransformMode"));
              }
            },
          },
          {
            optionName: "Rotate",
            OptionIcon: RotateIcon as FC<SVGProps<SVGElement>>,
            isDisabled: isTransformDisabled,
            isActive: mode === "RotateMode",
            optionClickAction: () => {
              if (!isTransformDisabled) {
                dispatch(setMode("RotateMode"));
              }
            },
          },
        ],
      },
      {
        name: "Measure",
        Icon: MeasureIcon as FC<SVGProps<SVGElement>>,
        isActive: ["MeasureDistanceMode", "MeasureAngleMode"].includes(mode),
        options: [
          {
            optionName: "Distance",
            OptionIcon: DistanceIcon as FC<SVGProps<SVGElement>>,
            isActive: mode === "MeasureDistanceMode",
            optionClickAction: () => {
              dispatch(setMode("MeasureDistanceMode"));
            },
          },
          {
            optionName: "Angle",
            OptionIcon: AngleIcon as FC<SVGProps<SVGElement>>,
            isActive: mode === "MeasureAngleMode",
            optionClickAction: () => {
              dispatch(setMode("MeasureAngleMode"));
            },
            fullBorder: true,
          },
        ],
      },
      {
        name: "Draw",
        Icon: DrawIcon as FC<SVGProps<SVGElement>>,
        isActive: ["DrawLineStringMode, DrawStopMode"].includes(mode),
        options: [
          {
            optionName: "Stop",
            OptionIcon: StopIcon as FC<SVGProps<SVGElement>>,
            isActive: mode === "DrawStopMode",
            optionClickAction: () => {
              dispatch(setMode("DrawStopMode"));
              // dispatch(setMode("DrawPolygonMode"));
            },
          },
          {
            optionName: "Line",
            OptionIcon: LineIcon as FC<SVGProps<SVGElement>>,
            isActive: mode === "DrawLineStringMode",
            optionClickAction: () => {
              dispatch(setMode("DrawLineStringMode"));
            },
          },
          // {
          //   optionName: "Goal Point",
          //   OptionIcon: GoalPointIcon as FC<SVGProps<SVGElement>>,
          //   optionClickAction: () => {
          //     console.log("action");
          //   },
          // },
          // {
          //   optionName: "Initialization Point",
          //   OptionIcon: InitializationPointIcon as FC<SVGProps<SVGElement>>,
          //   optionClickAction: () => {
          //     console.log("action");
          //   },
          // },
          {
            optionName: "Intersection",
            OptionIcon: IntersectionIcon as FC<SVGProps<SVGElement>>,
            optionClickAction: () => {
              dispatch(
                toggleModal({
                  type: INTERSECTIONS_MODAL,
                })
              );
            },
          },
        ],
      },
    ],
    [
      isTransformDisabled,
      selectedFeatureIndexes,
      isUndoDisabled,
      isRedoDisabled,
      mode,
    ]
  );

  return (
    <EditorToolbarStyled>
      {toolbarOptions.map(({ name, Icon, options, clickAction, isActive }) => {
        return (
          <ToolbarDropdown
            key={name}
            name={name}
            Icon={Icon}
            options={options}
            clickAction={clickAction}
            isActive={isActive}
          />
        );
      })}
    </EditorToolbarStyled>
  );
});
