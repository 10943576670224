import * as React from "react";
import { FC, memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ElevationMode, Position } from "@nebula.gl/edit-modes";

import { ALL_MODES, TWO_CLICK_POLYGON_MODES } from "../../constants/nebula";
import {
  Toolbox,
  ToolboxButton,
  ToolboxCheckbox,
  ToolboxControl,
  ToolboxRow,
  ToolboxSelect,
  ToolboxLabel,
} from "../../components/common/toolbox";
import FeatureItem from "../FeatureItem";
import {
  GeoJsonType,
  GeoJsonUploadType,
} from "../../pages/MapEditor/mapEditor";
import MeasureElements from "../MeasureElements";
import { CONFIRM_ACTION_MODAL } from "../../store/slices/modals";
import { sortArrayByIndices } from "../../utils";
import {
  getFeatures,
  getMode,
  getModeConfig,
  getSelectedFeatureIndexes,
  setFeatures,
  setModeWithConfig,
  setSelectedFeatureIndexes,
} from "../../store/slices/currentMap";
import { modeConfigAdditionalSnapTargets } from "../../constants/map-constants";

import { MapToolboxProps, ModeType } from "./index.d";
import { ToolboxControlTextareaStyled, ToolboxTitleStyled } from "./styles";

export const MapToolbox: FC<MapToolboxProps> = memo(
  ({
    geojsonType,
    showGeoJson,
    updateShowGeoJson,
    measureFeatures,
    removeMeasureElements,
    toggleModalAction,
    updateModeConfig,
    pointsRemovable,
    updatePointsRemovable,
    parseStringJson,
    deleteFeatureProperty,
    featureMenuClick,
    updateSelectedFeatureIndexes,
    viewport,
  }) => {
    const dispatch = useDispatch();
    const [pointcloudURL, setPointcloudURL] = useState<string | null>(null);
    const [currentCategory, setCurrentCategory] = useState<string>("Draw");

    const mapFeatures = useSelector(getFeatures);
    const currentMode = useSelector(getMode);
    const modeConfig = useSelector(getModeConfig);
    const selectedFeatureIndexes = useSelector(getSelectedFeatureIndexes);

    let currentModeConfig = modeConfig;
    const alterDisabled = selectedFeatureIndexes.length === 0;

    if (currentMode === "ElevationMode") {
      currentModeConfig = {
        ...currentModeConfig,
        viewport,
        calculateElevationChange: (opts: {
          pointerDownScreenCoords: Position;
          screenCoords: Position;
        }) =>
          ElevationMode.calculateElevationChangeWithViewport(viewport, opts),
      };
    } else if (currentMode === "ModifyMode") {
      currentModeConfig = {
        ...currentModeConfig,
        viewport,
      };
    } else if (
      currentMode === "SnappableMode" &&
      currentModeConfig &&
      currentModeConfig.enableSnapping
    ) {
      // Snapping can be accomplished to features that aren't rendered in the same layer
      currentModeConfig = {
        ...currentModeConfig,
        additionalSnapTargets: modeConfigAdditionalSnapTargets,
      };
    }

    const buildSelectFeatureCheckboxes = useCallback(() => {
      const { features } = mapFeatures;
      const checkboxes: JSX.Element[] = [];

      features.forEach((v, i) =>
        checkboxes.push(
          <FeatureItem
            deleteFeatureProperty={deleteFeatureProperty}
            featureMenuClick={featureMenuClick}
            updateSelectedFeatureIndexes={updateSelectedFeatureIndexes}
            key={`feature-item-${i}`}
            index={i}
            featureType={v?.geometry?.type}
          />
        )
      );

      return sortArrayByIndices(checkboxes, selectedFeatureIndexes);
    }, [
      mapFeatures,
      deleteFeatureProperty,
      featureMenuClick,
      updateSelectedFeatureIndexes,
      selectedFeatureIndexes,
    ]);

    const buildMode = useCallback(
      ({ note, label, mode }: ModeType) => {
        if (note) {
          return <ToolboxLabel key={label}>{label}</ToolboxLabel>;
        }

        return (
          <ToolboxButton
            key={label}
            selected={mode === currentMode}
            onClick={() =>
              dispatch(
                setModeWithConfig({
                  mode: mode,
                  modeConfig: {
                    turfOptions: { units: "meters" },
                  },
                })
              )
            }
          >
            {label}
          </ToolboxButton>
        );
      },
      [currentMode]
    );

    const loadSample = useCallback(
      (type: string, mergeType?: GeoJsonUploadType) => {
        if (type === "geojson") {
          const el = document.createElement("input");
          el.type = "file";
          el.onchange = (e) => {
            const eventTarget = e.target as HTMLInputElement;
            if (eventTarget.files && eventTarget.files[0]) {
              const reader = new FileReader();
              reader.onload = ({ target }) => {
                parseStringJson(target?.result as string, mergeType);
              };
              reader.readAsText(eventTarget.files[0]);
            }
          };
          el.click();
        } else if (type === "ply") {
          const el = document.createElement("input");
          el.type = "file";
          el.onchange = (e) => {
            const eventTarget = e.target as HTMLInputElement;
            if (eventTarget.files && eventTarget.files[0]) {
              const reader = new FileReader();
              reader.readAsArrayBuffer(eventTarget.files[0]);
              reader.onload = ({ target }) => {
                const blob = new Blob([target?.result as BlobPart], {
                  type: "application/octet-stream",
                });
                if (pointcloudURL) {
                  window.webkitURL.revokeObjectURL(pointcloudURL);
                }
                const currentPointcloudURL =
                  window.webkitURL.createObjectURL(blob);
                setPointcloudURL(currentPointcloudURL);
              };
            }
          };
          el.click();
        }
      },
      [pointcloudURL]
    );

    const renderSnappingControls = useCallback(
      () => (
        <ToolboxRow key="snap">
          <ToolboxTitleStyled>Enable snapping</ToolboxTitleStyled>
          <ToolboxControl>
            <ToolboxCheckbox
              checked={Boolean(modeConfig && modeConfig.enableSnapping)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateModeConfig({
                  enableSnapping: Boolean(event.target.checked),
                })
              }
            />
          </ToolboxControl>
        </ToolboxRow>
      ),
      [modeConfig, updateModeConfig]
    );

    const renderMeasureDistanceControls = useCallback(
      () => (
        <ToolboxRow key="measure-distance">
          <ToolboxTitleStyled>Units</ToolboxTitleStyled>
          <ToolboxControl>
            <ToolboxSelect
              value={modeConfig?.turfOptions?.units}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                updateModeConfig({
                  turfOptions: { units: event.target.value },
                })
              }
              options={["meters", "miles", "degrees", "radians"]}
            ></ToolboxSelect>
          </ToolboxControl>
        </ToolboxRow>
      ),
      [modeConfig, updateModeConfig]
    );

    const renderModifyModeControls = useCallback(
      () => (
        <ToolboxRow key="modify">
          <ToolboxTitleStyled>Allow removing points</ToolboxTitleStyled>
          <ToolboxControl>
            <ToolboxCheckbox
              checked={pointsRemovable}
              onChange={() => updatePointsRemovable()}
            />
          </ToolboxControl>
        </ToolboxRow>
      ),
      [pointsRemovable, updatePointsRemovable]
    );

    const renderTwoClickPolygonControls = useCallback(
      () => (
        <ToolboxRow key="twoClick">
          <ToolboxTitleStyled>Drag to draw</ToolboxTitleStyled>
          <ToolboxControl>
            <ToolboxCheckbox
              checked={Boolean(modeConfig && modeConfig.dragToDraw)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateModeConfig({
                  dragToDraw: Boolean(event.target.checked),
                })
              }
            />
          </ToolboxControl>
        </ToolboxRow>
      ),
      [modeConfig, updateModeConfig]
    );

    const renderModeConfigControls = useCallback(() => {
      const controls: JSX.Element[] = [];

      if (
        TWO_CLICK_POLYGON_MODES.some((modeClass) => currentMode === modeClass)
      ) {
        controls.push(renderTwoClickPolygonControls());
      }
      if (currentMode === "ModifyMode") {
        controls.push(renderModifyModeControls());
      }
      if (currentMode === "SnappableMode") {
        controls.push(renderSnappingControls());
      }
      if (currentMode === "MeasureDistanceMode") {
        controls.push(renderMeasureDistanceControls());
      }

      return controls;
    }, [currentMode, pointsRemovable, modeConfig]);

    return (
      <Toolbox>
        {ALL_MODES.map((category: { category: string; modes: ModeType[] }) => {
          const disabled = category.category === "Alter" && alterDisabled;
          return (
            <ToolboxRow key={category.category}>
              <ToolboxTitleStyled
                isDisabled={disabled}
                onClick={() =>
                  !disabled && setCurrentCategory(category.category)
                }
                active={category.category === currentCategory}
              >
                {category.category} Modes
              </ToolboxTitleStyled>
              {category.category === currentCategory &&
                category.modes.map((mode) => buildMode(mode))}
            </ToolboxRow>
          );
        })}
        {measureFeatures.features.length !== 0 && (
          <MeasureElements
            units={modeConfig?.turfOptions?.units}
            features={[...measureFeatures.features]}
            removeMeasureElements={removeMeasureElements}
          />
        )}
        {renderModeConfigControls()}
        {showGeoJson && (
          <React.Fragment>
            <ToolboxTitleStyled>GeoJSON</ToolboxTitleStyled>
            <ToolboxButton onClick={() => updateShowGeoJson()}>
              hide &#9650;
            </ToolboxButton>
            <ToolboxControl>
              <ToolboxControlTextareaStyled
                id="geo-json-text"
                rows={5}
                value={JSON.stringify(mapFeatures)}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  dispatch(setFeatures(JSON.parse(event.target.value)))
                }
              />
            </ToolboxControl>
          </React.Fragment>
        )}
        <ToolboxRow>
          <ToolboxTitleStyled
            onClick={() => setCurrentCategory("Data")}
            active={currentCategory === "Data"}
          >
            Data
          </ToolboxTitleStyled>
          {currentCategory === "Data" && (
            <>
              {geojsonType === GeoJsonType.Centerline && (
                <ToolboxControl>
                  <ToolboxButton onClick={() => loadSample("geojson")}>
                    Open Centerline GeoJSON
                  </ToolboxButton>
                </ToolboxControl>
              )}
              {geojsonType === GeoJsonType.Semantic && (
                <ToolboxControl>
                  <ToolboxButton onClick={() => loadSample("geojson")}>
                    Open Semantic GeoJSON
                  </ToolboxButton>
                  {mapFeatures.features.length !== 0 && (
                    <ToolboxButton
                      // onClick={() =>
                      //   loadSample("geojson", GeoJsonUploadType.GeoJsonUploadType)
                      // }
                      onClick={() =>
                        toggleModalAction({
                          type: CONFIRM_ACTION_MODAL,
                          data: {
                            text: "Would you like to merge with feature properties?",
                            confirmAction: () =>
                              loadSample(
                                "geojson",
                                GeoJsonUploadType.MergeWithFeatures
                              ),
                            cancelAction: () =>
                              loadSample(
                                "geojson",
                                GeoJsonUploadType.MergeWithNoFeatures
                              ),
                          },
                        })
                      }
                    >
                      Merge geojson
                    </ToolboxButton>
                  )}
                </ToolboxControl>
              )}

              <ToolboxControl>
                <ToolboxButton
                  disabled
                  title="Coming soon..."
                  onClick={() => loadSample("ply")}
                >
                  Open pointcloud map...
                </ToolboxButton>
              </ToolboxControl>
            </>
          )}
        </ToolboxRow>
        <ToolboxRow>
          <ToolboxTitleStyled
            onClick={() => setCurrentCategory("Features")}
            active={currentCategory === "Features"}
          >
            Features
          </ToolboxTitleStyled>
          {currentCategory === "Features" && (
            <>
              <ToolboxButton
                onClick={() => dispatch(setSelectedFeatureIndexes([]))}
              >
                Clear Selection
              </ToolboxButton>
              <ToolboxRow>{buildSelectFeatureCheckboxes()}</ToolboxRow>
            </>
          )}
        </ToolboxRow>
      </Toolbox>
    );
  }
);
