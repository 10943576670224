import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeAnyModal } from "../../../store/slices/modals";
import { getPointClouds } from "../../../store/slices/pointClouds";
import { fetchPostProject } from "../../../store/slices/projects";

import {
  ModalBackgroundStyled,
  ModalDropdownWrapper,
  ModalStyled,
  ModalTextDropdownWrapper,
  ModalTextStyled,
} from "../styles";
import { ModalWrapper } from "../ModalWrapper";
import { Input } from "../common/Input";
import { LabelField } from "../common/LabelField";
import { PlyFileLoaderController } from "../common/PlyFileLoader/PlyFileLoaderController";
import { DropdownComponent } from "../../common/DropdownComponent";
import { useNavigate } from "react-router-dom";

export const NewProjectModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [cloudName, setCloudName] = useState("");
  const [cloudDescription, setCloudDescription] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [selectedPointCloud, setSelectedPointCloud] =
    useState<PointCloud | null>(null);
  const [pointCloudFile, setPointCloudFile] = useState<File | null>(null);

  const pointClouds = useSelector(getPointClouds);

  const createAction = () => {
    dispatch(
      fetchPostProject({
        data: {
          name: projectName,
          description: projectDescription,
          latitude: Number(latitude),
          longitude: Number(longitude),
          pointCloudId: selectedPointCloud?._id,
          cloudName,
          cloudDescription,
          file: pointCloudFile,
        },
        onProjectCreated: (projectId: string) => {
          // This solution was based on a workable solution proposed here, using { replace: true } did not work here
          // https://stackoverflow.com/questions/68825965/react-router-v6-usenavigate-doesnt-navigate-if-replacing-last-element-in-path
          navigate(`/mapEditor/${projectId}`);
          navigate(0);
        },
      })
    );
    handleClose();
  };

  const handleClose = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  const isProjectInfoComplete = projectName && projectDescription;
  const isPointCloudInfoComplete =
    (pointCloudFile && cloudName && cloudDescription) || selectedPointCloud;

  const isDisabled = !isProjectInfoComplete || !isPointCloudInfoComplete;

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleClose} />
      <ModalWrapper
        successAction={createAction}
        headerText={"Create new project"}
        actionButtonText={"Create project"}
        isActionButtonDisabled={isDisabled}
      >
        <Input
          isRequired={true}
          value={projectName}
          labelText="Name"
          additionalInfo="Please enter project name"
          changeAction={setProjectName}
        />
        <Input
          isRequired={true}
          value={projectDescription}
          labelText="Description"
          additionalInfo="Please enter project description"
          changeAction={setProjectDescription}
        />
        <Input
          isRequired={true}
          value={latitude}
          labelText="Lat"
          additionalInfo="Please enter latitude"
          changeAction={setLatitude}
        />
        <Input
          isRequired={true}
          value={longitude}
          labelText="Long"
          additionalInfo="Please enter longitude"
          changeAction={setLongitude}
        />
        <ModalTextDropdownWrapper>
          <ModalTextStyled>Point cloud</ModalTextStyled>
          <ModalDropdownWrapper>
            <DropdownComponent
              placeholderValue={"Select a Point Cloud"}
              listData={pointClouds}
              currentItem={selectedPointCloud?.name || "Upload new"}
              changeItemAction={setSelectedPointCloud}
              specialAction={"Upload new"}
              specialActionHandler={() => setSelectedPointCloud(null)}
            />
          </ModalDropdownWrapper>
        </ModalTextDropdownWrapper>
        {!selectedPointCloud?._id && (
          <>
            <Input
              isRequired={true}
              value={cloudName}
              labelText="Point Cloud Name"
              additionalInfo="Please enter point cloud name"
              changeAction={setCloudName}
            />
            <Input
              isRequired={true}
              value={cloudDescription}
              labelText="Point Cloud Description"
              additionalInfo="Please enter point cloud description"
              changeAction={setCloudDescription}
            />
            <>
              <LabelField isRequired={true} labelText="File" />
              <PlyFileLoaderController
                setFile={setPointCloudFile}
                file={pointCloudFile}
              />
            </>
          </>
        )}
      </ModalWrapper>
    </ModalStyled>
  );
};
