import React, { createRef, PureComponent } from "react";
import { connect } from "react-redux";
import { ToastContent, toast } from "react-toastify";
import * as Utils from "../../utils";
import { PointCloudLayer, TextLayer, IconLayer } from "@deck.gl/layers";
import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { MapController, MapView } from "@deck.gl/core";
import DeckGL from "@deck.gl/react/typed";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { Feature, GeoJsonProperties, Geometry, LineString } from "geojson";
import { cloneDeep } from "lodash";
import window from "window-or-global";
import { InteractiveMap } from "react-map-gl";
import { WebMercatorViewport } from "@deck.gl/core";
import { registerLoaders } from "@loaders.gl/core";
import update from "immutability-helper";
import * as MapConst from "../../constants/map-constants"; // Register ply file loader - Used by pointcloud layer for loading ply
import { GeoJsonEditMode, Polygon, Position } from "@nebula.gl/edit-modes";
import {
  getEditHandleColor,
  getEditHandleTypeFromEitherLayer,
} from "../../constants/nebula";
import {
  fetchPatchMapGeoJson,
  fetchPatchMapPbBinFile,
} from "../../store/slices/projects";
import {
  FeaturesInterface,
  LineInterface,
  PolygonInterface,
} from "../../models/map-interface";
import { edit_plane_layer } from "../../utils/layers";
// TODO comment to open a new modal window
import FeatureDialog from "../../components/FeatureDialog";
import FeatureMenu from "../../components/FeatureMenu";
import LoaderSpinner from "../../components/common/loaderSpinner";
import { MapToolbox } from "../../components/MapToolbox";
import { PLYWorkerLoader } from "@loaders.gl/ply";
import RadiusDialog from "../../components/RadiusDialog";
import { SideMenu } from "../../components/sideMenu/side-menu";

import {
  setGeojsonType,
  getCurrentMap,
  fetchGetCurrentMapData,
  setContextMenuData,
  getContextMenuData,
  getLockedFeatures,
  getMode,
  setMode,
  setModeConfig,
  getModeConfig,
  resetCurrentMap,
  setModeWithConfig,
  getSelectedFeatureIndexes,
  setSelectedFeatureIndexes,
  getDeleteSelectedItems,
  setDeleteSelectedItems,
  getUndoFeatures,
  addUndoFeature,
  getUndoStep,
  setFeatures,
  getFeatures,
  getLanesBySelectedFeatures,
  FeatureInfo,
} from "../../store/slices/currentMap";
import {
  getIsLoading,
  getSaveSemanticMapFlag,
} from "../../store/slices/appState";
import { calculateCurve } from "../../utils/alter-curved-edge-mode";
import {
  getLaneFeatures,
  getDeckColorForFeature,
  generateUUID,
  getGeoJsonDataWithoutMeasureFeatures,
  tangentLineConstraint,
} from "../../utils";
import { STOP_SIGN_MODAL, toggleModal } from "../../store/slices/modals";
import { GeoJsonSelectTypeModal } from "../../components/GeoJsonSelectTypeModal";

import { MapContainerStyled } from "./styles";
import StopSignIcon from "./assets/stopSign.svg";
import { MapEditorState, MapEditorProps } from "./mapEditor";
import {
  copyPasteFeature,
  Direction,
  featurePropIdtoIndex,
  flipCoordinates,
  getFeatureIndexesByLaneIds,
  getInitialState,
  getIsError,
  getLaneAssociations,
  getMeasureTooltips,
  getRadius,
  getTopLeftPoint,
  groupPointsByColor,
  isFeatureInfoListNull,
  updateMeasureFeatures,
  snapFeature,
  initializeLayerVisibility,
  createPointCloudLayers,
} from "./utils";
import {
  getEmptyFeatureCollection,
  INVALID_ID_NUMBER,
  TOOLTIP_FONT_COLOR,
  TOOLTIP_FONT_SIZE,
} from "../../map-constants.d";
import {
  getMapStructs,
  removeIntersection,
  setLane,
} from "../../store/slices/mapStructs";
import { ContextMenu } from "./components/ContextMenu";
import { Tooltip } from "./components/Tooltip";
import {
  HANDLE_WHEEL_DELTA,
  modeMap,
  STOP_SIGN,
  STOP_SIGN_ICON_HEIGHT,
  STOP_SIGN_ICON_WIDTH,
  STOP_SIGN_OFFSET_X,
  STOP_SIGN_OFFSET_Y,
  TRANSPARENT_COLOR,
  ZOOM_DELTA,
} from "./constants";
//TODO uncomment to open a new modal
// import { FeatureInfoModal } from "../../components/Modals/FeatureInfoModal";

registerLoaders(PLYWorkerLoader);
let isShiftPressed = false;

class MapEditorComponent extends PureComponent<MapEditorProps, MapEditorState> {
  currentPointCloudLayers: any;
  containerRef = createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    const { currentMapData, setFeaturesAction } = props;
    this.state = getInitialState(currentMapData);
    setFeaturesAction(
      getEmptyFeatureCollection(
        currentMapData.latitude,
        currentMapData.longitude
      )
    );
    this.currentPointCloudLayers = [];
  }

  async componentDidMount() {
    const { getCurrentMapDataAction } = this.props;
    await getCurrentMapDataAction();
    await this.fetchPointCloudData();
    this.updateLayers();

    window.addEventListener("resize", this.resize);
    window.addEventListener("keyup", this.handleKeyUp);
    document.addEventListener("keydown", this.handleKeyDown);
    if (this.containerRef.current) {
      this.containerRef.current.addEventListener("wheel", this.handleWheel, {
        passive: false,
      });
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const { viewport } = this.state;
    const {
      selectedFeatureIndexes,
      setSelectedFeatureIndexesAction,
      setDeleteSelectedItemsAction,
      setFeaturesAction,
      mapFeatures,
    } = this.props;
    const features = mapFeatures.features as GeoJSON.Feature<
      LineString | Polygon
    >[];

    if (
      (prevProps.currentMapData._id === "" &&
        this.props.currentMapData._id !== prevProps.currentMapData._id) ||
      this.props.currentMapData.latitude !==
        prevProps.currentMapData.latitude ||
      this.props.currentMapData.longitude !== prevProps.currentMapData.longitude
    ) {
      this.setState({
        viewport: {
          ...viewport,
          latitude: this.props.currentMapData.latitude
            ? this.props.currentMapData.latitude
            : viewport.latitude,
          longitude: this.props.currentMapData.longitude
            ? this.props.currentMapData.longitude
            : viewport.longitude,
        },
      });
    }

    if (
      this.props.mode === "DrawPolygonMode" &&
      this.props.mapFeatures !== prevProps.mapFeatures
    ) {
      this.setState({
        layers: this.generateLayers(),
      });
    }

    //TODO: It will be removed after features moved into redux
    if (
      this.props.deleteSelectedItems !== prevProps.deleteSelectedItems &&
      this.props.deleteSelectedItems === true
    ) {
      const updatedFeatures = features.filter(
        (_, index) => !selectedFeatureIndexes.includes(index)
      );
      setSelectedFeatureIndexesAction([]);
      setDeleteSelectedItemsAction(false);
      setFeaturesAction({
        ...mapFeatures,
        features: [...updatedFeatures],
      });
    }

    if (
      this.props.mode !== prevProps.mode ||
      this.props.selectedFeatureIndexes !== prevProps.selectedFeatureIndexes ||
      this.state.measureFeatures !== prevState.measureFeatures ||
      this.props.mapFeatures !== prevProps.mapFeatures ||
      this.state.highlightedFeatureIndexes !==
        prevState.highlightedFeatureIndexes
    ) {
      this.setState({
        editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
      });
    }

    if (
      this.props.undoStep !== prevProps.undoStep ||
      this.props.undoFeatures !== prevProps.undoFeatures
    ) {
      if (this.props.undoStep !== null) {
        setFeaturesAction({ ...this.props.undoFeatures[this.props.undoStep] });
      }
    }

    if (this.state.layerVisibility !== prevState.layerVisibility) {
      // Update the layers only when layerVisibility state changes
      this.updateLayers();
    }
  }

  handleKeyUp = (event: any) => {
    if (event.key === "Shift") {
      isShiftPressed = false;
    }
  };

  handleKeyDown = (event: any) => {
    if (event.key === "Shift") {
      isShiftPressed = true;
    }
    const { setSelectedFeatureIndexesAction, setModeAction } = this.props;
    if (event.keyCode === MapConst.ESCAPE_KEY) {
      setSelectedFeatureIndexesAction([]);
      setModeAction("GeoJsonEditMode");
    }
  };

  async fetchPointCloudData() {
    this.setState({ isLoading: true });
    const { currentMapData } = this.props;
    const { layerVisibility } = this.state;

    try {
      if (!currentMapData.pointCloudJson) return;
      const response = await fetch(currentMapData.pointCloudJson);
      const pointCloudData = await response.json();
      this.setState(
        {
          pointCloudData: pointCloudData,
          isLoading: false,
          layerVisibility: initializeLayerVisibility(
            pointCloudData,
            currentMapData,
            layerVisibility
          ),
        },
        this.updateLayers
      );
    } catch (error) {
      toast.error(`fetchPointCloudData: ${error as ToastContent}`);
    }
  }

  componentWillUnmount() {
    const { resetCurrentMapAction } = this.props;
    window.removeEventListener("resize", this.resize);
    if (this.containerRef.current) {
      this.containerRef.current.removeEventListener("wheel", this.handleWheel);
    }
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
    resetCurrentMapAction();
  }

  onIconLayerClick = (info: any) => {
    const { mapFeatures } = this.props;
    const { features } = mapFeatures;
    if (
      info.object &&
      info.object.geometry.type === "Polygon" &&
      info.object.properties?.feature_info_list.some(
        (info: FeatureInfo) => info.polygon_type === STOP_SIGN
      )
    ) {
      const currentStopFeatureIndex =
        features.findIndex(
          (feature) =>
            feature.properties?.feature_id ===
            info.object.properties?.feature_id
        ) || -1;
      this.onLayerClick({ ...info, index: currentStopFeatureIndex });
    }
  };

  onLayerClick = (info: any, event?: any) => {
    const {
      setContextMenuDataAction,
      mode,
      selectedFeatureIndexes,
      setSelectedFeatureIndexesAction,
      mapFeatures,
      toggleModalAction,
    } = this.props;
    const features = mapFeatures.features as GeoJSON.Feature<LineString>[];
    let currentSelectedFeatureIndexes: number[] = [...selectedFeatureIndexes]; // Start with current selected indexes
    let contextMenuData: ContextMenuDataType | null = null;
    const { object } = info;

    if (mode === "DrawStopMode" && object?.geometry.type === "LineString") {
      const { coordinate, index } = info;
      toggleModalAction({
        type: STOP_SIGN_MODAL,
        data: { coordinate, index },
      });
      return;
    }

    if (mode !== "GeoJsonEditMode") return;

    const isFeature = info && info.index !== INVALID_ID_NUMBER;

    if (isFeature && !event?.rightButton) {
      const isShiftPressed = event?.srcEvent?.shiftKey;

      if (
        selectedFeatureIndexes.length > 1 &&
        selectedFeatureIndexes.includes(info.index)
      ) {
        if (isShiftPressed) {
          currentSelectedFeatureIndexes = selectedFeatureIndexes.filter(
            (index) => index !== info.index
          );
        } else {
          currentSelectedFeatureIndexes = [info.index];
        }
      } else if (
        (selectedFeatureIndexes.length === 1 &&
          selectedFeatureIndexes.includes(info.index)) ||
        selectedFeatureIndexes.length === 0 ||
        !selectedFeatureIndexes.includes(info.index)
      ) {
        const currentSelectedLaneIds = getLaneAssociations(info.object);

        if (currentSelectedLaneIds) {
          const laneFeatureIndexes = getFeatureIndexesByLaneIds(
            features,
            currentSelectedLaneIds
          );
          if (isShiftPressed) {
            currentSelectedFeatureIndexes = [
              ...currentSelectedFeatureIndexes,
              ...laneFeatureIndexes.filter(
                (index) => !currentSelectedFeatureIndexes.includes(index)
              ),
            ];
          } else {
            currentSelectedFeatureIndexes = laneFeatureIndexes;
          }
        } else {
          if (isShiftPressed) {
            currentSelectedFeatureIndexes = [
              ...currentSelectedFeatureIndexes,
              info.index,
            ];
          } else {
            currentSelectedFeatureIndexes = [info.index];
          }
        }
      }
    } else {
      currentSelectedFeatureIndexes = [];
    }

    if (event && event.rightButton) {
      contextMenuData = {
        position: info.pixel,
        coordinate: info.coordinate,
      };
      if (isFeature) {
        contextMenuData.currentBufferFeature = info.index;
      }
    }

    setContextMenuDataAction(contextMenuData);
    setSelectedFeatureIndexesAction(currentSelectedFeatureIndexes);
  };

  addToClipboard = (featureIndex: number) => {
    const { setContextMenuDataAction, mapFeatures } = this.props;
    let bufferFeature = null;

    if (featureIndex !== INVALID_ID_NUMBER) {
      const features = mapFeatures.features as GeoJSON.Feature<LineString>[];
      bufferFeature = featureIndex;
      toast.success(
        `The Feature ${
          features[featureIndex]?.properties?.feature_id || ""
        } is added to the clipboard`
      );
    }

    setContextMenuDataAction(null);
    this.setState({
      bufferFeature,
    });
  };

  pasteFromClipboard = (position: Array<number>) => {
    const { setContextMenuDataAction, setFeaturesAction, mapFeatures } =
      this.props;
    const { bufferFeature } = this.state;

    if (bufferFeature === null) return;

    const features = mapFeatures.features as GeoJSON.Feature<
      LineString | Polygon
    >[];
    const newFeature = copyPasteFeature(
      features[bufferFeature],
      position
    ) as GeoJSON.Feature<LineString | Polygon>;

    setContextMenuDataAction(null);
    setFeaturesAction({
      ...mapFeatures,
      features: [...features, newFeature],
    });
  };

  pasteAndReplace = (currentBufferFeature: number) => {
    const { setContextMenuDataAction, setFeaturesAction, mapFeatures } =
      this.props;
    const { bufferFeature } = this.state;

    if (bufferFeature === null) return;

    const features = mapFeatures.features as GeoJSON.Feature<
      LineString | Polygon
    >[];

    const topLeftCoordinates = getTopLeftPoint(features[currentBufferFeature]);

    if (!topLeftCoordinates) return;

    const newFeature = copyPasteFeature(
      features[bufferFeature],
      topLeftCoordinates
    ) as GeoJSON.Feature<LineString | Polygon>;

    features[currentBufferFeature] = newFeature;

    setContextMenuDataAction(null);
    setFeaturesAction({
      ...mapFeatures,
      features: [...features],
    });
  };

  deleteFeature = (featureIndex: number) => {
    const {
      setContextMenuDataAction,
      selectedFeatureIndexes,
      setSelectedFeatureIndexesAction,
      setFeaturesAction,
      mapFeatures,
    } = this.props;
    const features = mapFeatures.features as GeoJSON.Feature<
      LineString | Polygon
    >[];

    const updatedFeatures = features.filter(
      (_, index) => index !== featureIndex
    );

    setContextMenuDataAction(null);
    setSelectedFeatureIndexesAction([
      ...selectedFeatureIndexes.filter(
        (index: number) => index !== featureIndex
      ),
    ]);
    setFeaturesAction({
      ...mapFeatures,
      features: [...updatedFeatures],
    });
  };

  flipFeature = (featureIndex: number, direction: Direction) => {
    const { setContextMenuDataAction, setFeaturesAction, mapFeatures } =
      this.props;
    const features = mapFeatures.features as GeoJSON.Feature<
      LineString | Polygon
    >[];
    const currentFeature = features[featureIndex];

    if (currentFeature.geometry.type === "LineString") {
      const coordinates = currentFeature.geometry.coordinates as Position[];
      const flippedCoordinates = flipCoordinates(coordinates, direction);
      currentFeature.geometry.coordinates = flippedCoordinates;
    } else if (currentFeature.geometry.type === "Polygon") {
      const coordinates = currentFeature.geometry.coordinates[0] as Position[];
      const flippedCoordinates = flipCoordinates(coordinates, direction);
      currentFeature.geometry.coordinates[0] = flippedCoordinates;
    }

    setContextMenuDataAction(null);
    setFeaturesAction({
      ...mapFeatures,
      features: [...features],
    });
  };

  resize = () => {
    this.forceUpdate();
  };

  updateViewPortWithLatLong = (
    lat: number,
    long: number,
    bounding_box: [any, any] = [
      [-10, -10],
      [10, 10],
    ]
  ) => {
    const [mins, maxs] = bounding_box;
    const xyz_delta = [(mins[0] + maxs[0]) / 2, (mins[1] + maxs[1]) / 2];

    const [viewport_center_lon, viewport_center_lat] =
      new WebMercatorViewport().addMetersToLngLat([long, lat], xyz_delta);

    const viewport = {
      ...this.state.viewport,
      longitude: viewport_center_lon,
      latitude: viewport_center_lat,
    };
    this.setState({ viewport });
  };

  handleMouseDown = (e: any) => {
    if (e.button === 2) {
      e.preventDefault();
    }
  };

  adjustEditPlaneElevation = (diff: number) => {
    const editPlane = update(this.state.editPlane, {
      elevation: { $set: this.state.editPlane.elevation + diff },
    });
    this.setState(() => ({ editPlane }));
  };

  adjustEditPlaneRadius = (diff: number) => {
    const editPlane = update(this.state.editPlane, {
      radius: { $set: this.state.editPlane.radius + diff },
    });
    this.setState(() => ({ editPlane }));
  };

  setEditPlaneOpen = (open: boolean) => {
    const editPlane = update(this.state.editPlane, {
      renderEditPlane: { $set: open },
    });
    this.setState(() => ({ editPlane }));
  };

  onLaneFocus = (laneIds: Array<number>) => {
    const { setSelectedFeatureIndexesAction, mapFeatures } = this.props;
    const lanesRelatedFeaturesIndexes: Array<number> = [];

    laneIds.forEach((laneId: number) => {
      const { mapStructs } = this.props;
      const laneInfo = mapStructs.lanes[laneId];
      const { controlLines, stopSigns } = mapStructs;
      const controlLineItem = Object.values(controlLines).find(
        (controlLine) => controlLine.associated_lane_id === laneId
      );
      const stopSignItem = Object.values(stopSigns).find(
        (stopSign) => stopSign.associated_lane_id === laneId
      );
      const laneRelatedFeatures = getLaneFeatures(
        mapFeatures.features,
        laneInfo,
        controlLineItem?.control_line_id || undefined,
        stopSignItem?.stop_sign_id || undefined
      );
      lanesRelatedFeaturesIndexes.push(...laneRelatedFeatures);
    });

    setSelectedFeatureIndexesAction(
      lanesRelatedFeaturesIndexes.filter(
        (value, index, array) => array.indexOf(value) === index
      )
    );
  };

  onStopSignFocus = (stop_sign_id: number) => {
    const { mapFeatures } = this.props;
    const { features } = mapFeatures;
    const {
      mapStructs,
      setSelectedFeatureIndexesAction,
      selectedFeatureIndexes,
    } = this.props;
    // highlight the stop sign polygon and associated control line
    let selectedStopSignsIndexes: number[] = [];
    selectedStopSignsIndexes = selectedStopSignsIndexes.concat(
      featurePropIdtoIndex(
        "Polygon",
        MapConst.STOP_SIGN_ID_STRING_NAME,
        stop_sign_id,
        features
      )
    );

    const associated_control_line_id: number | undefined =
      mapStructs.stopSigns[stop_sign_id]?.associated_control_line_id;

    if (associated_control_line_id) {
      selectedStopSignsIndexes = selectedStopSignsIndexes.concat(
        featurePropIdtoIndex(
          "LineString",
          MapConst.CONTROL_LINE_ID_STRING_NAME,
          associated_control_line_id,
          features
        )
      );
    }
    setSelectedFeatureIndexesAction([
      ...selectedFeatureIndexes,
      ...selectedStopSignsIndexes,
    ]);
  };

  onEdit = ({
    updatedData,
    editType,
    editContext,
  }: {
    updatedData: FeaturesInterface;
    editType: string;
    editContext: any;
  }) => {
    const {
      lockedFeatures,
      mode,
      selectedFeatureIndexes,
      setSelectedFeatureIndexesAction,
      addUndoFeatureAction,
      setFeaturesAction,
      mapFeatures,
    } = this.props;
    let updatedSelectedFeatureIndexes = [...selectedFeatureIndexes];
    const { measureFeatures, editableGeoJsonLayer } = this.state;
    let newUpdatedData = cloneDeep(updatedData);
    const currentMeasureFeatures = { ...measureFeatures };

    if (editContext.featureIndexes) {
      const filteredFeatureIndexes = editContext.featureIndexes.filter(
        (featureIndex: number) => !lockedFeatures.includes(featureIndex)
      );

      if (filteredFeatureIndexes.length === 0) {
        return;
      }

      editContext.featureIndexes = filteredFeatureIndexes;
    }

    if (
      (mode === "MeasureDistanceMode" || mode === "MeasureAngleMode") &&
      editType === "addTentativePosition"
    ) {
      const { mode } = editableGeoJsonLayer.state;
      setSelectedFeatureIndexesAction(updatedSelectedFeatureIndexes);
      this.setState({
        measureFeatures: updateMeasureFeatures(currentMeasureFeatures, mode),
      });
      return;
    }

    if (editType === "removePosition" && !this.state.pointsRemovable) {
      // reject the edit
      return;
    }

    if (editType === "addFeature") {
      const { featureIndexes } = editContext;

      // Add the new feature to the selection
      updatedSelectedFeatureIndexes = [
        ...selectedFeatureIndexes,
        ...featureIndexes,
      ];
      if (!isShiftPressed) {
        newUpdatedData = snapFeature(featureIndexes, newUpdatedData);
      }
    }

    if (editType === "addFeature" && this.props.mode === "DuplicateMode") {
      updatedSelectedFeatureIndexes.forEach((index) => {
        const currentItem = { ...newUpdatedData.features[index] };
        currentItem.properties = {
          feature_id: generateUUID(),
          feature_info_list: [],
        };
        newUpdatedData.features[index] = currentItem;
      });
    }

    if (editType === "tangentLine") {
      try {
        updatedSelectedFeatureIndexes = tangentLineConstraint(
          mapFeatures.features,
          selectedFeatureIndexes,
          editContext
        );
      } catch (error: any) {
        toast.error(error.message);
        return;
      }
    }
    const newTestFeatures =
      getGeoJsonDataWithoutMeasureFeatures(newUpdatedData);
    if (["removePosition", "addFeature", "addPosition"].includes(editType)) {
      addUndoFeatureAction({ ...newTestFeatures });
    }

    setSelectedFeatureIndexesAction(updatedSelectedFeatureIndexes);
    setFeaturesAction(newTestFeatures);
    this.setState({
      editContext: editContext,
      radiusDialog: !!editContext?.radiusDialog,
      measureFeatures: currentMeasureFeatures,
    });
  };

  getLineColor = (
    feature: Feature<Geometry, GeoJsonProperties>,
    isSelected: any
  ): RGBAColor => {
    const { mapFeatures } = this.props;
    const { highlightedFeatureIndexes } = this.state;
    const index = mapFeatures.features?.indexOf(feature);

    if (
      feature.properties?.feature_info_list?.some(
        (info: FeatureInfo) => info.polygon_type === STOP_SIGN
      )
    ) {
      return TRANSPARENT_COLOR;
    }

    if (feature.properties?.type === "measure") {
      return [108, 117, 125, 255];
    }

    if (highlightedFeatureIndexes?.includes(index)) {
      return [0, 0, 255, 128];
    }

    // Default color for other features
    return isSelected
      ? getDeckColorForFeature(index, 1.0, 1.0)
      : getDeckColorForFeature(index, 0.5, 1.0);
  };

  updateModeConfig = (tempProps: any) => {
    const { modeConfig, setModeConfigAction } = this.props;

    setModeConfigAction({
      ...(modeConfig || {}),
      ...tempProps,
    });
  };

  updatePointsRemovable = () => {
    this.setState({ pointsRemovable: !this.state.pointsRemovable });
  };

  deleteFeatureProperty = (featureIndex: number, featureInfoIndex: number) => {
    const {
      mapStructs,
      removeIntersectionAction,
      setLaneAction,
      setFeaturesAction,
      mapFeatures,
    } = this.props;
    const { lanes } = mapStructs;
    const featureInfoProperties:
      | Array<LineInterface>
      | Array<PolygonInterface> =
      mapFeatures.features[featureIndex].properties?.feature_info_list;

    if (!featureInfoProperties) return;

    const removedItem = featureInfoProperties[featureInfoIndex];

    if (
      "polygon_type" in removedItem &&
      removedItem.polygon_type === "intersection"
    ) {
      removeIntersectionAction(removedItem.intersection_id);
    }

    const currentLaneId =
      featureInfoProperties[featureInfoIndex].lane_association;

    if (currentLaneId !== "NULL") {
      const currentLane = { ...lanes[currentLaneId] };

      if (
        currentLane &&
        Object.keys(currentLane).length > 0 &&
        "line_type" in removedItem
      ) {
        const currentLineType = removedItem.line_type;
        if (currentLineType === "lane_left_boundary_line") {
          currentLane.left_boundary_line_id = MapConst.INVALID_ID_NUMBER;
        }
        if (currentLineType === "lane_right_boundary_line") {
          currentLane.right_boundary_line_id = MapConst.INVALID_ID_NUMBER;
        }
        if (currentLineType === "lane_start_line") {
          currentLane.start_line_id = MapConst.INVALID_ID_NUMBER;
        }
        if (currentLineType === "lane_termination_line") {
          currentLane.termination_line_id = MapConst.INVALID_ID_NUMBER;
        }

        setLaneAction(currentLane);
      }
    }

    const filteredInfoProperties = featureInfoProperties.filter(
      (featureInfo: GeoJsonProperties, index: number) =>
        index !== featureInfoIndex && featureInfo
    );

    const updatedFeatures = update(mapFeatures, {
      features: {
        [featureIndex]: {
          properties: {
            feature_info_list: { $set: filteredInfoProperties },
          },
        },
      },
    });
    setFeaturesAction(updatedFeatures);
  };

  removeNullFeatureProperty = (selectedFeatures: Array<number>) => {
    const { setFeaturesAction, mapFeatures } = this.props;
    const { features } = mapFeatures;
    const fileredPropertyFeatures = features.map((feature, index) => {
      if (selectedFeatures.includes(index)) {
        return { ...feature };
      } else {
        const filteredFeatureInfoProperties: Feature<
          Geometry,
          GeoJsonProperties
        >[] = feature.properties?.feature_info_list?.filter(
          (featureInfo: GeoJsonProperties) =>
            (featureInfo?.control_line_id !== "NULL" &&
              featureInfo?.lane_association !== "NULL") ||
            (featureInfo?.polygon_type !== "NULL" &&
              featureInfo?.lane_association !== "NULL")
        );

        return {
          ...feature,
          properties: {
            ...feature.properties,
            feature_info_list: filteredFeatureInfoProperties,
          },
        };
      }
    });
    setFeaturesAction({
      ...this.props.mapFeatures,
      features: fileredPropertyFeatures,
    });
  };

  updateSelectedFeatureIndexes = (selected: Array<number>, menu?: any) => {
    this.removeNullFeatureProperty(selected);
    const { setSelectedFeatureIndexesAction } = this.props;
    this.setState({
      featureMenu:
        menu && menu.index !== this.state.featureMenu?.index ? menu : null,
    });
    setSelectedFeatureIndexesAction(selected);
  };

  updateFeatureMenu = (
    mapFeatures: any,
    show_dialog: boolean,
    selectedFeatureIndexes: number[]
  ) => {
    const { setSelectedFeatureIndexesAction, setFeaturesAction } = this.props;
    this.setState({
      featureMenu: undefined,
      showDialog: show_dialog,
    });
    setSelectedFeatureIndexesAction(selectedFeatureIndexes);
    setFeaturesAction(mapFeatures);
  };

  updateDialog = (showDialog: boolean) => {
    this.setState({ showDialog });
  };

  updateLayers = () => {
    const { currentMapData } = this.props;
    const {
      editPlane,
      viewport,
      pointCloudData,
      editableGeoJsonLayerVisible,
      editableGeoJsonLayer,
      editPlaneLayerVisible,
      layerVisibility,
    } = this.state;

    const pointCloudLayers = createPointCloudLayers(
      pointCloudData,
      currentMapData,
      layerVisibility
    );

    const visiblePointCloudLayers = [...pointCloudLayers].filter((layer) => {
      return layerVisibility[layer.id];
    });

    const layers = [
      ...(editableGeoJsonLayerVisible ? [editableGeoJsonLayer] : []),
      ...visiblePointCloudLayers,
      this.getIconLayer(),
      ...(editPlaneLayerVisible
        ? [
            edit_plane_layer(
              [viewport.longitude, viewport.latitude],
              editPlane.elevation,
              editPlane.radius,
              editPlane.color,
              editPlane.renderEditPlane
            ),
          ]
        : []),
    ];

    this.currentPointCloudLayers = pointCloudLayers;

    this.setState({
      layers,
      editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
    });
  };

  updateRadius = (radiusDialog: boolean, radius?: number) => {
    const { addUndoFeatureAction, setFeaturesAction, mapFeatures } = this.props;
    const { editContext } = this.state;
    const index: number = editContext?.featureIndex;
    const position = editContext?.positionIndexes?.[0];
    const features = [...mapFeatures.features];
    const feature = cloneDeep(features?.[index]);
    const length = (feature?.geometry as LineString)?.coordinates?.length || 0;
    const vertex = position > length && editContext?.position;

    if (radius) {
      if (feature?.properties?.radius) {
        (feature.properties.radius as Map<number, number>).set(
          position,
          radius
        );
      } else {
        feature.properties = {
          radius: new Map([[position, radius]]),
        };
      }

      try {
        (feature.geometry as LineString).coordinates = calculateCurve(
          feature.geometry as LineString,
          radius,
          position,
          vertex
        );
      } catch (error: any) {
        toast.error(error.message);
      }
      features[index] = feature;
    }
    const currentFeature = {
      ...mapFeatures,
      features,
    };
    addUndoFeatureAction({ ...currentFeature });
    setFeaturesAction(currentFeature);
    this.setState(() => ({
      radiusDialog,
    }));
  };

  isSemanticDataPresent = (state: {
    currentMap: { semanticMapGeoJson: null };
  }) => state.currentMap.semanticMapGeoJson !== null;

  isCenterlineDataPresent = (state: {
    currentMap: { centerlineGeoJson: null };
  }) => state.currentMap.centerlineGeoJson !== null;

  createPointCloudLayers = (pointCloudData: { COORDINATES: number[] }[]) => {
    const { currentMapData } = this.props;
    const groupedPoints = groupPointsByColor(pointCloudData);

    // create separate PointCloudLayers for each color group
    const pointCloudLayers: any[] = [];

    Object.entries(groupedPoints).forEach(([color, points], index) => {
      const rgbColor = Utils.hex2rgb(color).map((c) => c * 255) as RGBAColor;
      const layerId = `point_cloud_layer_${index}`;

      if (points && (points as Array<number>).length !== 0) {
        pointCloudLayers.push(
          new PointCloudLayer({
            id: layerId,
            data: points,
            coordinateSystem: COORDINATE_SYSTEM.LNGLAT_OFFSETS,
            coordinateOrigin: [
              currentMapData?.longitude,
              currentMapData?.latitude,
            ],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getPosition: (d: unknown) => d.COORDINATES as Position,
            pointSize: 2,
            opacity: 1.0,
            getColor: () => rgbColor,
            visible: this.state.layerVisibility[layerId] || false,
          })
        );
      }
    });

    return pointCloudLayers;
  };

  toggleLayerVisibility = (id: string) => {
    this.setState((prevState) => {
      const newLayerVisibility = {
        ...prevState.layerVisibility,
        [id]: !prevState.layerVisibility[id],
      };

      if (newLayerVisibility[id] !== prevState.layerVisibility[id]) {
        return { layerVisibility: newLayerVisibility };
      } else {
        return null;
      }
    }, this.updateLayers);
  };

  toggleEditableGeoJsonLayerVisibility = () => {
    this.setState(
      (prevState) => ({
        editableGeoJsonLayerVisible: !prevState.editableGeoJsonLayerVisible,
      }),
      this.updateLayers
    );
  };

  toggleEditPlaneLayerVisibility = () => {
    this.setState((prevState) => {
      return { editPlaneLayerVisible: !prevState.editPlaneLayerVisible };
    }, this.updateLayers);
  };

  featureMenuClick = (
    action: string,
    index: number | undefined = this.state.featureMenu?.index
  ) => {
    const { selectedFeatureIndexes, addUndoFeatureAction } = this.props;
    let { mapFeatures } = this.props;
    let currentSelectedFeatureIndexes = [...selectedFeatureIndexes];

    let show_dialog = !!index;
    if (index !== undefined && action === "delete") {
      if (
        !isFeatureInfoListNull(
          mapFeatures.features[index].properties?.feature_info_list
        )
      ) {
        alert("Please make all LineType / PolygonType NULL before deleting");
      } else {
        const features = [...mapFeatures.features];
        const selectedIndex = currentSelectedFeatureIndexes.indexOf(index);

        features.splice(index, 1);

        if (selectedIndex > -1) {
          if (currentSelectedFeatureIndexes.length === 1) {
            currentSelectedFeatureIndexes = [];
          } else {
            currentSelectedFeatureIndexes.splice(selectedIndex, 1);
          }
        }

        if (currentSelectedFeatureIndexes.length === 0) {
          show_dialog = false;
        }

        mapFeatures = Object.assign({}, mapFeatures, {
          features,
        });
      }
    } else if (action === "info") {
      show_dialog = true;
    } else if (action === "close") {
      show_dialog = false;
    }

    addUndoFeatureAction({ ...mapFeatures });
    this.updateFeatureMenu(
      mapFeatures,
      show_dialog,
      currentSelectedFeatureIndexes
    );
  };

  generateLayers = () => {
    const { editPlane, viewport, editableGeoJsonLayer, measureFeatures } =
      this.state;
    const pointCloudLayers = this.currentPointCloudLayers;

    const tooltipsLayer = new TextLayer({
      id: "tooltips",
      data: getMeasureTooltips(measureFeatures),
      getSize: TOOLTIP_FONT_SIZE,
      getColor: TOOLTIP_FONT_COLOR as RGBAColor,
    });

    const layers = [
      ...pointCloudLayers,
      tooltipsLayer,
      edit_plane_layer(
        [viewport.longitude, viewport.latitude],
        editPlane.elevation,
        editPlane.radius,
        editPlane.color,
        editPlane.renderEditPlane
      ),
    ];

    if (editableGeoJsonLayer) {
      return [...layers, editableGeoJsonLayer, this.getIconLayer()];
    }

    return layers;
  };

  getCurrentEditableGeoJsonLayer = () => {
    const {
      editHandleType,
      editPlane,
      editableGeoJsonLayerVisible,
      measureFeatures,
    } = this.state;
    const { mode, modeConfig, selectedFeatureIndexes, mapFeatures } =
      this.props;

    const tooltips = {
      getColor: TOOLTIP_FONT_COLOR,
    };
    const ModeClass = modeMap[mode] || GeoJsonEditMode;

    const editableGeoJsonLayer = new (EditableGeoJsonLayer as any)({
      ...MapConst.defaultGeoJsonLayerProps,
      data: {
        ...mapFeatures,
        features: [...mapFeatures.features, ...measureFeatures.features],
      },
      selectedFeatureIndexes,
      mode: ModeClass,
      modeConfig,
      onEdit: this.onEdit,
      editHandleType,
      getEditHandleIcon: (d: any) => getEditHandleTypeFromEitherLayer(d),
      getEditHandleIconColor: getEditHandleColor,
      getLineColor: (feature: GeoJSON.Feature, isSelected: boolean) => {
        const isStopSign = feature?.properties?.feature_info_list?.some(
          (info: FeatureInfo) => info.polygon_type === STOP_SIGN
        );
        return isStopSign
          ? TRANSPARENT_COLOR
          : this.getLineColor(feature, isSelected);
      },
      getFillColor: (feature: GeoJSON.Feature) => {
        const isStopSign = feature?.properties?.feature_info_list?.some(
          (info: FeatureInfo) => info.polygon_type === STOP_SIGN
        );
        return isStopSign ? TRANSPARENT_COLOR : undefined;
      },

      getEditHandlePointColor: getEditHandleColor,
      editElevation: editPlane.elevation,
      visible: editableGeoJsonLayerVisible,
      onHover: this.onHoverGeoJsonLayer,
      _subLayerProps: {
        tooltips,
      },
    });

    return editableGeoJsonLayer;
  };

  getIconLayer = () => {
    const { mapFeatures } = this.props;
    const { viewport } = this.state;
    const { zoom } = viewport;

    const stopSignFeatures = mapFeatures.features.filter(
      (feature: GeoJSON.Feature) =>
        feature?.properties?.feature_info_list?.some(
          (info: FeatureInfo) => info.polygon_type === STOP_SIGN
        )
    );

    const stopSignIconLayer = new IconLayer({
      id: "stop-sign-icons",
      data: stopSignFeatures,
      pickable: true,
      iconAtlas: StopSignIcon,
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: STOP_SIGN_ICON_WIDTH,
          height: STOP_SIGN_ICON_HEIGHT,
        },
      },
      getIcon: () => "marker",
      sizeScale: 1,
      getPosition: (feature: unknown): Position => {
        const currentFeature = feature as GeoJSON.Feature;
        if (currentFeature.geometry.type === "Polygon") {
          const coordinates = currentFeature.geometry.coordinates[0];
          let centroidX = 0;
          let centroidY = 0;
          coordinates.forEach(([x, y]) => {
            centroidX += x;
            centroidY += y;
          });
          centroidX /= coordinates.length;
          centroidY /= coordinates.length;

          return [
            centroidX + STOP_SIGN_OFFSET_X,
            centroidY + STOP_SIGN_OFFSET_Y,
          ];
        }
        return [0, 0];
      },
      onClick: this.onIconLayerClick,
      getSize: () => {
        const baseSize = 1.4;
        const scaleFactor = 1.15;
        const size = baseSize * Math.pow(scaleFactor, zoom);

        return size;
      },
      visible: true,
    });

    return stopSignIconLayer;
  };

  onHoverGeoJsonLayer = ({
    coordinate,
    index,
    x,
    y,
    object,
  }: {
    coordinate: [number, number];
    object: FeaturesInterface;
    index: number;
    x: number;
    y: number;
  }) => {
    const { mapFeatures, mode } = this.props;
    const { features } = mapFeatures;

    if (index === -1 || !object) {
      this.setState({ tooltipData: null, highlightedFeatureIndexes: null });
      return;
    }

    if (
      mode === "DrawStopMode" &&
      features[index].geometry?.type === "LineString"
    ) {
      const tooltipData = {
        x,
        y,
        tooltipText: `Coordinates: [${coordinate[0].toFixed(
          6
        )}, ${coordinate[1].toFixed(6)}]`,
      };
      this.setState({
        tooltipData,
      });
    } else {
      this.setState({ tooltipData: null });
    }

    const laneAssociations = getLaneAssociations(object);

    if (laneAssociations) {
      this.setState({
        highlightedFeatureIndexes: getFeatureIndexesByLaneIds(
          features,
          laneAssociations
        ),
      });
      return;
    }

    this.setState({ highlightedFeatureIndexes: [index] });
  };

  removeMeasureElements = (id: number) => {
    const { measureFeatures } = this.state;
    const { setModeAction } = this.props;
    const { features } = measureFeatures;

    setModeAction("GeoJsonEditMode");

    this.setState({
      measureFeatures: {
        ...measureFeatures,
        features: features.filter((feature) => feature?.properties?.id !== id),
      },
    });
  };

  handleWheel = (event: any) => {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    const isZoomAllowed = isMac ? event.metaKey : event.ctrlKey;

    const { viewport } = this.state;
    if (isZoomAllowed) {
      event.preventDefault();
      this.setState({
        viewport: {
          ...viewport,
          zoom: viewport.zoom - event.deltaY * ZOOM_DELTA,
        },
      });
    } else if (event.shiftKey) {
      this.setState({
        viewport: {
          ...viewport,
          longitude: viewport.longitude - event.deltaX * HANDLE_WHEEL_DELTA,
        },
      });
    } else {
      this.setState({
        viewport: {
          ...viewport,
          latitude: viewport.latitude - event.deltaY * HANDLE_WHEEL_DELTA,
        },
      });
    }
  };

  render() {
    const {
      currentMapData,
      mapStructs,
      contextMenuData,
      mapFeatures,
      selectedLaneIds,
      mode,
    } = this.props;
    const {
      viewport,
      layerVisibility,
      editableGeoJsonLayerVisible,
      bufferFeature,
      editContext,
      tooltipData,
      featureMenu,
    } = this.state;

    const { geojsonType } = currentMapData;

    const layers = this.generateLayers();

    const isShowGeoJsonSelectTypeModal =
      this.currentPointCloudLayers.length !== 0 && !geojsonType;

    return (
      <div>
        {tooltipData && <Tooltip {...tooltipData} />}
        {contextMenuData && (
          <ContextMenu
            toggleLayerVisibility={this.toggleLayerVisibility}
            layerVisibility={layerVisibility}
            editableGeoJsonLayerVisible={editableGeoJsonLayerVisible}
            toggleEditableGeoJsonLayerVisibility={
              this.toggleEditableGeoJsonLayerVisibility
            }
            addToClipboard={this.addToClipboard}
            bufferFeature={bufferFeature}
            pasteFromClipboard={this.pasteFromClipboard}
            pasteAndReplace={this.pasteAndReplace}
            deleteFeature={this.deleteFeature}
            flipFeature={this.flipFeature}
          />
        )}
        {this.currentPointCloudLayers.length === 0 && <LoaderSpinner />}
        {isShowGeoJsonSelectTypeModal && <GeoJsonSelectTypeModal />}
        <MapContainerStyled
          onMouseDown={this.handleMouseDown}
          onContextMenu={this.handleMouseDown}
        >
          <link
            href="https://api.mapbox.com/mapbox-gl-js/v0.44.0/mapbox-gl.css"
            rel="stylesheet"
          />
          <div ref={this.containerRef}>
            {currentMapData.pointCloudJson && (
              <DeckGL
                viewState={viewport}
                getCursor={() =>
                  mode !== "GeoJsonEditMode" ? "pointer" : "grab"
                }
                layers={layers}
                height="100%"
                width="100%"
                views={[
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  new MapView({
                    id: "basemap",
                    controller: {
                      type: MapController,
                      scrollZoom: false,
                      doubleClickZoom: false,
                    },
                  }),
                ]}
                onClick={this.onLayerClick}
                onViewStateChange={({ viewState }) => {
                  this.setState({ viewport: viewState });
                }}
              >
                <InteractiveMap
                  {...viewport}
                  mapboxApiAccessToken="pk.eyJ1IjoieWlhbm5pLXZlcnZlcmlzIiwiYSI6ImNrcWF0azdnejBjdm4yd3M3ajBmb2hpeGkifQ.rl7QWaaMtqRYNJ-vMIMoOA"
                  mapStyle={"mapbox://styles/mapbox/dark-v10"}
                  width="100%"
                  height="100%"
                />
              </DeckGL>
            )}
          </div>
          <MapToolbox
            removeMeasureElements={this.removeMeasureElements}
            measureFeatures={this.state.measureFeatures}
            deleteFeatureProperty={this.deleteFeatureProperty}
            featureMenuClick={this.featureMenuClick}
            updateModeConfig={this.updateModeConfig}
            updatePointsRemovable={this.updatePointsRemovable}
            updateSelectedFeatureIndexes={this.updateSelectedFeatureIndexes}
            toggleEditPlaneLayerVisibility={this.toggleEditPlaneLayerVisibility}
            isSemanticDataPresent={this.isSemanticDataPresent}
            isCenterlineDataPresent={this.isCenterlineDataPresent}
            pointsRemovable={this.state.pointsRemovable}
            viewport={viewport}
          />
          {featureMenu && (
            <FeatureMenu
              featureMenu={featureMenu}
              featureMenuClick={this.featureMenuClick}
            />
          )}
        </MapContainerStyled>
        <div>
          <FeatureDialog
            deleteFeatureProperty={this.deleteFeatureProperty}
            showDialog={this.state.showDialog}
            lane={mapStructs.lanes[this.state.selectedLaneId]}
            featureMenuClick={this.featureMenuClick}
            updateDialog={this.updateDialog}
            updateSelectedFeatureIndexes={this.updateSelectedFeatureIndexes}
          />
          <RadiusDialog
            isError={getIsError(mapFeatures, editContext)}
            radius={getRadius(mapFeatures, editContext)}
            radiusDialog={this.state.radiusDialog}
            updateRadius={this.updateRadius}
          />
        </div>
        <div>
          <SideMenu
            renderEditPlane={this.state.editPlane.renderEditPlane}
            adjustEditPlaneElevation={this.adjustEditPlaneElevation}
            adjustEditPlaneRadius={this.adjustEditPlaneRadius}
            setEditPlaneOpen={this.setEditPlaneOpen}
            onLaneFocus={this.onLaneFocus}
            onStopSignFocus={this.onStopSignFocus}
            selectedLaneIds={selectedLaneIds}
          ></SideMenu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentMapData: getCurrentMap(state),
  isLoading: getIsLoading(state),
  saveSemanticMapFlag: getSaveSemanticMapFlag(state),
  mapStructs: getMapStructs(state),
  contextMenuData: getContextMenuData(state),
  lockedFeatures: getLockedFeatures(state),
  mode: getMode(state),
  modeConfig: getModeConfig(state),
  selectedFeatureIndexes: getSelectedFeatureIndexes(state),
  deleteSelectedItems: getDeleteSelectedItems(state),
  undoFeatures: getUndoFeatures(state),
  undoStep: getUndoStep(state),
  mapFeatures: getFeatures(state),
  selectedLaneIds: getLanesBySelectedFeatures(state),
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveMapGeoJsonAction: (params: any) =>
      dispatch(fetchPatchMapGeoJson(params)),
    getMapPbBinFile: (params: any) => dispatch(fetchPatchMapPbBinFile(params)),
    getCurrentMapDataAction: () => dispatch(fetchGetCurrentMapData()),
    setGeojsonTypeAction: (params: any) => dispatch(setGeojsonType(params)),
    toggleModalAction: (params: any) => dispatch(toggleModal(params)),
    setLaneAction: (params: any) => dispatch(setLane(params)),
    setContextMenuDataAction: (params: any) =>
      dispatch(setContextMenuData(params)),
    removeIntersectionAction: (params: any) =>
      dispatch(removeIntersection(params)),
    setModeAction: (params: string) => dispatch(setMode(params)),
    setSelectedFeatureIndexesAction: (params: Array<number>) =>
      dispatch(setSelectedFeatureIndexes(params)),
    setModeConfigAction: (params: any) => dispatch(setModeConfig(params)),
    resetCurrentMapAction: () => dispatch(resetCurrentMap()),
    setModeWithConfigAction: (params: { mode: string; modeConfig: any }) =>
      dispatch(setModeWithConfig(params)),
    setDeleteSelectedItemsAction: (params: any) =>
      dispatch(setDeleteSelectedItems(params)),
    addUndoFeatureAction: (params: any) => dispatch(addUndoFeature(params)),
    setFeaturesAction: (params: any) => dispatch(setFeatures(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapEditorComponent);
