import { AvailableIds, Lane, MapStructs } from "../../../models/map-interface";
import { RootState } from "../../index";

const isIdAvailable = (lineId: number) => !lineId || (lineId && lineId === -1);

export const getMapStructs = (state: RootState): MapStructs => state.mapStructs;
export const getLanes = (state: RootState): Record<string, Lane> =>
  state.mapStructs.lanes;
export const getOutputMapStructs = (state: RootState): any => {
  return {
    lanes: {
      dataType: "Map",
      value: [
        ...Object.values(state.mapStructs.lanes).map(
          ({
            lane_id,
            speed_limit,
            left_boundary_line_id,
            right_boundary_line_id,
            start_line_id,
            termination_line_id,
          }) => [
            lane_id,
            {
              lane_id,
              speed_limit: speed_limit ? Number(speed_limit) : undefined,
              left_boundary_line_id: left_boundary_line_id
                ? Number(left_boundary_line_id)
                : undefined,
              right_boundary_line_id: right_boundary_line_id
                ? Number(right_boundary_line_id)
                : undefined,
              start_line_id: start_line_id ? Number(start_line_id) : undefined,
              termination_line_id: termination_line_id
                ? Number(termination_line_id)
                : undefined,
            },
          ]
        ),
      ],
    },
    control_lines: {
      dataType: "Map",
      value: [
        ...Object.values(state.mapStructs.controlLines).map(
          ({
            associated_lane_id,
            associated_stop_sign_id,
            control_line_id,
          }) => [
            control_line_id,
            {
              associated_lane_id: associated_lane_id
                ? Number(associated_lane_id)
                : undefined,
              associated_stop_sign_id: associated_stop_sign_id
                ? Number(associated_stop_sign_id)
                : undefined,
              control_line_id: control_line_id
                ? Number(control_line_id)
                : undefined,
            },
          ]
        ),
      ],
    },
    intersections: {
      dataType: "Map",
      value: [
        ...Object.values(state.mapStructs.intersections).map(
          ({ intersection_id, associated_lane_ids }) => [
            intersection_id,
            {
              associated_lane_ids: associated_lane_ids
                ? associated_lane_ids
                : [],
              intersection_id: intersection_id
                ? Number(intersection_id)
                : undefined,
            },
          ]
        ),
      ],
    },
    stop_signs: {
      dataType: "Map",
      value: [
        ...Object.values(state.mapStructs.stopSigns).map(
          ({
            stop_sign_id,
            associated_lane_id,
            associated_control_line_id,
          }) => [
            stop_sign_id,
            {
              stop_sign_id: stop_sign_id ? Number(stop_sign_id) : undefined,
              associated_lane_id: associated_lane_id
                ? Number(associated_lane_id)
                : undefined,
              associated_control_line_id: associated_control_line_id
                ? Number(associated_control_line_id)
                : undefined,
            },
          ]
        ),
      ],
    },
  };
};

export const getAvailableIds = (state: RootState): AvailableIds => {
  const { lanes, stopSigns, controlLines } = state.mapStructs;
  const currentAvailable = {
    laneIdsForLeftLine: new Set<number>(),
    laneIdsForRightLine: new Set<number>(),
    laneIdsForStartLine: new Set<number>(),
    laneIdsForTerminationLine: new Set<number>(),
    laneIdsForControlLine: new Set<number>(),
    laneIdsForStopSign: new Set<number>(),
    laneIdsForIntersection: new Set<number>(),
  };

  Object.values(lanes).forEach(
    ({
      lane_id,
      left_boundary_line_id,
      right_boundary_line_id,
      start_line_id,
      termination_line_id,
    }) => {
      if (isIdAvailable(left_boundary_line_id)) {
        currentAvailable.laneIdsForLeftLine.add(lane_id);
      }
      if (isIdAvailable(right_boundary_line_id)) {
        currentAvailable.laneIdsForRightLine.add(lane_id);
      }
      if (isIdAvailable(start_line_id)) {
        currentAvailable.laneIdsForStartLine.add(lane_id);
      }
      if (isIdAvailable(termination_line_id)) {
        currentAvailable.laneIdsForTerminationLine.add(lane_id);
      }
    }
  );

  const stopSignIds: Array<number> = [];
  Object.values(lanes).forEach(({ lane_id }) => {
    let isAvailable = true;
    Object.values(stopSigns).forEach(({ associated_lane_id }) => {
      if (associated_lane_id === lane_id) {
        isAvailable = false;
      }
    });
    if (isAvailable) {
      stopSignIds.push(lane_id);
    }
  });
  currentAvailable.laneIdsForStopSign = new Set(stopSignIds);

  const controlLineIds: Array<number> = [];
  Object.values(lanes).forEach(({ lane_id }) => {
    let isAvailable = true;
    Object.values(controlLines).forEach(({ associated_lane_id }) => {
      if (associated_lane_id === lane_id) {
        isAvailable = false;
      }
    });
    if (isAvailable) {
      controlLineIds.push(lane_id);
    }
  });

  currentAvailable.laneIdsForControlLine = new Set(controlLineIds);
  currentAvailable.laneIdsForIntersection = new Set(
    Object.keys(lanes).map((id) => Number(id))
  );

  return { ...currentAvailable };
};
