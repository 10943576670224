import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API from "../../../api";
import { setIsLoading } from "../appState";
import {
  getCurrentMapId,
  getFeatures,
  getOutput,
  setContextMenuData,
  setCurrentMapData,
  setLockedFeatures,
  setUndoFeatures,
} from ".";
import { fetchPatchMapGeoJson } from "../projects";
import { RootState } from "../..";

export const fetchGetCurrentMapData = createAsyncThunk(
  "currentMap/fetchGetCurrentMapData",
  async (_, thunkAPI) => {
    try {
      const response = await API.get(
        `map-projects/${window.location.pathname.split("/")[2]}`
      );
      const { _id, latitude, longitude, radius } = response.data;
      thunkAPI.dispatch(
        setCurrentMapData({
          _id,
          latitude,
          longitude,
          radius,
        })
      );
      thunkAPI.dispatch(setIsLoading(false));
      return response.data;
    } catch (error) {
      const baseError = error as BaseError;
      toast.error(baseError.response.data.detail);
      return Promise.reject();
    }
  }
);

export const setLockedFeaturesAction = createAsyncThunk(
  "currentMap/setLockedFeaturesAction",
  async (featureId: number, { getState, dispatch }) => {
    const state = getState() as { currentMap: CurrentMap };
    const { lockedFeatures } = state.currentMap;
    let updatedLockedFeatures: number[];

    if (lockedFeatures.includes(featureId)) {
      updatedLockedFeatures = lockedFeatures.filter((id) => id !== featureId);
    } else {
      updatedLockedFeatures = [...lockedFeatures, featureId];
    }

    dispatch(setLockedFeatures(updatedLockedFeatures));
    dispatch(setContextMenuData(null));

    return updatedLockedFeatures;
  }
);

export const saveGeoJsonAction = createAsyncThunk(
  "currentMap/saveGeoJsonAction",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    const output = getOutput(state);
    const currentMapProjectId = getCurrentMapId(state);
    const mapFeatures = getFeatures(state);

    const file = new Blob([JSON.stringify(output)], { type: "octet/stream" });

    await dispatch(
      fetchPatchMapGeoJson({
        file,
        projectId: currentMapProjectId,
      })
    );
    dispatch(setUndoFeatures([mapFeatures]));

    return { success: true };
  }
);
