import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
  getCurrentProjectName,
  getUndoFeatures,
} from "../../../../store/slices/currentMap";

import {
  CurrentMapInfoStyled,
  CurrentMapSaveStatusStyled,
  CurrentMapNameStyled,
} from "./styles";

export const CurrentMapInfo = memo(() => {
  const currentProjectName = useSelector(getCurrentProjectName);
  const undoFeatures = useSelector(getUndoFeatures);

  return (
    <CurrentMapInfoStyled>
      <CurrentMapSaveStatusStyled>
        {undoFeatures.length === 1 ? "Saved" : "Unsaved"}
      </CurrentMapSaveStatusStyled>
      /<CurrentMapNameStyled>{currentProjectName}</CurrentMapNameStyled>
    </CurrentMapInfoStyled>
  );
});
