import React, { memo, FC } from "react";
import { useSelector } from "react-redux";

import { getCurrentUserImage } from "../../../store/slices/user";

import { ReactComponent as UserAvatarStub } from "./assets/userAvatarStub.svg";
import { UserAvatarComponentStyled } from "./styles";

type UserAvatarProps = {
  image?: UserImage | null;
};

export const UserAvatarComponent: FC<UserAvatarProps> = memo(({ image }) => {
  const currentUserImage = useSelector(getCurrentUserImage);
  const imageLink =
    typeof image === "undefined" ? currentUserImage : image?.link;

  return (
    <UserAvatarComponentStyled>
      {imageLink ? (
        <img src={imageLink} alt="user avatar" />
      ) : (
        <UserAvatarStub />
      )}
    </UserAvatarComponentStyled>
  );
});
