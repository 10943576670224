import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

import FeatureItem from "../FeatureItem";
import { FeatureDialogProps } from "./index.d";
import { Lane } from "../../models/map-interface.d";
import { setSpeedLimit } from "../../store/slices/mapStructs";

import { ModalStyled, ModalWrapperContentStyled } from "./styles";

import "./index.css";
import {
  getFeatures,
  getSelectedFeatureIndexes,
} from "../../store/slices/currentMap";

type Position = {
  xRate: number;
  yRate: number;
};

const LaneSpeedLimit = ({ lane }: { lane?: Lane }) => {
  const dispatch = useDispatch();

  return lane ? (
    <Form.Group as={Row}>
      <Form.Label column sm="4">
        <b>Speed Limit:</b>
      </Form.Label>
      <Col sm="8">
        <Form.Control
          type="text"
          placeholder="Speed Limit"
          defaultValue={lane.speed_limit}
          onBlur={(e) =>
            dispatch(
              setSpeedLimit({
                laneId: lane.lane_id,
                speedLimit: Number(e.currentTarget.value),
              })
            )
          }
        />
      </Col>
    </Form.Group>
  ) : null;
};

const Feature = (props: FeatureDialogProps & { index: number }) => {
  const mapFeatures = useSelector(getFeatures);
  const { index, deleteFeatureProperty } = props;
  const feature = mapFeatures.features?.[index] as GeoJSON.Feature<
    GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon
  >;
  const radius = feature?.properties?.radius;
  const coordinates = feature?.geometry?.coordinates;
  const num_points =
    feature?.geometry?.type === "LineString"
      ? coordinates?.length
      : (coordinates?.[0] as GeoJSON.Position)?.length;

  return (
    <>
      <FeatureItem
        {...props}
        deleteFeatureProperty={deleteFeatureProperty}
        key={`feature-item-${index}`}
        index={index}
        featureType={feature?.geometry?.type}
        isModal
      />
      <br />
      <Accordion key={`feature-${index}`}>
        <Accordion.Item eventKey={index.toString()}>
          <Accordion.Header>
            <b>Info Feature No {feature.properties?.feature_id}</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p>
                <b>Coordinates:</b> ({num_points} points)
                <br />
                {JSON.stringify(coordinates)}
              </p>
              {radius && (
                <p>
                  <b>Radius (position, radius):</b>{" "}
                  {JSON.stringify([...radius])}
                </p>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
    </>
  );
};

export default function FeatureDialog(props: FeatureDialogProps) {
  const { lane, showDialog, updateDialog, deleteFeatureProperty } = props;

  const selectedFeatureIndexes = useSelector(getSelectedFeatureIndexes);
  const [currentPosition, setCurrentPosition] = useState<Position>({
    xRate: 0,
    yRate: 0,
  });

  if (!showDialog) return <></>;

  let title = "Feature Info";

  if (lane) {
    title = `Lane Segment No ${lane.lane_id}`;
  } else if (selectedFeatureIndexes.length === 0) {
    title = "No Feature(s) selected";
  }

  const closeAction = () => updateDialog(false);

  const onDrag = (e: DraggableEvent, data: DraggableData) => {
    setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
  };

  return (
    <Draggable
      position={{
        x: currentPosition.xRate,
        y: currentPosition.yRate,
      }}
      onDrag={onDrag}
      cancel="svg"
    >
      <ModalStyled className="feature-dialog-modal">
        <ModalWrapperContentStyled>
          <Modal.Header
            closeButton
            closeVariant="white"
            onHide={closeAction}
            style={{ justifyContent: "space-between" }}
          >
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LaneSpeedLimit lane={lane} />
            {selectedFeatureIndexes.map((index) => (
              <Feature
                {...props}
                deleteFeatureProperty={deleteFeatureProperty}
                key={`feature-${index}`}
                index={index}
              />
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeAction}>
              Close
            </Button>
          </Modal.Footer>
        </ModalWrapperContentStyled>
      </ModalStyled>
    </Draggable>
  );
}
