import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

import { closeAnyModal, getModalData } from "../../../store/slices/modals";
import { ReactComponent as CloseIcon } from "../../Modals/assets/closeIcon.svg";
import {
  addUndoFeature,
  getFeatures,
  setFeatures,
} from "../../../store/slices/currentMap";
import { generateUUID } from "../../../utils";

import {
  StopSignModalStyled,
  HeaderStyled,
  StopSignModalContentStyled,
  ModalWrapStyled,
  ActionButtonStyled,
  InputStyled,
} from "./styles";
import {
  DropdownButtonStyled,
  RowItemStyled,
} from "../IntersectionsModal/styles";
import { RowStyled } from "../../LineStringFeature/styles";
import { ItemWithContainerValues } from "../../common/ItemWithContainerValues";
import { setStopSign } from "../../../store/slices/mapStructs";

export const StopSignModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const mapFeatures = useSelector(getFeatures);

  if (!modalData) return <></>;

  const stopSignId = generateUUID();
  const newFeatureId = generateUUID();

  const { coordinate, index } = modalData;
  const { features } = mapFeatures;
  const currentFeature = features[index];
  const { properties } = currentFeature;
  const lanes =
    properties?.feature_info_list.map((item: any) => item.lane_association) ||
    [];
  const controlLineAssociation =
    String(currentFeature.properties?.feature_id) || "NULL";

  const [stopName, setStopName] = useState("");
  const [laneAssociation, setLaneAssociation] = useState(
    lanes.length === 1 ? lanes[0] : "NULL"
  );

  const squareOffset = 0.0000035;
  const squareCoordinates = [
    [
      [coordinate[0] - squareOffset, coordinate[1] + squareOffset],
      [coordinate[0] + squareOffset, coordinate[1] + squareOffset],
      [coordinate[0] + squareOffset, coordinate[1] - squareOffset],
      [coordinate[0] - squareOffset, coordinate[1] - squareOffset],
      [coordinate[0] - squareOffset, coordinate[1] + squareOffset],
    ],
  ];

  const handleSubmit = useCallback(() => {
    const updatedMapFeatures = cloneDeep(mapFeatures);
    updatedMapFeatures.features = [
      ...updatedMapFeatures.features,
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: squareCoordinates,
        },
        properties: {
          feature_id: newFeatureId,
          feature_info_list: [
            {
              stop_name: stopName,
              polygon_type: "stop_sign",
              lane_association: laneAssociation,
              stop_sign_id: stopSignId,
              intersection_id: "NULL",
              control_line_association: controlLineAssociation,
            },
          ],
        },
      },
    ];
    dispatch(setFeatures(updatedMapFeatures));
    dispatch(
      setStopSign({
        stop_sign_id: stopSignId,
        associated_lane_id: laneAssociation,
        associated_control_line_id: controlLineAssociation,
      })
    );
    dispatch(addUndoFeature(updatedMapFeatures));
    toast.success(`Stop Sign ${stopSignId} was created`);
    dispatch(closeAnyModal());
  }, [
    dispatch,
    newFeatureId,
    laneAssociation,
    stopSignId,
    controlLineAssociation,
    stopName,
  ]);

  return (
    <ModalWrapStyled>
      <StopSignModalStyled>
        <CloseIcon onClick={() => dispatch(closeAnyModal())} />
        <HeaderStyled>Create Stop Sign</HeaderStyled>
        <StopSignModalContentStyled>
          <RowStyled>
            <RowItemStyled>Stop Name</RowItemStyled>
            <RowItemStyled>
              <InputStyled
                value={stopName}
                onChange={(e: any) => {
                  setStopName(e?.currentTarget?.value);
                }}
              />
            </RowItemStyled>
          </RowStyled>
          <RowStyled>
            <RowItemStyled>Control Line</RowItemStyled>
            <RowItemStyled>{controlLineAssociation}</RowItemStyled>
          </RowStyled>
          <RowStyled>
            <RowItemStyled>Lane Associated</RowItemStyled>
            <RowItemStyled>
              {lanes.length === 1 && lanes[0]}
              {lanes.length !== 1 && (
                <DropdownButtonStyled
                  title={String(laneAssociation)}
                  size="sm"
                  onSelect={(val: any) => setLaneAssociation(val)}
                >
                  <ItemWithContainerValues values={[...lanes, "NULL"]} />
                </DropdownButtonStyled>
              )}
            </RowItemStyled>
          </RowStyled>
        </StopSignModalContentStyled>
        <ActionButtonStyled onClick={handleSubmit}>Create</ActionButtonStyled>
      </StopSignModalStyled>
    </ModalWrapStyled>
  );
});
