import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as MapConst from "../../constants/map-constants"; // Register ply file loader - Used by pointCloud layer for loading ply
import {
  getMapStructs,
  setControlLine,
  setLane,
} from "../../store/slices/mapStructs";
import { LineStringLineTypeDropdown } from "./components/LineStringLineTypeDropdown/LineStringLineTypeDropdown";
import { LaneAssocDropdown } from "./components/LaneAssocDropdown/LaneAssocDropdown";
import { StopSignAssocDropdown } from "./components/StopSignAssocDropdown/StopSignAssocDropdown";
import { ControlLineIdString } from "./components/ControlLineIdString/ControlLineIdString";
import { FC, memo, useCallback } from "react";

type LineStringFeatureProps = {
  feature_info: any;
  feature_index: number;
  feature_info_index: number;
  availableIds: any;
};

export const LineStringFeature: FC<LineStringFeatureProps> = memo(
  ({ feature_info, feature_index, feature_info_index, availableIds }) => {
    const dispatch = useDispatch();
    const mapStructs = useSelector(getMapStructs);

    const _updateBoundaryLineOfLane = (
      affectedLaneId: number,
      lineType: any,
      lineStringFeatureId: any
    ) => {
      // handle NULL and NEW line ids
      if (isNaN(affectedLaneId)) {
        return;
      }

      const affectedLane = mapStructs.lanes[affectedLaneId]
        ? {
            ...mapStructs.lanes[affectedLaneId],
          }
        : {
            lane_id: affectedLaneId,
            left_boundary_line_id: -1,
            right_boundary_line_id: -1,
            start_line_id: -1,
            termination_line_id: -1,
          };
      if (lineType === MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME) {
        affectedLane["left_boundary_line_id"] = lineStringFeatureId;
      } else if (lineType === MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME) {
        affectedLane["right_boundary_line_id"] = lineStringFeatureId;
      } else if (lineType === MapConst.LANE_START_LINE_STRING_NAME) {
        affectedLane["start_line_id"] = lineStringFeatureId;
      } else if (lineType === MapConst.LANE_TERMINATION_LINE_STRING_NAME) {
        affectedLane["termination_line_id"] = lineStringFeatureId;
      }
      dispatch(setLane(affectedLane));
    };

    const setControlLineAction = useCallback(
      (params: any) => dispatch(setControlLine(params)),
      []
    );

    return [
      <>
        <LineStringLineTypeDropdown
          feature_info={feature_info}
          feature_index={feature_index}
          feature_info_index={feature_info_index}
          _updateBoundaryLineOfLane={_updateBoundaryLineOfLane}
          setControlLineAction={setControlLineAction}
        />
        <LaneAssocDropdown
          feature_index={feature_index}
          feature_info_index={feature_info_index}
          _updateBoundaryLineOfLane={_updateBoundaryLineOfLane}
          setControlLineAction={setControlLineAction}
          feature_info={feature_info}
          availableIds={availableIds}
        />
      </>,
      feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME && (
        <ControlLineIdString
          feature_index={feature_index}
          feature_info_index={feature_info_index}
          feature_info={feature_info}
        />
      ),
      feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME && (
        <StopSignAssocDropdown
          feature_index={feature_index}
          feature_info_index={feature_info_index}
          feature_info={feature_info}
          setControlLineAction={setControlLineAction}
        />
      ),
    ];
  }
);
