import styled from "styled-components";
import { getHtmlColorForFeature } from "../../utils/toolbox";
import { ToolboxCheckbox } from "../common/toolbox";

interface ToolboxCheckboxProps {
  isHide: boolean | undefined;
}
export const ToolboxCheckboxStyled = styled(
  ToolboxCheckbox
)<ToolboxCheckboxProps>`
  margin: 10px;
  ${(props) =>
    props.isHide &&
    `
  display: none;
`}
`;

export const ModalStyled = styled.div`
  position: absolute;
  right: 32px;
`;

export const ToolboxRowStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ToolboxCheckboxSpanProps {
  index: number;
  isSelected: boolean;
}

export const ToolboxCheckboxSpanStyled = styled.span<ToolboxCheckboxSpanProps>`
  color: ${(props) => getHtmlColorForFeature(props.index, props.isSelected)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 240px;
`;
