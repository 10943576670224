import axios from "axios";
import { sendSentryEvent } from "./utils/sentryUtils";
import { refreshToken } from "./utils/refreshTokenHelper";

export const API_URL = window._envK8S_?.REACT_APP_API_URL || "";
const API_VERSION = window._envK8S_?.REACT_APP_API_VERSION || "v1";
export const BASE = `${API_URL}api/${API_VERSION}/`;

const instance = axios.create({
  baseURL: BASE,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = token;
            return instance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;

      return new Promise((resolve, reject) => {
        refreshToken()
          .then((newToken) => {
            originalRequest.headers["Authorization"] = newToken;
            processQueue(null, newToken);
            resolve(instance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    sendSentryEvent(error, "API error");
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("JWT");
  config.headers.Authorization = token;
  return config;
});

export default instance;
