import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getContextMenuData,
  setLockedFeaturesAction,
} from "../../../../store/slices/currentMap";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { ReactComponent as CopyIcon } from "../../assets/copyIcon.svg";
import { ReactComponent as PasteIcon } from "../../assets/pasteIcon.svg";
import { ReactComponent as PasteToReplaceIcon } from "../../assets/pasteToReplace.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as LockUnlockIcon } from "../../assets/lockUnlock.svg";
import { ReactComponent as FlipHorizontalIcon } from "../../assets/flipHorizontal.svg";
import { ReactComponent as FlipVerticalIcon } from "../../assets/flipVertical.svg";

import {
  ContextMenuBodyStyled,
  ContextMenuItemStyled,
  ContextMenuItemContentStyled,
  ContextMenuOptionsStyled,
  ContextMenuOptionsItemStyled,
  LayerIconStyled,
} from "./styles";
import { Direction } from "../../utils";

type ContextMenuProps = {
  layerVisibility: { [key: string]: boolean };
  toggleLayerVisibility: (id: string) => void;
  editableGeoJsonLayerVisible: boolean;
  toggleEditableGeoJsonLayerVisibility: () => void;
  bufferFeature: number | null;
  addToClipboard: (featureIndex: number) => void;
  pasteFromClipboard: (position: Array<number>) => void;
  pasteAndReplace: (currentBufferFeature: number) => void;
  flipFeature: (currentBufferFeature: number, direction: Direction) => void;
  deleteFeature: (featureIndex: number) => void;
};

export const ContextMenu: React.FC<ContextMenuProps> = memo(
  ({
    layerVisibility,
    toggleLayerVisibility,
    editableGeoJsonLayerVisible,
    toggleEditableGeoJsonLayerVisibility,
    bufferFeature,
    addToClipboard,
    pasteFromClipboard,
    pasteAndReplace,
    deleteFeature,
    flipFeature,
  }) => {
    const dispatch = useDispatch();
    const contextMenuData = useSelector(getContextMenuData);
    const [isLayerMenuOpen, setIsLayerMenuOpen] = useState(false);

    if (!contextMenuData) return <></>;

    const { position, currentBufferFeature, coordinate } = contextMenuData;

    const copyAction = useCallback(() => {
      if (!currentBufferFeature) return;
      addToClipboard(currentBufferFeature);
    }, [currentBufferFeature, addToClipboard]);

    const pasteAction = useCallback(() => {
      if (!bufferFeature) return;
      pasteFromClipboard(coordinate);
    }, [bufferFeature, pasteFromClipboard]);

    const pasteAndReplaceAction = useCallback(() => {
      if (!bufferFeature || !currentBufferFeature) return;
      pasteAndReplace(currentBufferFeature);
    }, [bufferFeature, pasteAndReplace]);

    const deleteFeatureAction = useCallback(() => {
      if (!currentBufferFeature) return;
      deleteFeature(currentBufferFeature);
    }, [currentBufferFeature, deleteFeature]);

    const flipHorizontalAction = useCallback(() => {
      if (!currentBufferFeature) return;
      flipFeature(currentBufferFeature, "horizontal");
    }, [currentBufferFeature, flipFeature]);

    const flipVerticalAction = useCallback(() => {
      if (!currentBufferFeature) return;
      flipFeature(currentBufferFeature, "vertical");
    }, [currentBufferFeature, flipFeature]);

    const setLockedFeatures = useCallback(() => {
      if (!currentBufferFeature) return;
      dispatch(setLockedFeaturesAction(currentBufferFeature));
    }, [currentBufferFeature, flipFeature]);

    return (
      <ContextMenuBodyStyled x={position[0]} y={position[1]}>
        <ContextMenuItemStyled
          onClick={copyAction}
          isDisabled={!currentBufferFeature}
        >
          <ContextMenuItemContentStyled>
            Copy
            <CopyIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onClick={pasteAction}
          isDisabled={!bufferFeature}
        >
          <ContextMenuItemContentStyled>
            Paste here
            <PasteIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onClick={pasteAndReplaceAction}
          isDisabled={!currentBufferFeature || !bufferFeature}
        >
          <ContextMenuItemContentStyled>
            Paste to replace
            <PasteToReplaceIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onMouseEnter={() => setIsLayerMenuOpen(true)}
          onMouseLeave={() => setIsLayerMenuOpen(false)}
        >
          <ContextMenuItemContentStyled>
            Select layer
            <ArrowIcon />
          </ContextMenuItemContentStyled>
          {isLayerMenuOpen && (
            <ContextMenuOptionsStyled>
              <ContextMenuOptionsItemStyled
                onClick={() => toggleEditableGeoJsonLayerVisibility()}
              >
                <LayerIconStyled isVisible={editableGeoJsonLayerVisible} />
                Editable GeoJson Layer
              </ContextMenuOptionsItemStyled>
              {Object.entries(layerVisibility).map(([layerId, isVisible]) => {
                const index = layerId
                  ? parseInt(layerId.split("_").pop() || "0") + 1
                  : 0;
                return (
                  <ContextMenuOptionsItemStyled
                    key={layerId}
                    onClick={() => toggleLayerVisibility(layerId)}
                  >
                    <LayerIconStyled isVisible={isVisible} /> 3D point Layer
                    {index}
                  </ContextMenuOptionsItemStyled>
                );
              })}
            </ContextMenuOptionsStyled>
          )}
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onClick={setLockedFeatures}
          isDisabled={!currentBufferFeature}
        >
          <ContextMenuItemContentStyled>
            Lock/Unlock
            <LockUnlockIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onClick={flipHorizontalAction}
          isDisabled={!currentBufferFeature}
        >
          <ContextMenuItemContentStyled>
            Flip horizontal
            <FlipHorizontalIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onClick={flipVerticalAction}
          isDisabled={!currentBufferFeature}
        >
          <ContextMenuItemContentStyled>
            Flip vertical
            <FlipVerticalIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onClick={deleteFeatureAction}
          isDisabled={!currentBufferFeature}
        >
          <ContextMenuItemContentStyled>
            Delete
            <DeleteIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
      </ContextMenuBodyStyled>
    );
  }
);
