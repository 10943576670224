import styled from "styled-components";

export const EditorToolbarStyled = styled.div`
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin: 0 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.6);
`;
