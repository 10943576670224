// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  TransformMode,
  ModeProps,
  Viewport,
  GuideFeatureCollection,
} from "@nebula.gl/edit-modes";
import { FeatureCollection, Geometry, GeoJsonProperties } from "geojson";

export class RotateMode extends TransformMode {
  // Override handle pointer down to disable scaling and translation
  handlePointerDown(
    event,
    props: ModeProps<FeatureCollection<Geometry, GeoJsonProperties>>
  ) {
    const { modeConfig } = props;
    const selectedFeatureIndexes = modeConfig?.selectedFeatureIndexes || [];

    if (selectedFeatureIndexes.length === 0) return;

    if (!this.isRotateHandle(event)) {
      // Ignore pointer down events on scaling or other handles
      return;
    }

    // Proceed with pointer down for rotation only
    super.handlePointerDown(event, props);
  }

  // Override handle pointer move to limit transformations to rotation
  handlePointerMove(
    event,
    props: ModeProps<FeatureCollection<Geometry, GeoJsonProperties>>,
    viewport: Viewport
  ) {
    if (!this.isRotateHandle(event)) {
      // If not a rotation handle, ignore the pointer move
      return;
    }

    // Proceed with pointer move for rotation only
    super.handlePointerMove(event, props, viewport);
  }

  // Helper method to identify rotation handles
  private isRotateHandle(event): boolean {
    // Check if the event corresponds to a rotation handle (could vary based on implementation)
    // Rotation handles are typically positioned away from the feature's center
    const { pointerDownPicks } = event;
    return pointerDownPicks?.some(
      (pick) => pick?.object.properties?.editHandleType === "rotate"
    );
  }

  // Override getGuides to show only rotation guides
  getGuides(
    props: ModeProps<FeatureCollection<Geometry, GeoJsonProperties>>
  ): GuideFeatureCollection | null {
    const guides = super.getGuides(props);

    if (guides) {
      // Filter out any non-rotation handles
      guides.features = guides.features.filter(
        (feature) => feature.properties?.editHandleType === "rotate"
      );
    }

    return guides;
  }
}
