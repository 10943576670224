import React, {
  FC,
  memo,
  SVGProps,
  useCallback,
  useRef,
  useState,
} from "react";

import { useOnClickOutside } from "../../../../utils/hooks";
import { ReactComponent as ArrowIcon } from "../EditorToolbar/assets/arrow.svg";

import {
  ToolbarDropdownStyled,
  ToolbarDropdownTitleStyled,
  ToolbarDropdownContainerStyled,
  ToolbarDropdownItemStyled,
  ToolbarDropdownSubOptionsStyled,
  DropdownSubOptionsContentStyled,
  DropdownItemWrapperStyled,
} from "./styles";

export type ToolbarDropdownProps = {
  name: string;
  Icon: FC<SVGProps<SVGElement>>;
  options?: Array<ToolbarOptionType>;
  clickAction?: () => void;
  isActive?: boolean;
};

type SubOptionType = {
  name: string;
  subOptionClickAction: () => void;
};

type ToolbarOptionType = {
  optionName: string;
  OptionIcon: FC<SVGProps<SVGElement>>;
  optionClickAction?: () => void;
  subOptions?: Array<SubOptionType>;
  fullBorder?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
};

export const ToolbarDropdown: FC<ToolbarDropdownProps> = memo(
  ({ name, Icon, options, clickAction, isActive }) => {
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    const handleClickOutside = useCallback(() => setIsOpen(false), []);
    useOnClickOutside(ref, handleClickOutside);

    const isButton = !options && clickAction;

    return (
      <ToolbarDropdownStyled
        onClick={() => (isButton ? clickAction() : setIsOpen(!isOpen))}
        ref={ref}
        isOpen={isOpen}
        isActive={isActive}
      >
        <ToolbarDropdownTitleStyled isActive={isActive}>
          <Icon />
          {name}
        </ToolbarDropdownTitleStyled>
        {options && isOpen && (
          <ToolbarDropdownContainerStyled>
            {options.map(
              ({
                optionName,
                optionClickAction,
                OptionIcon,
                subOptions,
                fullBorder,
                isDisabled,
                isActive,
              }) => {
                if (subOptions) {
                  return (
                    <ToolbarDropdownSubOptions
                      key={optionName}
                      optionName={optionName}
                      OptionIcon={OptionIcon}
                      subOptions={subOptions}
                    />
                  );
                }
                return (
                  <ToolbarDropdownItemStyled
                    isDisabled={isDisabled}
                    key={optionName}
                    onClick={optionClickAction}
                    isActive={isActive}
                  >
                    <DropdownItemWrapperStyled
                      isActive={isActive}
                      fullBorder={fullBorder}
                    >
                      <OptionIcon />
                      {optionName}
                    </DropdownItemWrapperStyled>
                  </ToolbarDropdownItemStyled>
                );
              }
            )}
          </ToolbarDropdownContainerStyled>
        )}
      </ToolbarDropdownStyled>
    );
  }
);

const ToolbarDropdownSubOptions: FC<
  Omit<ToolbarOptionType, "fullBorder" | "optionClickAction">
> = memo(({ optionName, OptionIcon, subOptions }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ToolbarDropdownSubOptionsStyled
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <DropdownItemWrapperStyled>
        <span>
          <OptionIcon />
          {optionName}
        </span>
      </DropdownItemWrapperStyled>
      <ArrowIcon />
      {isOpen && (
        <DropdownSubOptionsContentStyled>
          {subOptions?.map(({ name, subOptionClickAction }) => (
            <ToolbarDropdownItemStyled
              key={name}
              onClick={subOptionClickAction}
            >
              <DropdownItemWrapperStyled>{name}</DropdownItemWrapperStyled>
            </ToolbarDropdownItemStyled>
          ))}
        </DropdownSubOptionsContentStyled>
      )}
    </ToolbarDropdownSubOptionsStyled>
  );
});
